import { $log } from '@/utils/plugins/logger';
import { api } from '@shared/services/api';
import { excludedCategories } from '@shared/utils/product/excluded-categories';

const filtered = list => {
  const excluded = excludedCategories();
  return list.filter(v => !excluded.includes(v.id));
};

export const CatalogModule = {
  namespaced: true,
  state: {
    specialCategories: null,
    simpleCategories: null,
    categoriesByGroup: null,
    categories: null,
    isCatalogLoading: false,

    saleCategory: {
      id: 0,
      // childId: 0,
      items: [],
    },
    /**
     * Слайдер товаров на главной Новинки
     */
    newProducts: null,
  },
  getters: {
    hasMainCategories(state) {
      return !!state.categories;
    },
    isCatalogLoading(state) {
      return state.isCatalogLoading;
    },
    saleCategory(state) {
      return state.saleCategory;
    },
  },
  mutations: {
    SET_MAIN_CATEGORIES(state, value) {
      state.categories = value;
    },
    SET_CATEGORIES_BY_GROUP(state, value) {
      state.categoriesByGroup = value;
    },
    SET_SPECIAL_CATEGORIES(state, value) {
      state.specialCategories = value;
    },
    SET_SIMPLE_CATEGORIES(state, value) {
      state.simpleCategories = value;
    },
    // RESET_MAIN_CATEGORIES(state) {
    //   state.specialCategories = null;
    //   state.simpleCategories = null;
    //   state.categoriesByGroup = null;
    //   state.categories = null;
    // },
    SET_CATALOG_IS_LOADING(state, value) {
      state.isCatalogLoading = value;
    },
    SET_SALE_CATEGORY(state, value) {
      state.saleCategory = value;
    },
    SET_NEW_PRODUCTS(state, value) {
      state.newProducts = value;
    },
  },
  actions: {
    BUILD_CATEGORIES_TYPE({ commit }, value) {
      if (
        value.hasOwnProperty('Специальные предложения') ||
        value.hasOwnProperty('Арнайы ұсыныстар')
      ) {
        const title = value.hasOwnProperty('Специальные предложения')
          ? 'Специальные предложения'
          : 'Арнайы ұсыныстар';

        const specialCategories = value[title];

        commit('SET_SPECIAL_CATEGORIES', specialCategories);

        delete value[title];
      } else {
        commit('SET_SPECIAL_CATEGORIES', null);
      }
      commit('SET_SIMPLE_CATEGORIES', value);
    },
    FETCH_MAIN_CATEGORIES(ctx) {
      $log('FETCH_MAIN_CATEGORIES start');
      ctx.commit('SET_CATALOG_IS_LOADING', true);
      const warehouseId = ctx.rootGetters['delivery/warehouseId'];
      return api.lavka
        .categories(warehouseId)
        .then(({ data }) => {
          $log('FETCH_MAIN_CATEGORIES fetched', data);
          const categories = filtered(data);
          ctx.commit('SET_MAIN_CATEGORIES', categories);
          const grouped = categories.reduce((acc, category) => {
            const cat = { ...category };
            if (!cat.group_name) cat.group_name = 'Остальное';
            if (!cat.block_size) cat.block_size = '1';
            if (!acc.hasOwnProperty(cat.group_name)) {
              acc[cat.group_name] = {
                id: cat.category_group_id,
                name: cat.group_name,
                icon: cat.group_icon,
                categories: [],
              };
            }
            acc[cat.group_name].categories.push(cat);
            return acc;
          }, {});

          ctx.commit('SET_CATEGORIES_BY_GROUP', { ...grouped });
          ctx.dispatch('BUILD_CATEGORIES_TYPE', { ...grouped });
          return categories;
        })
        .finally(() => {
          $log('FETCH_MAIN_CATEGORIES ended');
          ctx.commit('SET_CATALOG_IS_LOADING', false);
        });
    },
  },
};
