import { client } from '../../clients/client';

export default function (payload, { mock = false }) {
  if (mock) {
    return import('./mocks/stash-promocode.json');
  }
  return client
    .post(`/lavka-promoaction/v1/profile/promocode/stash`, payload)
    .then(r => r.data);
}
