<template>
  <svg
    width="57"
    height="56"
    viewBox="0 0 57 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1_53591)">
      <path
        d="M0.5 16C0.5 7.16344 7.66344 0 16.5 0H40.5C49.3366 0 56.5 7.16344 56.5 16V40C56.5 48.8366 49.3366 56 40.5 56H16.5C7.66344 56 0.5 48.8366 0.5 40V16Z"
        fill="#FFF3C9"
        fill-opacity="0.5"
      />
    </g>
    <path
      d="M46.145 24.2629C45.9679 23.667 45.0981 23.5274 43.3586 23.2375L35.5148 21.9328C35.0102 21.8469 34.7524 21.804 34.5592 21.659C34.3605 21.5087 34.2532 21.2778 34.0384 20.8108L30.6668 13.6112C29.9205 12.0113 29.5447 11.2113 28.9273 11.2006C28.3045 11.1899 27.9019 11.9683 27.0858 13.5307L23.4189 20.5853C23.1827 21.0416 23.0646 21.2671 22.8606 21.4121C22.6565 21.5517 22.4042 21.5839 21.8942 21.6483L14.002 22.6254C12.2518 22.8402 11.3821 22.9529 11.1781 23.5381C10.974 24.1233 11.5914 24.7515 12.8263 26.0078L18.4045 31.6772C18.7642 32.0423 18.9467 32.2248 19.0165 32.4611C19.0863 32.6973 19.038 32.9496 18.9413 33.4543L17.4327 41.2605C17.0998 42.9893 16.9334 43.8536 17.4273 44.2295C17.9213 44.6053 18.7105 44.2134 20.2835 43.4241L27.3972 39.87C27.8589 39.6391 28.0844 39.5264 28.3314 39.5318C28.5783 39.5318 28.8038 39.6606 29.2548 39.9076L36.2128 43.7516C37.7536 44.6053 38.5267 45.0294 39.0367 44.6751C39.5468 44.3207 39.4179 43.451 39.1549 41.7061L37.9737 33.8408C37.8986 33.3308 37.861 33.0785 37.9415 32.8476C38.022 32.6167 38.21 32.4396 38.5858 32.0906L44.3948 26.6628C45.6833 25.4601 46.3276 24.8588 46.145 24.2629Z"
      fill="#FFC563"
    />
    <defs>
      <clipPath id="clip0_1_53591">
        <rect x="0.5" width="56" height="56" rx="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'IconStar',
};
</script>

<style scoped></style>
