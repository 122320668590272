import { SaleCategoryService } from '@services/sale-category';

function buildQueryOnGoToProduct(
  product,
  isAppliances = false,
  openedFrom,
  locale
) {
  let query = product.replacement || {};
  if (product.category_id && product.parent_id) {
    let pId = product.parent_id;
    let cId = product.category_id;
    let catName = product.category_name;

    if (
      SaleCategoryService.getParentIdOfPayed() === product.parent_id ||
      SaleCategoryService.getParentId() === product.parent_id
    ) {
      pId = SaleCategoryService.getParentId();
      cId = undefined;
      catName = locale === 'kk' ? 'Жеңілдіктер' : 'Акции';
    }

    let path = `/category/${pId}`;
    if (cId) path = path + `?tag=${cId}`;

    query = Object.assign(query, {
      category_id: cId,
      category_name: encodeURIComponent(catName),
      /*
        Если кликнуть на карточку в поиске, то на десктопе в детальной
        в хлебных крошках клик по названию категории будет
        вести на страницу поиска, что неправильно.
        Поэтому руками формируем ссылку
      */
      path,
    });
    console.log(pId, cId, catName, query);
  }
  query.opened_from = openedFrom;
  if (isAppliances) {
    query = Object.assign(query, { showAppliances: true });
  }
  return query;
}

export const ProductService = {
  buildQueryOnGoToProduct,
};
