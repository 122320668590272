<template>
  <div class="relative cursor-pointer">
    <div
      v-if="userProfile && userProfile.avatar"
      class="rounded-full overflow-hidden IconProfileButton"
      @click="openProfileModal"
    >
      <picture>
        <source
          class="w-full h-full rounded-full IconProfileButton"
          :srcset="userProfile.avatar"
          width="100%"
          height="100%"
          alt="profile"
        />
        <img
          src="@img/header/profile-icon.svg"
          class="w-full h-full rounded-full IconProfileButton"
          width="100%"
          height="100%"
          alt=""
        />
      </picture>
    </div>
    <IconProfileButton v-else @click.native="openProfileModal" />

    <UiToolPopup
      name="header-profile-toolpopup"
      :has-scrollbar="false"
      :style="`
        --modal-width: ${$isDesktop ? 328 : 280}px;
        --modal-translateX: -82%;
        --modal-translateY: -42px;
      `"
      absolute
    >
      <div class="menu-content">
        <UiInlineIcon
          v-if="!$isDesktop"
          class="menu-close"
          name="profile-menu-mob-close"
          @click="closeProfileModal"
        />
        <div class="menu-links">
          <div
            v-for="(item, index) in profileLinks"
            :key="index"
            class="menu-link"
            :class="{ 'text-sirius-red-300': item.color === 'red' }"
            @click="goToProfileLink(item)"
          >
            {{ $t(item.name) }}
          </div>
          <div class="menu-link lang" @click="openLangList">
            {{ currentLocale }}
          </div>
        </div>

        <div class="support-center">
          <p class="support-center-title">{{ $t('careService') }}</p>
          <div class="support-center-btn" @click="onPhone">
            <UiInlineIcon name="support-mob-phone" />
            <span>
              {{ formatPhoneNumber(phoneNumber, '-') }}
            </span>
          </div>
          <div class="support-center-btn" @click="onWhatsapp">
            <UiInlineIcon name="support-mob-whatsapp" />
            <span> Whatsapp </span>
          </div>
        </div>
      </div>
    </UiToolPopup>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  PROFILE_LINKS,
  PROFILE_LINKS_I18N,
} from '@shared/components/profile/constants';
import { SupportService } from '@services/support';
import { TEL_NUM } from '@services/support/constants';
import { formatPhoneNumber } from '@/utils/formatters/phone';

import debounce from 'debounce';
import UiToolPopup from '@ui/UiToolPopup.vue';
import UiInlineIcon from '@pure-ui/components/UiInlineIcon/UiInlineIcon.vue';
import IconProfileButton from '@shared/components/icons/IconProfileButton.vue';

export default {
  name: 'ProfileIcon',
  components: {
    IconProfileButton,
    UiInlineIcon,
    UiToolPopup,
  },
  inject: ['popup'],
  data() {
    return {
      phoneNumber: TEL_NUM,
    };
  },
  computed: {
    ...mapGetters('user', ['userProfile']),
    ...mapGetters('cashbacks', ['hasCashbacksProgram', 'hasCashbacksLinks']),
    currentLocale() {
      return this.$i18n.locale === 'ru' ? 'Язык: Русский' : 'Тіл: Қазақ тілі';
    },
    profileLinks() {
      let links = [...PROFILE_LINKS];
      const removeByName = name => {
        links = links.filter(v => v.name !== name);
      };

      if (!this.hasCashbacksProgram && !this.hasCashbacksLinks) {
        removeByName('cashbacks');
      }
      return links;
    },
  },
  methods: {
    formatPhoneNumber,
    onPhone: debounce(function () {
      SupportService.clickSupport('call');
    }, 300),
    onWhatsapp: debounce(function () {
      SupportService.clickSupport('whatsapp');
    }, 300),
    openProfileModal() {
      this.popup.show('header-profile-toolpopup');
    },
    closeProfileModal() {
      this.popup.hide('header-profile-toolpopup');
    },
    goToProfileLink(item) {
      this.closeProfileModal();

      const query = {};
      if (item.routeName === 'cashbacks') {
        query.from = 'profile-icon-menu';
      }

      this.$router.push({ name: item.routeName, query });
    },
    openLangList() {
      this.$eventBus.emit('eb_open_lang_switcher_popup');
    },
  },
  i18n: {
    messages: {
      ru: {
        ...PROFILE_LINKS_I18N.messages.ru,
        careService: 'Служба заботы',
      },
      kk: {
        ...PROFILE_LINKS_I18N.messages.kk,
        careService: 'Қолдау қызметі',
      },
    },
  },
};
</script>

<style scoped>
.IconProfileButton {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
}
.menu-content {
  @apply pb-6 pt-4 px-4;
}
.menu-close {
  @apply absolute top-4 right-4;
}
.menu-link {
  @apply mb-2 py-2 px-3 block
    font-medium leading-5 whitespace-nowrap
    md:text-lg md:leading-6
    rounded-xl bg-transparent hover:bg-sirius-gray-200;
}
/* .menu-link:first-of-type {
  @apply pt-0;
} */
.menu-link:last-of-type {
  @apply mb-0;
}
.menu-link.lang {
  @apply md:hidden;
}
.support-center {
  @apply mt-3 py-4 px-3 border-t-1 border-sirius-gray-200;
}
.support-center-title {
  @apply mb-4 font-bold leading-5
    md:text-lg md:leading-6;
}
.support-center-btn {
  @apply mb-3 flex items-center space-x-2
    text-sm font-medium leading-4 
    text-sirius-orange-100 hover:text-sirius-orange-200
    md:text-base md:leading-5;
}
.support-center-btn:last-child {
  @apply mb-0;
}
@media screen and (min-width: 768px) {
  .IconProfileButton {
    width: 52px;
    height: 52px;
    min-width: 52px;
    min-height: 52px;
    max-width: 52px;
    max-height: 52px;
  }
}
</style>
