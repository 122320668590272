import { api } from '@shared/services/api';
import { Analitycs, EVENTS, SEGMENT_EVENTS } from '@shared/services/analitycs';

export function fetchFromApi(params = {}) {
  return api.lavkaFavorites
    .shoppingHistoryProductsList(params)
    .then(r => {
      return Promise.all([r.data.items, r.data.pagination, r.data.log_id]);
    })
    .then(([items, pagination, logId]) => {
      return {
        items,
        pagination,
        logId,
      };
    });
}

export function logListView(logId, pageNum) {
  Analitycs.logSegmentEvent(SEGMENT_EVENTS.VIEW_PRODUCT_LIST, {
    screenName: 'Shopping History Page',
    logId,
    pageNum,
  });
}

export function logMounted(from) {
  Analitycs.logEvent(EVENTS.ORDERS_HISTORY, { from: from });
}
