import mitt from 'mitt';
import { $log } from '../logger';

// todo: проверить что в будущем не будет проблем с типизацией
// по идее не должно быть
// @see https://github.com/developit/mitt?tab=readme-ov-file#typescript

const EVENT_NAME_PREFIX = 'eb_';
/**
 * Название опции в описании компонента
 */
const OPTIONS_PROP_NAME = 'eventBusEvents';
/**
 * Внутреннее свойство экземпляра, где будут храниться установленные обработчики
 */
const VM_PROP_NAME = '_eventBusVmEvents';

const throwErrorIfNotValidName = name => {
  if (!name.startsWith(EVENT_NAME_PREFIX)) {
    throw new Error(
      `Event bus event names MUST be prefixed with \`eb_\`, given: ${name}`,
      name
    );
  }
};

const emitter = mitt();

export const eventBus = {
  get _mitt() {
    return emitter;
  },
  isListening(type, listener) {
    let listeners = emitter.all.get(type);
    if (!listeners) return false;
    return !!listeners.find(l => l === listener);
  },
  on(type, listener) {
    throwErrorIfNotValidName(type);
    let isListening = this.isListening(type, listener);
    if (!isListening) emitter.on(type, listener);
  },
  once(type, listener) {
    throwErrorIfNotValidName(type);
    function onceCB() {
      listener(...arguments);
      emitter.off(type, onceCB);
    }
    emitter.on(type, onceCB);
  },
  off(type, listener) {
    throwErrorIfNotValidName(type);
    emitter.off(type, listener);
  },
  emit(type, evt) {
    console.log(`%c[eventBus] ${type}`, 'background: #ff9d00;', evt);
    throwErrorIfNotValidName(type);
    emitter.emit(type, evt);
    $log(`Emit: ${type}`, { type, evt }, 'EventBus');
  },
};

export const EventBusPlugin = {
  install(VueLocal) {
    Object.defineProperty(VueLocal.prototype, '$eventBus', {
      get() {
        return eventBus;
      },
    });

    VueLocal.mixin({
      created() {
        const vmEvents = this.$options[OPTIONS_PROP_NAME] || {};
        this[VM_PROP_NAME] = {};

        Object.keys(vmEvents).forEach(evt => {
          const handler = vmEvents[evt].bind(this);
          this[VM_PROP_NAME][evt] = handler;
          eventBus.on(evt, handler);
        });
      },
      beforeDestroy() {
        const vmEvents = this[VM_PROP_NAME];
        Object.keys(vmEvents).forEach(evt => {
          eventBus.off(evt, vmEvents[evt]);
        });
      },
    });
  },
};
