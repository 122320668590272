<template>
  <RenderlessProduct
    v-if="product"
    v-slot="{
      replace,
      quantity,
      isPending,
      addToCart,
      isFavorite,
      addToFavorites,
      removeFromCart,
      removeFromFavorites,
      showBrandName,
    }"
    :product="product"
  >
    <ProductCard
      :title="productName"
      :image="productImage"
      :price="product.price"
      :old-price="oldPrice"
      :quantity="compQuantity(quantity)"
      :max-quantity="isWeightable ? Infinity : product.max_quantity || 0"
      :is-available="isAvailable"
      :labels="product.labels || []"
      :has-favorite-btn="isAuthorized"
      :full-width-image="fullWidthImage"
      :has-video="hasVideo"
      :is-favorite="isFavorite"
      :is-replace="isReplaceable"
      :is-weight="isWeightable"
      :is-weight-added="isWeightable && !!compQuantity(quantity)"
      :is-mix-blend="!hasRealBgOfPicture"
      :is-blured="checkIsImgBlured"
      :loading="isAuthorized && isPending"
      :brand-name="product.brand_name"
      :show-brand-name="showBrandName"
      :loyalty-percent="
        $store.getters['loyalty/available'] && isAvailable ? 1 : 0
      "
      @onSave="addToFavorites(product.id)"
      @onUnsave="removeFromFavorites(product.id)"
      @onMaxLimit="onMaxLimit"
      @onIncrement="onIncrement(addToCart)"
      @onDecrement="onDecrement(removeFromCart)"
      @onAdd="onAdd($event, addToCart, replace)"
      @onClickProduct="onProductClick"
    />
  </RenderlessProduct>
</template>

<script>
import { hasSupportInlineMedia } from '@/lib/video/has-support-inline-media';
import { RenderlessProduct } from '@shared/components/product/RenderlessProduct';
import { Analitycs, EVENTS } from '@shared/services/analitycs';
import { formatPrice } from '@shared/utils/formatters/price';
import { onAdd, onDec } from '@services/product/analytics';
import { createStorage } from '@shared/services/storage';
import { PRODUCT_ADDED } from '@shared/config/product';
import { ProductService } from '@services/product';
import { AdultsService } from '@services/adults';
import { RahmetApp } from '@shared/RahmetApp';
import { mapGetters } from 'vuex';

import ProductCard from '@pure-ui/components/Product/ProductCard/ProductCard.vue';
import { DevConfigs } from '@/utils/dev-config';

export default {
  name: 'ProductCardWrapper',
  components: {
    RenderlessProduct,
    ProductCard,
  },
  inject: ['toast'],
  props: {
    product: {
      type: Object,
      required: true,
    },
    addedFrom: {
      type: String,
      default: PRODUCT_ADDED.CATEGORY,
    },
    productDeeplink: {
      type: Number,
      default: -1,
    },
    position: {
      type: Number,
      default: undefined,
    },
  },
  data: () => ({}),
  computed: {
    ...mapGetters('user', ['hasRealBgOfPicture']),
    ...mapGetters(['isAuthorized']),
    isAvailable() {
      const q = this.product.max_quantity;
      return typeof q !== 'undefined' && q > 0;
    },
    oldPrice() {
      return this.product.old_price ? this.product.old_price : 0;
    },
    productName() {
      if (!this.product.feature_name) {
        return this.product.name;
      }

      const arr = this.product.feature_name.split(' ');

      return `${this.product.name}, ${Number(arr[0].replace(',', '.'))}
      ${arr[1]}`;
    },
    hasVideo() {
      return (
        !this.$isDesktop &&
        this.product.video_preview_path &&
        hasSupportInlineMedia()
      );
    },
    productImage() {
      if (this.hasVideo) return this.product.video_preview_path;
      return this.product.path;
    },
    checkIsImgBlured() {
      return (
        AdultsService.checkIsAdultProduct(this.product) &&
        !(
          AdultsService.checkIsAgreed() ||
          this.$store.getters['adults/isAgreed']
        )
      );
    },
    isWeightable() {
      return this.product.feature_id && this.product.features_quantity > 1;
    },
    isReplaceable() {
      return this.product.replacement && this.product.replacement.replacement;
    },
    fullWidthImage() {
      let productsWithFullWidth = DevConfigs.isProd
        ? [18993, 15072]
        : [15072, 5476, 2565, 15691];
      return this.hasVideo || productsWithFullWidth.includes(this.product.id);
    },
  },
  mounted() {
    if (this.product.id === this.productDeeplink) {
      this.onProductClick();
    }
  },
  methods: {
    formatPrice,
    checkHasAppliances() {
      const storage = createStorage(window.localStorage);
      if (
        [574, 745, 746, 576].includes(this.product.category_id) &&
        this.product.id !== 11700 &&
        !storage.parse('appliancesAreShown')
      ) {
        this.$eventBus.emit('eb_open_appliance_reminder_modal');
        storage.set('appliancesAreShown', true);
      }
    },
    onAddToCart(addToCart) {
      this.checkHasAppliances();

      RahmetApp.hapticSelection();
      onAdd(this.addedFrom, this.product, false, 1, this.position);
      addToCart(this.addedFrom, this.product.category_id);
    },
    onIncrement(addToCart) {
      RahmetApp.hapticSelection();
      onAdd(this.addedFrom, this.product, true, this.quantity, this.position);
      addToCart(this.addedFrom, this.product.category_id);
    },
    onDecrement(removeFromCart) {
      RahmetApp.hapticSelection();
      onDec(this.addedFrom, this.product);
      removeFromCart();
    },
    onAdd(type, addToCart, replace) {
      if (type === 'weight') this.onProductClick();
      else if (type === 'replace') this.onReplaceProduct(replace);
      else this.onAddToCart(addToCart);
    },
    onProductClick(isAppliances = false) {
      if (this.product.is_sample) return;

      let query = ProductService.buildQueryOnGoToProduct(
        this.product,
        isAppliances,
        this.addedFrom,
        this.$i18n.locale
      );

      if (this.$isDesktop) {
        this.$router.push({
          name: 'product-full',
          params: {
            id: this.product.id.toString(),
          },
          query,
        });
      } else {
        this.$eventBus.emit('eb_open_product_details_modal', {
          id: this.product.id,
          query,
        });
      }
    },
    onReplaceProduct(replace) {
      RahmetApp.hapticSelection();
      Analitycs.logEvent(EVENTS.RECIPE_CHANGE_PRODUCT, {
        recipe_id: this.product.replacement.recipe_id,
        product_id: this.product.replacement.product_id,
        feature_id: this.product.replacement.feature_id,
        replace_product_id: this.product.id,
        replace_feature_id: this.product.feature_id,
      });
      replace().then(() => {
        this.toast.show(this.$t('replacingIngridientsHasBeenDoneSuccessfully'));
        this.$router.push({
          name: 'recipe-full',
          params: { id: this.product.replacement.recipe_id },
        });
      });
    },
    onMaxLimit() {
      RahmetApp.hapticNotification('error');
    },
    compQuantity(q) {
      if (!this.isWeightable) {
        return q;
      } else {
        return this.$store.getters['cart/items'].reduce((acc, v) => {
          if (v.product_id === this.product.id) return v.quantity + acc;
          return acc;
        }, 0);
      }
    },
  },
  i18n: {
    messages: {
      ru: {
        replacingIngridientsHasBeenDoneSuccessfully:
          'Замена ингредиента прошла успешно',
        thankYouForFeedback: 'Спасибо за обратную связь, мы записали!',
      },
      kk: {
        replacingIngridientsHasBeenDoneSuccessfully:
          'Ингредиентті ауыстыру сәтті өтті',
        thankYouForFeedback: 'Кері байланысыңыз үшін!',
      },
    },
  },
};
</script>
