import { mapGetters } from 'vuex';
import { RahmetApp } from '@shared/RahmetApp';
import { getAddressText } from '@services/address/lib/get-address-text';
import { getWorkingTimeFromWarehouse } from '@components/open-time/lib/get-working-time-from-warehouse';

export default {
  name: 'RenderlessAddressContext',
  props: {
    cart: Boolean,
  },
  computed: {
    ...mapGetters('delivery', [
      'expectedDeliveryTime',
      'warehouse',
      'isWarehouseOpen',
      'closureReason',
    ]),
    ...mapGetters('user', [
      'deliveryAddress',
      'addressCount',
      'isAddressLoading',
    ]),
    ...mapGetters('paidOrder', ['isDistant']),
    ...mapGetters(['appLoader', 'appInitLoader']),

    addressTitle() {
      return (
        getAddressText(this.deliveryAddress, {
          lang: this.$i18n.locale,
          withName: true,
        }) || this.$t('provideAddress')
      );
    },
    isAppLoading() {
      return this.appInitLoader || this.appLoader;
    },
    noDelivery() {
      return this.deliveryAddress && !this.warehouse;
    },
    openTime() {
      return getWorkingTimeFromWarehouse(this.warehouse).openTime;
    },
    timeTitle() {
      if (
        this.isAppLoading ||
        this.isAddressLoading ||
        this.addressCount === 0
      ) {
        return this.$t('deliveryTimeFrom15');
      }
      if (this.noDelivery) {
        return this.$t('noDelivery');
      }
      if (!this.isWarehouseOpen && this.warehouse) {
        return this.closureReason
          ? this.$t('temporaryClosed')
          : this.$t('startOrdering', [this.openTime]);
      }

      let [first, second] = this.expectedDeliveryTime.split('-');
      return this.$tc('deliveryTime', second, [first, second]);
    },
    showDistantIcon() {
      if (this.isAppLoading || this.isAddressLoading || this.addressCount === 0)
        return false;
      if (this.noDelivery) return false;
      if (!this.isWarehouseOpen && this.warehouse) return false;
      return !!this.isDistant;
    },
  },
  mounted() {
    this.controllDeeplink();
    this.$store.dispatch('delivery/FETCH_EXPECTED_DELIVERY_TIME');
  },
  methods: {
    openAddressList() {
      this.$eventBus.emit('eb_open_address_list', {
        from: this.cart ? 'cart_mobile_header' : 'main_mobile_header',
      });
    },
    goCreateAddress() {
      this.$eventBus.emit('eb_open_address_create_container', {
        from: this.cart ? 'cart_mobile_header' : 'main_mobile_header',
      });
    },
    onAddressClick() {
      RahmetApp.hapticSelection();
      this.addressCount !== 0 ? this.openAddressList() : this.goCreateAddress();
    },
    controllDeeplink() {
      if (this.$route.params.changeAddress) this.openAddressList();
    },
  },
  i18n: {
    messages: {
      ru: {
        provideAddress: 'Укажите адрес доставки',
        deliveryTime:
          'Доставим за {0}-{1} минут | Доставим за {0}-{1} минуту | Доставим за {0}-{1} минуты | Доставим за {0}-{1} минут ',
        deliveryTimeFrom15: 'Доставка от 15 минут',
        noDelivery: 'Сюда пока не доставляем',
        startOrdering: 'Принимаем заказы с {0}',
        temporaryClosed: 'Временно не принимаем заказы',
      },
      kk: {
        provideAddress: 'Жеткізу мекенжайын таңдаңыз',
        deliveryTime: '{0}-{1} минутта жеткіземіз',
        deliveryTimeFrom15: 'Жеткізу 15 минуттан',
        noDelivery: 'Бұл жерге әзір жеткізбейміз',
        startOrdering: 'Тапсырыстарды {0}-қабылдаймыз',
        temporaryClosed: 'Уақытша тапсырыс қабылдамаймыз',
      },
    },
  },
  render() {
    return this.$scopedSlots.default({
      deliveryTime: this.expectedDeliveryTime,
      showDistantIcon: this.showDistantIcon,
      addressTitle: this.addressTitle,
      timeTitle: this.timeTitle,
      onAddressClick: this.onAddressClick,
    });
  },
};
