<template>
  <UiPopup
    :show="modelValue"
    :full-page="!$isDesktop"
    :with-title="$isDesktop"
    :closable="$isDesktop"
    :transition="$isDesktop ? 'popup-fade' : 'popup-slide'"
    style="--popup-width-d: 588px"
    @change="$emit('update:modelValue', $event)"
    @onClose="$emit('onClose')"
  >
    <template v-if="$isDesktop" #title>
      {{ $t('fillAddressDetails') }}
    </template>

    <UiLoader v-if="loading" class="AddressFormModal__loader" />

    <div v-else id="AddressFormModal" class="AddressFormModal">
      <p v-if="$isDesktop" class="AddressFormModal__title" @click="onGoBack">
        <IconArrow class="mr-1" /> {{ address }}
      </p>

      <template v-if="!$isDesktop">
        <HeaderBase class="AddressFormModal__header" @onBack="onGoBack">
          <!-- maybe -->
          <!-- <template #left> <span></span> </template> -->
          {{ $t('title') }}
        </HeaderBase>
        <div class="AddressFormModal__streetInput" @click="onGoBack(true)">
          <UiTextField
            :value="address"
            :label="$t('streetBuildingHouse')"
            name="addressFormStreetInput"
          />
          <div class="AddressFormModal__streetInputCover"></div>
        </div>
      </template>

      <AddressForm
        id="form"
        :state="state"
        @onMaxLength="$emit('onMaxLength', $event)"
        @onFillAll="$emit('onFillAll', $event)"
        @onSubmit="$emit('onSubmit', $event)"
        @onValidationChange="$emit('onValidationChange', $event)"
      />

      <p v-if="$isDesktop" class="AddressFormModal__note">
        {{ $t('provideAddressName') }}
      </p>

      <div class="AddressFormModal__btn">
        <UiButton form="form" size="LG"> {{ $t('saveAddress') }} </UiButton>
      </div>
    </div>
  </UiPopup>
</template>

<script>
import AddressForm from '@pure-ui/components/Address/AddressForm/AddressForm.vue';
import UiTextField from '@pure-ui/components/UiTextField/UiTextField.vue';
import HeaderBase from '@components/header/components/HeaderBase.vue';
import UiLoader from '@pure-ui/components/UiLoader/UiLoader.vue';
import UiButton from '@pure-ui/components/UiButton/UiButton.vue';
import UiPopup from '@pure-ui/components/UiPopup/UiPopup.vue';
import IconArrow from './components/IconArrow.vue';

export default {
  components: {
    AddressForm,
    UiTextField,
    HeaderBase,
    IconArrow,
    UiButton,
    UiPopup,
    UiLoader,
  },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    modelValue: Boolean,
    loading: Boolean,
    state: {
      type: Object,
      default: null,
    },
    address: {
      type: String,
      default: '',
    },
  },
  methods: {
    onGoBack(toMap = false) {
      this.$emit('update:modelValue', false);
      this.$emit('onGoBack', toMap);
    },
  },
  i18n: {
    messages: {
      kk: {
        streetBuildingHouse: 'Көше және үй/ғимарат',
        saveAddress: 'Мекенжайды сақтау',
        title: 'Жеткізу мекенжайы',
        fillAddressDetails: 'Мекен-жайыңызды толтырыңыз',
        provideAddressName: 'Ыңғайлы болу үшін мекен-жайға ат қоюыңызға болады',
      },
      ru: {
        streetBuildingHouse: 'Улица и здание/дом',
        saveAddress: 'Сохранить адрес',
        title: 'Адрес доставки',
        fillAddressDetails: 'Заполните данные адреса',
        provideAddressName:
          'Укажите название, чтобы легко ориентироваться между своими адресами',
      },
    },
  },
};
</script>

<style scoped>
.AddressFormModal {
  @apply h-full flex flex-col;
}
.AddressFormModal__header {
  @apply -mx-2;
}
.AddressFormModal__title {
  @apply my-4 text-lg font-bold leading-6
  text-sirius-orange-100 md:cursor-pointer
  
  md:mt-7 md:mb-5 md:flex md:items-center md:text-2xl md:leading-30;
}
.AddressFormModal__streetInput {
  @apply my-4 relative;
}
.AddressFormModal__streetInputCover {
  @apply absolute top-0 w-full h-full bg-transparent;
}
.AddressFormModal__note {
  @apply pl-1 mt-1 
  font-normal text-xs leading-14
  text-sirius-gray-1000;
}

.AddressFormModal__btn {
  @apply mt-auto md:mt-4 pt-4;
}
.AddressFormModal__loader {
  @apply h-full w-full m-auto;
}
</style>
