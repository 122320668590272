import {
  INITIAL_COORDS,
  YMAPS_SETTINGS,
  NEW_POLYGON_SETTINGS,
} from '@services/map/constants';
import { defineGeolocation, geocodeSinglePosition } from '@services/map/utils';
import { validateGeoObject } from '@services/map/utils/validate-geoobject';
import { getDeliveryZoneValidationsOfCoords } from '@services/map/utils/map-polygon';
import { loadYmap } from 'vue-yandex-maps';
import { $log } from '@/utils/plugins/logger';

export default class YandexMap {
  constructor(
    onMapInit,
    onMapClick,
    onMapActionEnd,
    onMapActionStart,
    onMapCenterUpdated
  ) {
    this.onMapInit = onMapInit;
    this.onMapClick = onMapClick;
    this.onMapActionEnd = onMapActionEnd;
    this.onMapActionStart = onMapActionStart;
    this.onMapCenterUpdated = onMapCenterUpdated;

    this.polygons = {
      regular: [],
      extended: [],
    };
  }

  getOptions() {
    $log('YandexMap getOptions', this.map);
    if (!this.map)
      return {
        coords: INITIAL_COORDS,
        zoom: 16,
      };

    const mapCoords = this.map.getCenter();
    return {
      coords: mapCoords,
      zoom: this.map.getZoom(),
    };
  }

  onCenterUpdated() {
    let options = this.getOptions();
    $log('YandexMap onCenterUpdated', { options });
    this.getGeoObjectByCoords(options.coords).then(validatedCollection => {
      let isPolygonsLoaded =
        this.polygons.regular.length || this.polygons.extended.length;
      let data = {
        ...options,
        ...validatedCollection,
        isPolygonsLoaded,
      };
      this.onMapCenterUpdated(data);
    });
  }

  getGeoObjectByCoords(coords) {
    $log('YandexMap getGeoObjectByCoords', { coords });
    return geocodeSinglePosition(window.ymaps, coords).then(collection => {
      let geoObject = collection.featureMember[0];
      const [long, lat] = geoObject.GeoObject.Point.pos.split(' ');

      let geoObjectValidations = validateGeoObject(geoObject);

      let isInDeliveryZoneValidations = getDeliveryZoneValidationsOfCoords(
        window.ymaps,
        [lat, long],
        this.polygons.regular,
        this.polygons.extended
      );

      let validatedGeoObject = {
        ...geoObject,
        ...geoObjectValidations,
        ...isInDeliveryZoneValidations,
      };
      return validatedGeoObject;
    });
  }
  // @prop {String} type in|out
  zoom(type = 'in') {
    $log('YandexMap zoom', { type });
    let currentZoom = this.map.getZoom();
    if (type === 'in' && currentZoom === 20) return;
    if (type === 'out' && currentZoom === 2) return;

    return this.map.setZoom(type === 'in' ? ++currentZoom : --currentZoom, {
      duration: 300,
    });
  }

  defineByCoords(coords) {
    $log('YandexMap defineByCoords', { coords });
    return this.map
      .setCenter(coords, 16, {
        duration: 500,
        timingFunction: 'ease',
      })
      .then(res => {
        $log('YandexMap defineByCoords then', { res });
      });
  }

  /**
   *
   * @param {Number} timeout число в ms 3000
   * @returns
   */
  findGeoLocation(timeout = 0, fallbackCoords) {
    $log('YandexMap findGeoLocation', { timeout, fallbackCoords });
    const timeoutPromise = new Promise(resolve => {
      $log('YandexMap timeoutPromise', { timeout });
      return setTimeout(() => resolve(fallbackCoords), timeout);
    });
    const definePromise = new Promise(() => {
      $log('YandexMap definePromise', { timeout });
      return defineGeolocation(window.ymaps);
    });

    let promises = [definePromise];
    if (timeout !== 0) {
      promises.push(timeoutPromise);
    }

    return Promise.race(promises)
      .then(coords => {
        $log('YandexMap Promise.race', { coords });
        return this.defineByCoords(coords);
      })
      .then(() => this.onCenterUpdated());
  }

  onClick(e) {
    var coords = e.get('coords');
    $log('YandexMap onClick', { coords });
    return this.map.panTo(coords).then(() => {
      this.onMapClick(this.getOptions());
      this.onCenterUpdated();
    });
  }

  setListeners() {
    $log('YandexMap setListeners');
    this.map?.events.add('click', e => this.onClick(e));
    this.map?.events.add('actionbegin', event => {
      $log('YandexMap actionbegin', event);
      this.onMapActionStart(this.getOptions());
    });

    this.map?.events.add('actionend', event => {
      $log('YandexMap actionend', event);

      this.onMapActionEnd(this.getOptions());
      this.onCenterUpdated();
    });
  }

  createMap(id, coords, zoom) {
    $log('YandexMap createMap', { id, coords, zoom });
    this.map = new window.ymaps.Map(
      id,
      {
        zoom: zoom || 16,
        center: coords || INITIAL_COORDS,
        controls: [],
      },
      {
        yandexMapDisablePoiInteractivity: true,
      }
    );
  }

  createPolygonMarker(type = 'regular', index, coords) {
    let polygonMap = {
      regular: NEW_POLYGON_SETTINGS.regular,
      regularNearExtended: NEW_POLYGON_SETTINGS.regularNearExtended,
      extended: NEW_POLYGON_SETTINGS.extended,
      custom: {
        fillColor: '#ccc',
        fillOpacity: 0.2,

        strokeColor: '#ccc',
        strokeOpacity: 0.8,
        strokeWidth: 2,
      },
    };

    return new window.ymaps.GeoObject(
      {
        geometry: {
          type: 'Polygon',
          coordinates: coords,
          id: `${type}_${index}`,
        },
      },
      {
        ...polygonMap[type],
      }
    );
  }
  setPolygons(polygons) {
    $log('YandexMap setPolygons', { polygons });
    if (!this.map) return;
    if (!polygons?.regular?.length) return;

    this.polygons = polygons;

    let paintPolygons = polygons => {
      polygons.forEach((polygon, index) => {
        let polygonObj = this.createPolygonMarker(
          polygon.type,
          index,
          polygon.coordinates
        );
        this.map.geoObjects.add(polygonObj);
        polygonObj.events.add('click', e => this.onClick(e));
      });
    };

    paintPolygons(polygons.extended);
    paintPolygons(polygons.regular);
    this.onCenterUpdated();
  }

  async initMap(id, coords, zoom, listeners = true) {
    $log('YandexMap initMap', { id, coords, zoom, listeners });
    await loadYmap(YMAPS_SETTINGS);

    this.createMap(id, coords, zoom);

    this.onMapInit(this.getOptions());
    this.onCenterUpdated();

    if (listeners) this.setListeners();
  }
}
