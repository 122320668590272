import { StaticBanners } from '@services/banners/static-banners';
import { DevConfigs } from '@/utils/dev-config';

/**
 * @typedef CategoryBanner
 * @prop {number} categoryId
 * @prop {string} code
 * ...
 * @see @/src/services/banners/adapters/categoryBanner.js
 */

const vegetables = [
  '390', // Фрукты, ягоды и бахчевые
  '391', // Овощи, зелень и грибы
];

/**
 * Возвращает баннер для текущей группы товаров или для страницы,
 * в зависимости от указанного id категории
 * Для категорий овощей/фруктов возвращает статичный баннер
 * @param {*} bannerCategoryId
 * @param {*} index
 * @returns {CategoryBanner | null}
 */
export function getCategoryBanner(bannerCategoryId, index) {
  if (index === 0) {
    const pageCategoryId = this.$route.params.id;
    if (vegetables.includes(pageCategoryId)) {
      return StaticBanners.categoryFreshBanner;
    }

    const pageBanner =
      this.$store.getters['banners/categoryBanners'][pageCategoryId];
    if (pageBanner) {
      return pageBanner;
    }
  }

  // "Испекли сегодня"
  if (bannerCategoryId === (DevConfigs.isProd ? 1294 : 760)) {
    return StaticBanners.categoryLamberBanner;
  }

  if (bannerCategoryId === (DevConfigs.isProd ? 1431 : 820)) {
    return StaticBanners.categoryPersimmonBanner;
  }

  const categoryBanner =
    this.$store.getters['banners/categoryBanners'][bannerCategoryId];

  return categoryBanner ?? null;
}
