<template>
  <MainLayout with-checkout-reminder>
    <template v-if="$isWebView" #header>
      <ToolBar bottom-toolbar>
        <p class="truncate">
          {{ $t('youBoughtIt') }}
        </p>

        <!--          Временно скрыли потому что нет фильтров на бэке. Задачу возьмем позже-->
        <template v-if="false" #right-side>
          <div class="ml-2" @click="$emit('onFilterPopupSetShow', true)">
            <IconFilter />
          </div>
        </template>

        <template #bottom-toolbar>
          <div class="px-4">
            <SearchTrigger
              disabled
              @click.native="$router.push({ name: 'search' })"
            />
          </div>
        </template>
      </ToolBar>
    </template>
    <template v-else-if="!$isDesktop" #header>
      <WebMobileHeaderCategory />
    </template>

    <div :class="topSpacing">
      <!-- Десктопный заголовок и хлебные крошки -->
      <div v-if="$isDesktop" class="container" style="max-width: 988px">
        <UiBreadCrumbs class="pt-4 pb-8" />
        <div class="mt-8 flex justify-between">
          <h1 class="text-32 font-bold leading-10 pb-2">
            {{ $t('youBoughtIt') }}
          </h1>
          <!--          Временно скрыли потому что нет фильтров на бэке. Задачу возьмем позже-->
          <DesktopFilterButton
            v-if="false"
            @click="$emit('onFilterPopupSetShow', true)"
          />
        </div>
      </div>
      <!-- / Десктопный заголовок и хлебные крошки -->

      <div class="mobile-container px-3">
        <div class="container pt-2 md:pt-8" style="max-width: 988px">
          <template v-if="!$isWebView && !$isDesktop">
            <h1 class="text-2xl font-bold leading-10 pb-4">
              {{ $t('youBoughtIt') }}
            </h1>
          </template>
          <UiRippleLoader v-if="loading" />
          <template v-if="emptyProducts && !loading && $isWebView">
            <NoShoppingHistory></NoShoppingHistory>
          </template>
          <template v-else>
            <UiInfiniteScroll
              :is-loading="pagination.loading"
              class="duration-100"
              @callback="$emit('onNextPage')"
            >
              <div
                class="products grid gap-x-2 gap-y-2 grid-cols-3 md:grid-cols-5"
              >
                <div v-for="product in products" :key="product.id">
                  <ProductCardWrapper
                    :product="
                      product.product_info ? product.product_info : product
                    "
                    :added-from="addedFrom"
                  />
                </div>
              </div>
            </UiInfiniteScroll>
          </template>
        </div>
      </div>
    </div>

    <FilterSheetPopup
      v-bind="{
        ...filterPopup,
        sortOptions,
      }"
      @onClose="$emit('onFilterPopupSetShow', false)"
      @onSortChange="$emit('onFilterPopupSortChange', $event)"
      @onSubmit="$emit('onFilterPopupSubmit')"
    />
  </MainLayout>
</template>

<script>
import MainLayout from '@layout/MainLayout.vue';
import ToolBar from '@layout/ToolBar.vue';
import SearchTrigger from '@pure-ui/components/SearchTrigger/SearchTrigger.vue';
import ProductCardWrapper from '@shared/components/product/ProductCardWrapper.vue';
import UiRippleLoader from '@ui/UiRippleLoader.vue';
import UiBreadCrumbs from '@ui/UiBreadCrumbs.vue';
import UiInfiniteScroll from '@ui/UiInfiniteScroll.vue';
import IconFilter from '@shared/components/icons/IconFilter.vue';
import FilterSheetPopup from '@/views/favorites/components/FilterSheetPopup.vue';
import DesktopFilterButton from '../favorites/components/DesktopFilterButton.vue';
import WebMobileHeaderCategory from '@components/header/WebMobileHeaderCategory.vue';
import NoShoppingHistory from '@/views/shopping-history/NoShoppingHistory.vue';

export default {
  name: 'ShoppingHistoryPage',
  components: {
    NoShoppingHistory,
    IconFilter,
    UiInfiniteScroll,
    WebMobileHeaderCategory,
    DesktopFilterButton,
    UiBreadCrumbs,
    UiRippleLoader,
    SearchTrigger,
    ToolBar,
    MainLayout,
    ProductCardWrapper,
    FilterSheetPopup,
  },
  props: {
    products: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    addedFrom: {
      type: String,
      required: true,
    },
    filterPopup: {
      type: Object,
      default: () => ({}),
    },
    pagination: {
      type: Object,
      default: () => ({
        loading: false,
      }),
    },
  },
  computed: {
    /*
     Не очень удачное решение, нужно будет убрать
     */
    topSpacing() {
      if (this.$isDesktop) return 'mt-10';
      if (!this.$isWebView) return 'mt-16';
      return 'mt-16';
    },
    sortOptions() {
      return [
        { id: 'last_shopped_at:desc', title: this.$t('recentlyBought') },
        { id: 'frequency:desc', title: this.$t('frequentlyBought') },
      ];
    },
    emptyProducts() {
      return this.products.length === 0;
    },
  },
  i18n: {
    messages: {
      ru: {
        youBoughtIt: 'Мои покупки',
        recentlyBought: 'недавно купленные',
        frequentlyBought: 'часто покупаемые',
      },
      kk: {
        youBoughtIt: 'Сатып алғаным',
        recentlyBought: 'жақында сатып алынғандар',
        frequentlyBought: 'жиі сатып алынатындар',
      },
    },
  },
};
</script>
