import { reviewClient } from '../../clients/review';

export default function (payload) {
  // if (import.meta.env.VITE_DEPLOYMENT !== 'DEVELOPMENT')
  return reviewClient
    .post('/ryadom-review/api/v1/review/order', payload)
    .then(res => {
      return res.data;
    });
  // return Promise.resolve(import('./mocks/leave-review.json'));
}
