<template>
  <HeaderBaseWrapper>
    <template #header-top>
      <HeaderBase>
        <template #left>
          <IconBack @click.native="$emit('onBack')" />
        </template>

        {{ $t('cartTitle') }}

        <template v-if="cartBtn" #right>
          <IconTrash @click.native="$emit('onClearCart')" />
        </template>
      </HeaderBase>
    </template>
    <template #header-middle> <CartAddressContext /> </template>
    <template #header-bottom> <div class="header-divider"></div> </template>
  </HeaderBaseWrapper>
</template>

<script>
import CartAddressContext from '@components/address-context/CartAddressContext.vue';
import HeaderBaseWrapper from './components/HeaderBaseWrapper.vue';
import HeaderBase from './components/HeaderBase.vue';
import IconTrash from './components/IconTrash.vue';
import IconBack from './components/IconBack.vue';

export default {
  components: {
    CartAddressContext,
    HeaderBaseWrapper,
    HeaderBase,
    IconTrash,
    IconBack,
  },
  props: {
    cartBtn: {
      type: Boolean,
      default: false,
    },
  },
  i18n: {
    messages: {
      ru: {
        cartTitle: 'Корзина',
      },
      kk: {
        cartTitle: 'Себет',
      },
    },
  },
};
</script>

<style scoped>
.header-divider {
  @apply pt-1 w-full relative;
}
.header-divider:after {
  @apply border-t-2 border-sirius-gray-200;
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: calc(100% - 32px);
}
</style>
