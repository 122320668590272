import Vue from 'vue';
import VueGtm from '@gtm-support/vue2-gtm';
import IsDesktopPlugin from '@/utils/plugins/is-desktop';
import IsWebviewPlugin from '@/utils/plugins/is-webview';
import { ImageProxyDirective } from '@services/image-proxy/image-proxy-directive';
import { createBottomSheet } from '@ui/bottom-sheet/UiBottomSheetV2.vue';
import { createBottomSheetPopup } from '@ui/bottom-sheet-popup';
import { EventBusPlugin } from '@/utils/plugins/event-bus';
import { LoggerPlugin } from '@/utils/plugins/logger';
import { DevConfigs } from '@/utils/dev-config';
import { i18n } from '@shared/services/locale';
import { RahmetApp } from '@shared/RahmetApp';
import { createPopup } from '@ui/ui-popup';
import { AppService } from '@services/app';
import { ToastApp } from '@ui/ui-toast';

import { markPageLoadStart } from './utils/performance/page-load-time';
import floatingElementDirective from './utils/directives/floating-element/index';
import { installAuthGuard } from './router/auth-guard';
import { store } from './store';
import { globals } from './lib/globals';

import '@/assets/styles/main.css';
import router from './router';
import App from './App.vue';

markPageLoadStart();

Vue.config.productionTip = false;

if (DevConfigs.isDev && DevConfigs.isMiniApp) {
  RahmetApp.setMocsForDevelopment();
}

RahmetApp.setupWebViewApi();
AppService.init();

const isGtmEnabled =
  import.meta.env.VITE_DEPLOYMENT === 'PRODUCTION' && !RahmetApp.isWebView();
Vue.use(VueGtm, {
  id: 'GTM-MXMGCGZ',
  enabled: isGtmEnabled,
});

ToastApp.mount();

Vue.use(IsDesktopPlugin);
Vue.use(IsWebviewPlugin);
Vue.use(EventBusPlugin);
Vue.use(LoggerPlugin);
Vue.use(ImageProxyDirective);

Vue.directive('floating-element', floatingElementDirective);

installAuthGuard(router, store, {
  onReject(err) {
    if (err.message === 'DefaultReject') return;
    if (err.message === 'NO_DELIVERY') return;

    const message =
      err.error_code !== 401
        ? err.message || 'Произошла ошибка'
        : 'Не удалось авторизоваться';

    ToastApp.toast.show(message);
  },
});

const root = new Vue({
  i18n,
  router,
  store,
  provide() {
    return {
      bottomSheetPopup: createBottomSheetPopup(),
      bottomSheet: createBottomSheet(),
      popup: createPopup(),
      toast: ToastApp.toast,
    };
  },
  render: h => h(App),
});
root.$mount('#app');
globals.rootApp = root;
