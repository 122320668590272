<template>
  <div class="">
    <UiBottomSheetWrapper
      :show="modelValue"
      :with-title="!noDelivery"
      style="--shift-plank-top: -20px"
      @onClose="$emit('onClose')"
      @change="$emit('update:modelValue', $event)"
    >
      <template v-if="!noDelivery" #title>
        {{ $t('selectAddress') }}
      </template>
      <div class="SearchListModal" :class="{ '--with-title': !noDelivery }">
        <UiTextField
          ref="streetInput"
          :value="street"
          :label="$t('streetBuildingHouse')"
          type="text"
          name="streetInput"
          :clearable="isClearable"
          :autofocus="autofocus"
          @input="onInput"
          @onClear="onClear"
        />

        <SearchList
          :loading="loading"
          :message-type="messageType"
          :no-delivery="noDelivery"
          :saved-addresses="savedAddresses"
          :searched-addresses="searchedAddresses"
          @onReturnToMap="$emit('onReturnToMap')"
          @onNoDeliveryToAddress="
            () => ((isClearable = false), $emit('onNoDeliveryToAddress'))
          "
          @typeNew="onTypeNew"
          @goToMain="$emit('goToMain')"
          @saveAnyway="$emit('saveAnyway')"
          @onSelectSavedAddress="$emit('onSelectSavedAddress', $event)"
          @onSelectSearchedAddress="$emit('onSelectSearchedAddress', $event)"
        >
          <template #return>
            <div
              class="SearchListModal__returnMap"
              @click="$emit('onReturnToMap')"
            >
              <IconReturn /> <span>{{ $t('returnToMap') }}</span>
            </div>
          </template>
        </SearchList>
      </div>
    </UiBottomSheetWrapper>
  </div>
</template>

<script>
import UiBottomSheetWrapper from '@pure-ui/components/UiBottomSheetWrapper/UiBottomSheetWrapper.vue';
import SearchList from '@pure-ui/components/Address/SearchList/SearchList.vue';
import UiTextField from '@pure-ui/components/UiTextField/UiTextField.vue';
import IconReturn from './components/IconReturn.vue';

export default {
  name: 'SearchListModal',
  components: {
    UiBottomSheetWrapper,
    UiTextField,
    IconReturn,
    SearchList,
  },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    modelValue: {
      type: Boolean,
      default: true,
    },
    street: {
      type: String,
      default: '',
    },
    messageType: {
      type: String,
      default: '',
    },
    noDelivery: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    savedAddresses: {
      type: Array,
      default: () => [],
    },
    searchedAddresses: {
      type: Array,
      default: () => [],
    },
    autofocus: Boolean,
  },
  data() {
    return {
      isClearable: true,
    };
  },
  methods: {
    // Access from out
    focusInput() {
      this.$refs.streetInput.focusIt();
    },
    onTypeNew() {
      console.log('onTypeNew');

      this.focusInput();
      this.$emit('typeNew');
    },
    onClear() {
      this.isClearable = true;
      this.$emit('onClear');
      this.$emit('update:street', '');
    },
    onInput(event) {
      this.isClearable = true;
      this.$emit('update:street', event.target.value);
    },
  },
  i18n: {
    messages: {
      kk: {
        streetBuildingHouse: 'Көше және үй/ғимарат',
        returnToMap: 'Картаға қайта оралу',
        selectAddress: 'Жеткізу мекен жайын енгізіңіз',
      },
      ru: {
        streetBuildingHouse: 'Улица и здание/дом',
        returnToMap: 'Вернуться к выбору на карте',
        selectAddress: 'Укажите адрес доставки',
      },
    },
  },
};
</script>

<style scoped>
.SearchListModal {
  @apply mt-10 flex flex-col space-y-4;
  min-height: 260px;
}
.SearchListModal.--with-title {
  @apply mt-4;
  /* height: 100%; */
  /* padding-bottom: 300px; */
}
.SearchListModal__returnMap {
  @apply pt-2 pb-4 border-b-2 border-sirius-gray-400
  flex items-center space-x-2 
  font-medium leading-5;
  border-color: rgba(241, 242, 247, 0.7);
}
</style>
