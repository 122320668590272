import { client } from '../../clients/client';
import { isAuthorizedUrl } from '../../utils/is-authorized-url';

export default function (id, isAuthorized = false) {
  return client
    .get(`/lavka/v1${isAuthorizedUrl(isAuthorized)}/user_address/${id}`, {
      useDeviceId: true,
    })
    .then(response => response.data);
}
