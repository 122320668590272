import { store } from '@/store';

// closureReason will be saved if current polygonId

/**
 * @typedef ClosureReason
 * @prop {number} id
 * @prop {number[]|null} polygon_ids
 * @prop {string} title_kk
 * @prop {string} title_ru
 * @prop {string} description_kk
 * @prop {string} description_ru
 * @prop {string} reminder_title_kk
 * @prop {string} reminder_title_ru
 *
 *
 * @param {ClosureReason} reason - can be any reason which is changed from admin panel,
 * it can be a reason which is not for current polygon
 *
 */
export function onUpdateClosureReason(reason) {
  console.log('onUpdateClosureReason', reason);
  // when warehouse not found or not delivarable
  const warehouse = store.getters['delivery/warehouse'];
  if (!warehouse) return;

  // when reason not equal to current closure reason which is saved
  // and we do not need to change anything
  const currentClosureReason = store.getters['delivery/closureReason'];
  if (currentClosureReason && currentClosureReason.id !== reason.id) return;

  // if no current reason
  // or current reason and recieved reason is same
  const currentPolygonId = warehouse.polygon_id;
  const hasPolygonId = reason.polygon_ids.find(id => id === currentPolygonId);
  let data = { polygon_closure_reason: hasPolygonId ? reason : null };
  // reason has a polygon
  // closing polygon when it temporary closed
  store.commit('delivery/UPDATE_WAREHOUSE', data);

  store.dispatch('delivery/UPDATE_IS_WAREHOUSE_OPEN');
}
