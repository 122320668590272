import { getSource } from './image-proxy';

const DEFAULT_PRESET = {
  url: '',
  size: undefined,
  lazy: true,
  as: '',
  placeholder: '',
};

const imageProxy = {
  inserted(el, binding) {
    const binds = binding.value;
    let options = DEFAULT_PRESET;
    let fallback = undefined;

    if (typeof binds === 'string') {
      options.url = binds;
      fallback = binds;
    } else {
      options = { ...DEFAULT_PRESET, ...binds };
      fallback = binds.url;
    }

    const proxyUrl = getSource(options);

    // if (options.lazy) {
    //   binding.value = proxyUrl;
    //   const lazy = new Lazy({ error: fallback });
    //   lazy.add(el, binding);
    // } else {
    el.src = proxyUrl;

    if (fallback) {
      el.onerror = function () {
        this.onerror = null;
        this.src = fallback;
      };
    }
    // }
  },
};

export const ImageProxyDirective = {
  install(Vue) {
    console.log('ImageProxyDirective install');

    Vue.directive('img-proxy', imageProxy);
  },
};
