<template>
  <div class="MainSpecialCategories">
    <div class="MainSpecialCategories-items">
      <div
        v-for="category in categories"
        :key="category.type"
        class="MainSpecialCategories-item"
        @click="goToCategory(category)"
      >
        <component :is="category.icon"></component>
        <p class="font-medium">{{ $t(category.label) }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import IconPercent from '@pure-ui/components/MainSpecialCategories/icons/IconPercent.vue';
import IconStar from '@pure-ui/components/MainSpecialCategories/icons/IconStar.vue';
import IconBasket from '@pure-ui/components/MainSpecialCategories/icons/IconBasket.vue';
import { mapGetters } from 'vuex';
import { DevConfigs } from '@/utils/dev-config';
import { Analitycs, EVENTS } from '@shared/services/analitycs';

export default {
  name: 'MainSpecialCategories',
  components: { IconBasket, IconStar, IconPercent },
  data() {
    return {
      categories: [
        {
          type: 'sale',
          icon: 'IconPercent',
          label: 'saleCategory',
        },
        {
          type: 'new',
          icon: 'IconStar',
          label: 'newCategory',
        },
        {
          type: 'purchases',
          icon: 'IconBasket',
          label: 'purchasesCategory',
        },
      ],
    };
  },
  computed: {
    ...mapGetters('catalog', ['saleCategory']),
  },
  methods: {
    goToCategory(category) {
      if (category.type === 'sale') {
        Analitycs.logEvent(EVENTS.CATEGORY_SELECTED, {
          categoryId: 798,
          categoryName: 'Акции',
          from: 'main',
        });
        this.$router.push(`/category/${this.saleCategory.id}`);
      } else if (category.type === 'purchases') {
        this.$router.push({
          path: '/shopping-history',
          query: { from: 'main' },
        });
      } else if (category.type === 'new') {
        Analitycs.logEvent(EVENTS.CATEGORY_SELECTED, {
          categoryId: 797,
          categoryName: 'Новинки',
          from: 'main',
        });
        this.$router.push(`/category/${DevConfigs.isProd ? 797 : 586}`);
      }
    },
  },
  i18n: {
    messages: {
      ru: {
        saleCategory: 'Скидки',
        newCategory: 'Новинки',
        purchasesCategory: 'Мои покупки',
      },
      kk: {
        saleCategory: 'Жеңілдіктер',
        newCategory: 'Жаңа өнімдер',
        purchasesCategory: 'Сатып алғаным',
      },
    },
  },
};
</script>

<style scoped>
.MainSpecialCategories {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.MainSpecialCategories-items {
  width: 85%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.MainSpecialCategories-item {
  width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
}

.MainSpecialCategories-item p {
  font-size: 14px;
  color: #acaaba;
  margin-top: 8px;
}
</style>
