import { client } from '../../clients/client';

export function ofd() {
  return {
    orderBill(id, hash) {
      return client
        .get(`/ofd/v1/receipts?filter[orderId]=${id}&filter[hash]=${hash}`)
        .then(res => res.data);
    },
  };
}
