import { ERR_NO_DELIVERY, ERR_NO_ADDRESS } from '@shared/config/delivery';

export function validateState(state) {
  if (state.user.deliveryAddress == null) {
    return {
      invalid: true,
      err: new Error(ERR_NO_ADDRESS),
    };
  }

  if (
    !state.delivery.isFailedToFindWarehouse &&
    state.delivery.warehouse == null
  ) {
    return {
      invalid: true,
      err: new Error(ERR_NO_DELIVERY),
    };
  }

  return {
    invalid: false,
    err: null,
  };
}

export const calculateTotalPrice = products => {
  return products.reduce(
    (total, product) => total + product.quantity * product.price,
    0
  );
};
