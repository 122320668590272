import Story1kk from '@img/static-stories/persimmon-1-kk.jpg';
import Story1ru from '@img/static-stories/persimmon-1-ru.jpg';
import Story2kk from '@img/static-stories/persimmon-2-kk.jpg';
import Story2ru from '@img/static-stories/persimmon-2-ru.jpg';
import Story3kk from '@img/static-stories/persimmon-3-kk.jpg';
import Story3ru from '@img/static-stories/persimmon-3-ru.jpg';
import Story4kk from '@img/static-stories/persimmon-4-kk.png';
import Story4ru from '@img/static-stories/persimmon-4-ru.png';
import imageDesktopKk from '@img/category-banners/persimmon-kk-d.jpg';
import imageMobileKk from '@img/category-banners/persimmon-kk-m.jpg';
import imageDesktopRu from '@img/category-banners/persimmon-ru-d.jpg';
import imageMobileRu from '@img/category-banners/persimmon-ru-m.jpg';

export const categoryPersimmonBanner = {
  type: 'categoryBanner',
  code: 'category_persimmon',
  imageDesktopKk,
  imageDesktopRu,
  imageMobileKk,
  imageMobileRu,
  videoMobileKk:
    'https://player.vimeo.com/progressive_redirect/playback/1026378421/rendition/540p/file.mp4?loc=external&signature=7ec52f2971ee121b07c28fbaca16e0ee01cb73ab910a47247cb0fc1c8f0f9f4f',
  videoMobileRu:
    'https://player.vimeo.com/progressive_redirect/playback/1026378441/rendition/540p/file.mp4?loc=external&signature=e3c430d82ac5d5ddd6e21120f1ad526b89bbefefe128b72af3d0660258c82da3',
  videoDesktopKk:
    'https://player.vimeo.com/progressive_redirect/playback/1022858103/rendition/540p/file.mp4?loc=external&signature=f7d30ccab49383a05abacd10106828b8e63685b5b3f7c65616a86b9e6181b164',
  videoDesktopRu:
    'https://player.vimeo.com/progressive_redirect/playback/1022858132/rendition/540p/file.mp4?loc=external&signature=aa4ded5855fbfb6024e77fad1f68250bca6f31509ac32546b833f15a16be3a19',
  stories: [
    // королёк
    {
      imageKk: Story1kk,
      imageRu: Story1ru,
      videoKk:
        'https://player.vimeo.com/progressive_redirect/playback/1021988495/rendition/540p/file.mp4?loc=external&signature=a91778117bc92308df3ad875b7f65855f7e5b659300ce5fa716c25ea62e4d24c',
      videoRu:
        'https://player.vimeo.com/progressive_redirect/playback/1021988508/rendition/540p/file.mp4?loc=external&signature=0e73d49291fb71448d90e23f8cf82870dfe583d932ae698c4e03fc7c61ed94c0',
    },
    // конфетка
    {
      imageKk: Story2kk,
      imageRu: Story2ru,
      videoKk:
        'https://player.vimeo.com/progressive_redirect/playback/1021988464/rendition/540p/file.mp4?loc=external&signature=ff7f15b3aa80dc7d9162be7834d50cc859d214e4b5c15e03cc0ed993b45d2c15',
      videoRu:
        'https://player.vimeo.com/progressive_redirect/playback/1021988482/rendition/540p/file.mp4?loc=external&signature=d7104dea25a6594219eb9634cba767304b59926a206ed636ee878ae86a842b10',
    },
    // свечка
    {
      imageKk: Story3kk,
      imageRu: Story3ru,
      videoKk:
        'https://player.vimeo.com/progressive_redirect/playback/1021988521/rendition/540p/file.mp4?loc=external&signature=a17dca51971c89abcb2bb4494d07e6d4d2cbcda28e1e9f848515faa2ca0a6da4',
      videoRu:
        'https://player.vimeo.com/progressive_redirect/playback/1021988539/rendition/540p/file.mp4?loc=external&signature=1f446d4a5e065bde7638fcfe70bd404ee8381de47ea3187c7a4eda20521c5966',
    },
    {
      imageKk: Story4kk,
      imageRu: Story4ru,
    },
  ],
};
