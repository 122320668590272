import { client } from '../../clients/client';

export default function ({ mock = false }) {
  if (mock) {
    return import('./mocks/expired-promocodes.json');
  }
  return client
    .get(`/lavka-promoaction/v1/profile/promocodes/list/expired`)
    .then(r => r.data);
}
