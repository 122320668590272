export const CITIES = {
  // local Key
  Almaty: {
    id: 1, // local ID
    chocoId: 1, // Choco ID
    name: 'Алматы', // local Name
    defaultWarehouseId: 1,
    defaultCoords: [43.237242, 76.915075],
  },
  Astana: {
    id: 2,
    chocoId: 2,
    name: 'Астана',
    defaultWarehouseId: 16,
    defaultCoords: [51.089227, 71.41629],
  },
};

export const DELIVERY_CITIES = [CITIES.Almaty.name, CITIES.Astana.name];

export const DEFAULT_CITY = CITIES.Almaty;
