import { client } from '../../clients/client';

/**
 * Получить список опросов (только с авторизацией)
 */
export function rFetchSurveys() {
  return client
    .get(`/ryadom-review/api/v1/review/categories`)
    .then(res => res.data);
}
