<template>
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1_53588)">
      <path
        d="M0 16C0 7.16344 7.16344 0 16 0H40C48.8366 0 56 7.16344 56 16V40C56 48.8366 48.8366 56 40 56H16C7.16344 56 0 48.8366 0 40V16Z"
        fill="#FFE4E0"
        fill-opacity="0.5"
      />
      <g clip-path="url(#clip1_1_53588)">
        <path
          d="M45.0619 33.2305C40.2988 23.6935 25.1224 30.0264 29.7135 40.0957C34.4766 49.6327 49.653 43.2998 45.0619 33.2305ZM39.8633 36.8808C39.6429 40.1064 34.5734 39.6548 34.9013 36.4507C35.1217 33.2305 40.1913 33.6767 39.8633 36.8808Z"
          fill="#FF6C5C"
        />
        <path
          d="M22.2254 26.8441C32.3538 21.9197 24.736 7.61957 14.871 12.0333C4.74263 16.9523 12.355 31.2632 22.2254 26.8441ZM16.2903 20.4951C14.871 17.592 19.4782 15.447 20.8007 18.3877C22.22 21.2961 17.6128 23.4358 16.2903 20.4951Z"
          fill="#FF6C5C"
        />
        <path
          d="M37.5677 12.942L14.3058 40.0155L18.3647 43.1712L41.6266 16.0977L37.5677 12.942Z"
          fill="#FF6C5C"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1_53588">
        <rect width="56" height="56" rx="16" fill="white" />
      </clipPath>
      <clipPath id="clip1_1_53588">
        <rect
          width="35.831"
          height="33.6"
          fill="white"
          transform="translate(10.08 11.2)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'IconPercent',
};
</script>

<style scoped></style>
