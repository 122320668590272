import { capitalize } from '@/utils/formatters/words';

export function hasMatchingAddress(addresses, target) {
  if (!target) return false;
  if (!addresses && addresses.length === 0) return false;
  const fields = ['entrance', 'flat_number', 'floor', 'street'];
  return addresses.find(address =>
    fields.every(field => target[field] === address[field])
  );
}

export const distanceBetweenCoordinates = (center, position) => {
  if (!center || !position) return false;

  const [lat1, lon1] = center;
  const [lat2, lon2] = position;
  const R = 6371e3; // meters
  const radians = Math.PI / 180;

  const f1 = lat1 * radians;
  const f2 = lat2 * radians;
  const df = (lat2 - lat1) * radians;
  const dl = (lon2 - lon1) * radians;

  const a =
    Math.sin(df / 2) * Math.sin(df / 2) +
    Math.cos(f1) * Math.cos(f2) * Math.sin(dl / 2) * Math.sin(dl / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const dist = Math.abs(R * c);

  return dist;
};

export const findNearestAddress = (addresses, userPosition) => {
  let nearestAddress = undefined;
  let minDistance = Number.MAX_VALUE;
  addresses.forEach(address => {
    const distance = distanceBetweenCoordinates(
      [address.lat, address.long],
      userPosition
    );
    if (distance < minDistance) {
      minDistance = distance;
      nearestAddress = address;
    }
  });
  return nearestAddress;
};

export function isAddressNear(address, userPosition) {
  const distance = distanceBetweenCoordinates(
    [address.lat, address.long],
    userPosition
  );
  return distance <= 200 && distance >= 0;
}

export function getHouseAddress(address, lang = 'ru', withName = true) {
  let text = '';
  let kvText = lang === 'kk' ? 'пәтер' : 'кв.';
  // let floorText = lang === 'kk' ? 'қабат' : 'этаж';
  if (address) {
    let { name, street, flat_number: kv } = address;
    text = '';

    text =
      withName &&
      name &&
      name !== street &&
      name !== 'Адрес' &&
      name !== 'Мекен-жай'
        ? `${capitalize(name)}, `
        : '';

    text = !text ? capitalize(street) : text + street;
    text = kv ? `${text}, ${kvText} ${kv}` : text;
    // text = floor ? `${text}, ${floorText} ${floor}` : text;
  }
  return text;
}
