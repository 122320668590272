import * as JsSearch from 'js-search';
import { rSearchAddress } from '@shared/services/api/apis';
import { stringify } from 'qs';
import { QUERY_SEARCH_SETTINGS, INITIAL_COORDS } from './constants';
import {
  filterGeoObjectsByDelivery,
  sortGeoObjectsByDelivery,
  validateGeoObjectsCollection,
} from './utils/validate-geo-objects-collection';
import { CITIES } from '@services/city/constants';

export * from './constants';

/**
 * Поиск совпадающих адресов из сохраненных
 * @param {String} query ввод в пойске
 * @param {Address[]} addresses сохраненые адреса пользователя
 * @returns
 */
function searchAddressFromSavedByQuery(query, addresses) {
  query = query.replace(/[.,/#!$%^&*;:{}=\-_`~()]/g, ' ');
  if (!query) return [];
  if (!addresses || !addresses.length > 0) return [];
  console.log('searchAddressFromSavedByQuery', query, addresses);

  let search = new JsSearch.Search('id');
  search.addIndex('street');
  search.addIndex('name');
  search.addIndex('street_name');
  search.addDocuments(addresses);
  let searched = search.search(query);
  console.log('searchAddressFromSavedByQuery searched', searched);
  return searched;
}

function parseGeoObject(GeoObject) {
  let streetName = '';
  let cityName = '';
  let building = '';

  if (GeoObject.metaDataProperty?.GeocoderMetaData?.Address?.Components) {
    GeoObject.metaDataProperty.GeocoderMetaData.Address.Components.forEach(
      item => {
        if (
          item.kind === 'street' ||
          item.kind === 'district' ||
          item.kind === 'locality'
        )
          streetName = item.name;
        if (item.kind === 'house') building = item.name;
        if (item.kind === 'locality') cityName = item.name;
      }
    );
  }

  return {
    street: GeoObject.name,
    lat: GeoObject.Point.pos.split(' ')[1],
    long: GeoObject.Point.pos.split(' ')[0],
    street_name: streetName, // нужен для yandex taxi
    cityName: cityName,
    building,
  };
}

/**
 * Выдает промис с результатами адресов от yandex api по названию адреса
 * @param {String} query принимает название адреса
 * @returns {Object} featureMember
 */
function findAddressByQuery(query) {
  let promises = [];
  Object.values(CITIES).forEach(city => {
    let promise = rSearchAddress(
      stringify({
        ...QUERY_SEARCH_SETTINGS,
        geocode: query,
        rspn: 1,
        ll: [...city.defaultCoords].reverse().join(','), // '76.910350,43.239554'
        spn: '0.20,0.20',
      })
    );
    promises.push(promise);
  });

  return Promise.all(promises)
    .then(res => {
      let concatedFeatureMembers = [];
      res.forEach(r => {
        concatedFeatureMembers.push(
          ...r.response.GeoObjectCollection.featureMember
        );
      });
      let result = res[0];
      result.response.GeoObjectCollection.featureMember =
        concatedFeatureMembers;
      return result;
    })
    .then(({ response }) => {
      console.log('findAddressByQuery', response);
      return validateGeoObjectsCollection(
        response.GeoObjectCollection,
        INITIAL_COORDS
      ).then(res => {
        console.log('findAddressByQuery', res);
        let filteredAddresses = filterGeoObjectsByDelivery(res.addresses);
        let isNoDeliveryToAddress =
          res.address &&
          res.address.hasBuilding &&
          res.address.hasStreet &&
          filteredAddresses.length === 0 &&
          res.addresses.length > 0;
        sortGeoObjectsByDelivery(filteredAddresses);
        return { ...res, isNoDeliveryToAddress, addresses: filteredAddresses };
      });
    });
}

export const MapService = {
  searchAddressFromSavedByQuery,
  findAddressByQuery,
  parseGeoObject,
};
