export const getPolygonsFromResponse = warehouses => {
  const polygons = {
    regular: [],
    extended: [],
  };

  warehouses.forEach(warehouse => {
    const deliveryZone = JSON.parse(warehouse.delivery_zone);

    const extended = deliveryZone.features?.[1]?.geometry;
    const extended2 = deliveryZone.features?.[2]?.geometry;
    // полигон дальней доставки
    if (extended) {
      extended.coordinates[0].map(v => v.reverse());
      if (extended2) {
        extended2.coordinates[0].map(v => v.reverse());
        extended.coordinates.push(extended2.coordinates[0]);
      }
      polygons.extended.push({
        type: 'extended',
        coordinates: extended.coordinates,
      });
    }

    // полигон обычной быстрой доставки
    const regular = deliveryZone.features?.[0]?.geometry;
    if (regular) {
      regular.coordinates[0].map(v => v.reverse());
      polygons.regular.push({
        type: extended ? 'regularNearExtended' : 'regular',
        coordinates: regular.coordinates,
      });
    }
  });

  return polygons;
};
