<template>
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1_53594)">
      <path
        d="M0 16C0 7.16344 7.16344 0 16 0H40C48.8366 0 56 7.16344 56 16V40C56 48.8366 48.8366 56 40 56H16C7.16344 56 0 48.8366 0 40V16Z"
        fill="#DFE9FF"
        fill-opacity="0.5"
      />
      <path
        d="M39.6979 24.3867V22.686C39.6979 16.3531 34.5499 11.2 28.2119 11.2C21.8739 11.2 16.731 16.3531 16.731 22.686V24.3867H11.2V29.4887H13.3245L14.4736 43.2423C14.5451 44.1259 15.2857 44.8 16.1692 44.8H40.2597C41.1432 44.8 41.8787 44.1208 41.9553 43.2423L43.0993 29.4887H45.2238V24.3867H39.6979ZM21.4092 22.5844V22.3438C21.593 18.7433 24.5705 15.8782 28.217 15.8782H28.5592C32.1597 16.062 35.0248 19.0395 35.0248 22.6809V24.3816H21.4143L21.4092 23.657V22.5844ZM23.1098 37.9973H19.7085V31.1945H23.1098V37.9973ZM29.9177 37.9973H26.5163V31.1945H29.9177V37.9973ZM36.7204 37.9973H33.3191V31.1945H36.7204V37.9973Z"
        fill="#85ACFF"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_53594">
        <rect width="56" height="56" rx="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'IconBasket',
};
</script>

<style scoped></style>
