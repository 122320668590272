import Story1kk from '@img/static-stories/fresh-1-kk.jpg';
import Story1ru from '@img/static-stories/fresh-1-ru.jpg';
import Story2kk from '@img/static-stories/fresh-2-kk.jpg';
import Story2ru from '@img/static-stories/fresh-2-ru.jpg';
import Story3kk from '@img/static-stories/fresh-3-kk.jpg';
import Story3ru from '@img/static-stories/fresh-3-ru.jpg';
import imageDesktopKk from '@img/category-banners/fresh-kk-d.jpg';
import imageMobileKk from '@img/category-banners/fresh-kk-m.jpg';
import imageDesktopRu from '@img/category-banners/fresh-ru-d.jpg';
import imageMobileRu from '@img/category-banners/fresh-ru-m.jpg';

export const categoryFreshBanner = {
  type: 'categoryBanner',
  code: 'fresh',
  imageDesktopKk,
  imageDesktopRu,
  imageMobileKk,
  imageMobileRu,
  stories: [
    {
      imageKk: Story1kk,
      imageRu: Story1ru,
    },
    {
      imageKk: Story2kk,
      imageRu: Story2ru,
    },
    {
      imageKk: Story3kk,
      imageRu: Story3ru,
    },
  ],
};
