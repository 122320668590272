<template>
  <div v-if="show" class="SaleCategory">
    <HorizontalProductList
      :navigation="navigation"
      :products="saleCategory.items"
      :added-from="PRODUCT_ADDED.MAIN"
    >
      <template #title>
        {{ $t('discounts') }}
      </template>
    </HorizontalProductList>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { PRODUCT_ADDED } from '@shared/config/product';
import HorizontalProductList from '@shared/components/horizontal-products/HorizontalProductList.vue';

export default {
  name: 'SaleCategory',
  components: {
    HorizontalProductList,
  },
  data() {
    return {
      PRODUCT_ADDED,
    };
  },
  computed: {
    ...mapGetters('catalog', ['saleCategory']),
    ...mapGetters('delivery', ['warehouseId']),
    navigation() {
      return {
        name: 'category',
        params: {
          id: this.saleCategory.id.toString(),
        },
        // query: {
        // tag: [this.saleCategory.childId],
        // },
      };
    },
    show() {
      return this.saleCategory?.items?.length >= 5;
    },
  },
  i18n: {
    messages: {
      ru: {
        discounts: 'Скидки',
      },
      kk: {
        discounts: 'Жеңілдіктер',
      },
    },
  },
};
</script>
