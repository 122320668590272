import ChocoPayWidget from 'choco-pay-widget';
import { LocaleService } from '@shared/services/locale';
import { rGetTrackIds } from '@shared/services/api/apis';
import { ClientService } from './services/client';

const paymentEnv =
  import.meta.env.VITE_DEPLOYMENT === 'PRODUCTION' ? 'production' : 'stage';

export const WebApp = {
  async pay(options) {
    const { href } = window.location;
    const { origin } = new URL(href);

    const successUrl = options.successUrl
      ? `${origin}${options.successUrl}`
      : origin;

    const failureUrl = options.failureUrl
      ? `${origin}${options.failureUrl}`
      : href;

    ClientService.getFingerprint();

    rGetTrackIds().then(({ data }) => {
      const ChocoPayWidgetItem = new ChocoPayWidget({
        track_id: data.id,
        paymentUrl: options.backlink,
        environment: paymentEnv,
        sucessUrl: successUrl,
        failureUrl,
        lang: LocaleService.getLocale(),
      });
      ChocoPayWidgetItem.initPayment();
      return false;
    });
  },
  requestGeoPermissionStatus() {
    return new Promise(resolve => {
      if (!navigator.permissions) resolve('denied');

      navigator.permissions.query({ name: 'geolocation' }).then(res => {
        if (res.state === 'granted') return resolve('granted');
        if (res.state === 'prompt') return resolve('notDetermined');
        return resolve('denied');
      });
    });
  },
  applicationBecomeActive(callback) {
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        callback();
      }
    });
  },
};
