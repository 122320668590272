<template>
  <MainLayout>
    <template v-if="$isWebView" #header>
      <ToolBar :back="onBackClick">
        <p class="break-all truncate">{{ title }}</p>
      </ToolBar>
    </template>

    <ProfileLayout :container-width="568" :bread-links="links">
      <div v-if="loading" class="flex justify-center w-full">
        <UiRippleLoader class="mx-auto" />
      </div>

      <div v-else-if="order" class="h-full">
        <div
          v-if="!isFinished"
          :class="[
            $isDesktop
              ? 'p-10 pt-8 custom-box-shadow bg-white rounded-4xl '
              : '',
          ]"
        >
          <OrderStatus
            class="px-4 md:px-0"
            :order="order"
            @openCancelOrder="openCancelOrder"
          />
        </div>

        <div
          v-else
          :class="[
            $isDesktop
              ? 'p-10 pt-8 custom-box-shadow bg-white rounded-4xl'
              : '',
          ]"
        >
          <OrderInfo
            ref="orderInfo"
            class="px-4 md:px-0"
            :is-finished="isFinished"
            :order="order"
            :is-problem-btn-shown="hasProblemButton"
            @goToProblemsClicked="goToPoblems"
            @onUpdateOrder="onUpdateOrder"
          />
        </div>

        <ProductsReviewWrapper
          v-if="hasReviewProducts"
          class="mt-8 px-4"
          screen="order"
        />

        <div class="mt-12 md:mt-16 px-4 md:px-10">
          <OrderDetails class="max-width-488" :order="order" />
        </div>

        <div class="mt-12 md:mt-20 px-4 md:px-10">
          <div class="max-width-488">
            <h2 class="font-bold text-lg md:text-2xl">
              <!-- eslint-disable vue/no-v-html -->
              <span
                v-html="
                  $t('productsInOrderWithTotal', {
                    amount: formatPrice(order.price),
                  })
                "
              />
              <!-- eslint-enable vue/no-v-html -->
            </h2>

            <div class="mt-2 md:mt-5">
              <OrderProduct
                v-for="product in items"
                :key="constructItemKey(product)"
                class="md:mb-0.5"
                :product="product"
                :is-order-finished="isFinished"
                :is-order-with-loyalty="!!getOrderLoyaltyCount(order)"
              />
            </div>
          </div>
        </div>
      </div>

      <CancelOrderWrapper v-if="order" :id="id" ref="cancelOrderRef" />

      <SuccessPaymentModal
        v-model="showSuccessPaymentModal"
        :time="expectedDeliveryTime"
        @onClose="onSuccessPaymentClose"
      />
    </ProfileLayout>
  </MainLayout>
</template>

<script>
import {
  Analitycs,
  EVENTS,
  APPSFLYER_EVENTS,
} from '@shared/services/analitycs';
import { mapGetters, mapActions } from 'vuex';
import { computeDateDiff, dateAndTime } from '@/utils/formatters/date';
import { ORDER_EVENTS, orderEmitter } from '@services/orders/events';
import { replaceUrlQuery } from '@shared/utils/replace-url-query';
import { YandexMetrikaService } from '@services/yandex-metrika';
import { formatPrice } from '@shared/utils/formatters/price';
import { capitalize } from '@shared/utils/formatters/words';
import { ORDER_STATUSES } from '@services/orders/constants';
import { rFetchOrder } from '@shared/services/api/apis';
import { DevConfigs } from '@/utils/dev-config';
import { UserService } from '@services/user';
import { getOrderLoyaltyCount } from '@/lib/loyalty/get-order-loyalty-count';

import ProductsReviewWrapper from '@components/product-review/ProductsReviewWrapper.vue';
import CancelOrderWrapper from '@components/orders/CancelOrderWrapper.vue';
import ProfileLayout from '@shared/components/layout/ProfileLayout.vue';
import ToolBar from '@shared/components/layout/ToolBar.vue';
import UiRippleLoader from '@ui/UiRippleLoader.vue';
import MainLayout from '@layout/MainLayout.vue';

import OrderProduct from './components/OrderProduct.vue';
import OrderDetails from './components/OrderDetails.vue';
import OrderStatus from './components/OrderStatus.vue';
import OrderInfo from './components/OrderInfo.vue';
import {getDeliveryTimePrediction} from "@/lib/get-delivery-time-prediction";

export default {
  name: 'OrderView',
  components: {
    SuccessPaymentModal: () =>
      import('@components/orders/success-payment/SuccessPaymentModal.vue'),
    ProductsReviewWrapper,
    CancelOrderWrapper,
    UiRippleLoader,
    ProfileLayout,
    OrderProduct,
    OrderDetails,
    OrderStatus,
    MainLayout,
    OrderInfo,
    ToolBar,
  },
  inject: ['toast', 'bottomSheetPopup'],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      order: undefined,
      items: [],
      loading: true,

      showSuccessPaymentModal: false,
    };
  },
  computed: {
    ...mapGetters(['isAuthorized']),
    ...mapGetters('productReview', ['hasReviewProducts']),
    ...mapGetters('delivery', ['expectedDeliveryTime']),
    title() {
      return this.order && this.order.paid_at && this.isFinished
        ? capitalize(dateAndTime(this.order.paid_at, false, this.$i18n.locale))
        : this.$t('orderDetails');
    },
    isFinished() {
      return (
        this.order &&
        (this.order.status === ORDER_STATUSES.DELIVERED ||
          this.order.status === ORDER_STATUSES.CANCELED ||
          this.order.status === ORDER_STATUSES.FINISHED)
      );
    },
    links() {
      const links = [
        { name: 'Главная', path: '/' },
        {
          name: 'Все заказы',
          path: '/orders',
        },
      ];
      if (this.order) {
        links.push({
          name: dateAndTime(this.order.paid_at),
          path: `/order/${this.id}`,
        });
      }
      return links;
    },
    hasProblemButton() {
      const date = this.order.paid_at || this.order.created_at;
      const diff = computeDateDiff(date);
      return (
        diff <= 3 &&
        (this.order.status === ORDER_STATUSES.DELIVERED ||
          this.order.status === ORDER_STATUSES.FINISHED)
      );
    },
  },
  beforeDestroy() {
    orderEmitter.off(ORDER_EVENTS.ON_UPDATE_ORDER, this.onUpdateOrder);
  },
  async mounted() {
    if (this.isAuthorized) {
      await this.fetchOrder();
      orderEmitter.on(ORDER_EVENTS.ON_UPDATE_ORDER, this.onUpdateOrder);
    }

    this.controllDeeplink();
  },
  methods: {
    formatPrice,
    ...mapActions('cart', {
      clearLocalCart: 'CLEAR_LOCAL_CART',
    }),
    openCancelOrder() {
      this.$refs.cancelOrderRef.openModal();
    },
    fetchOrder() {
      return rFetchOrder(this.id)
        .then(data => {
          if (DevConfigs.isDev) {
            data.is_distant = true;
            data.high_delivery_price = true;
            data.delivery_price = 209;
            if (this.id === '436995') {
              data.promocode = {
                code: 'TST-FZHAKGN',
                amount: 500,
                free_delivery: false,
              };
            }
          }

          this.order = data;
          this.items = data.items;
          Analitycs.logEvent(EVENTS.VIEWED_ORDER, {
            order: this.order,
          });
        })
        .catch(err => this.toast.show(err.message))
        .finally(() => (this.loading = false));
    },
    onUpdateOrder(order) {
      if (Number(this.order.id) === order.id) {
        this.order = { ...this.order, ...order };
      }
    },
    onBackClick() {
      this.$router.push({ name: 'orders' });
    },
    constructItemKey(item) {
      return item.feature_id
        ? `${item.product_id}_${item.feature_id}`
        : item.product_id;
    },
    openSuccessModal() {
      this.showSuccessPaymentModal = true;
    },
    closeSuccessModal() {
      this.showSuccessPaymentModal = false;
    },
    onSuccessPayment() {
      this.openSuccessModal();

      const { preorderId } = this.$route.query;
      Analitycs.logEvent(EVENTS.DONE_PAYMENT, {
        preorderId,
        orderId: this.id,
        cartWeight: this.cartWeight,
        promocode: window.localStorage.getItem('analytics-promocode'),
        estimated_delivery_time: getDeliveryTimePrediction()
      });
      window.localStorage.removeItem('analytics-promocode');
      Analitycs.logAppsflyerEvent(APPSFLYER_EVENTS.DOM_DONE_PAYMENT, {
        orderId: this.id,
        dom_order_id: this.id,
        dom_preorder_id: preorderId,
      });
      this.$gtm.trackEvent({
        event: EVENTS.DONE_PAYMENT,
      });

      YandexMetrikaService.reachGoal('done_payment');

      if (this.$route.query.firstPayment === 'true') {
        YandexMetrikaService.reachGoal('first_done_payment');
        this.$gtm.trackEvent({
          event: 'first_done_payment',
        });
        Analitycs.logAppsflyerEvent(APPSFLYER_EVENTS.DOM_FIRST_DONE_PAYMENT, {
          dom_order_id: this.id,
          dom_preorder_id: this.preorderId,
        });
        replaceUrlQuery(/firstPayment=true&/i, /firstPayment=true/i);
      }

      this.$store.dispatch('cart/SET_UNPAID_ORDER', false);
    },
    onSuccessPaymentClose() {
      this.closeSuccessModal();

      this.$store.dispatch('cart/SET_UNPAID_ORDER', false);
      this.clearLocalCart();

      UserService.loadUserProfile();
    },
    goToPoblems() {
      Analitycs.logEvent(EVENTS.PROBLEM_ORDER);
      this.$router.push({
        name: 'problems',
        params: { id: this.id.toString() },
      });
    },
    controllDeeplink() {
      const { problems, successMessage, tipsSuccessMessage } =
        this.$route.query;

      if (problems === 'problems' && this.hasProblemButton) this.goToPoblems();

      if (successMessage) {
        this.onSuccessPayment();
        replaceUrlQuery(/successMessage=true&/i, /successMessage=true/i);
      }
      /**
       * Нужен для того что б отправить ивент в аналитику после оплаты чаевых
       *
       * replaceUrlQuery нужен для того что б убрать вспомогательные query params
       * и при релоуде экрана мы снова не слали ивент о успешной оплате чаевых
       */
      if (tipsSuccessMessage) {
        Analitycs.logEvent(EVENTS.TIPS_PAYMENT_DONE, {
          orderId: String(this.id),
        });
        this.toast.show(this.$t('thanksForTips'), 'success');
        replaceUrlQuery(
          /tipsSuccessMessage=true&/i,
          /tipsSuccessMessage=true/i
        );
      }
    },
    getOrderLoyaltyCount,
  },
  eventBusEvents: {
    eb_on_reconnect_to_app() {
      this.fetchOrder();
    },
  },
  i18n: {
    messages: {
      ru: {
        order: 'Заказ',
        productsInOrderWithTotal: 'Товары в заказе на сумму {amount}&nbsp;₸',
        total: 'Итого',
        orderDetails: 'Детали заказа',
        thanksForTips: 'Спасибо за чаевые',
      },
      kk: {
        order: 'Тапсырыс',
        productsInOrderWithTotal:
          'Тапсырыстағы заттардың сомасы {amount}&nbsp;₸',
        total: 'Барлығы',
        orderDetails: 'Тапсырыс мәліметтері',
        thanksForTips: 'Шайпұл үшін рахмет',
      },
    },
  },
};
</script>

<style scoped>
.custom-box-shadow {
  box-shadow:
    0px 92px 92px rgba(0, 0, 0, 0.05),
    0px 3px 38.4354px rgba(0, 0, 0, 0.0334858),
    0px -3px 20.5494px rgba(0, 0, 0, 0.06),
    0px 5.79369px 11.5198px rgba(0, 0, 0, 0.0189792),
    0px 2.40368px 6.11809px rgba(0, 0, 0, 0.0132742),
    0px 0.705169px 2.54588px rgba(0, 0, 0, 0.00743532);
}

.max-width-488 {
  @emdia (min-width:768px) {
    max-width: 488px;
  }
}
</style>
