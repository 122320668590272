<template>
  <div
    class="font-bold text-lg text-sirius-black-100 flex items-center justify-between"
    :class="[
      main ? 'md:text-2xl md:leading-10' : 'md:text-2xl md:leading-7',
      navigation ? 'cursor-pointer' : '',
    ]"
    @click="navigate"
  >
    <slot></slot>
    <!-- на время теста новой главной также меняем стрелки -->
    <div
      v-if="navigation && !$isDesktop"
      class="bg-sirius-gray-200 rounded-xl flex items-center z-0 font-medium text-sm pl-3 py-0.5"
    >
      <span style="margin-bottom: 2px">
        {{ $i18n.locale === 'ru' ? 'все' : 'барлығы' }}
      </span>
      <!-- prettier-ignore -->
      <svg class="-ml-1" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 16L14 12L10 8" stroke="#242424" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>
    <template v-else>
      <IconRightArrow
        v-if="navigation"
        class="md:hidden w-9 h-9 cursor-pointer"
      />
      <IconRightArrow
        v-if="navigation"
        class="hidden md:block w-11 h-11 cursor-pointer"
      />
    </template>
  </div>
</template>

<script>
import IconRightArrow from '@shared/components/icons/IconRightArrow.vue';
import { Analitycs, EVENTS } from '@shared/services/analitycs';

export default {
  name: 'UiNavigationTitle',
  components: {
    IconRightArrow,
  },
  props: {
    route: {
      type: Object,
      default: () => {
        return { name: 'main' };
      },
    },
    navigation: {
      type: Object,
      default: null,
    },
    main: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    navigate() {
      if (!this.navigation) return;
      this.sendAnalytics();
      this.$router.push(this.navigation);
    },
    sendAnalytics() {
      if (!this.navigation || !this.navigation.params) return;

      if (this.navigation.params.id === '798') {
        Analitycs.logEvent(EVENTS.CATEGORY_SELECTED, {
          categoryId: 798,
          categoryName: 'Акции',
          from: 'main_all_button',
        });
      }
    },
  },
};
</script>
