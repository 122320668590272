export const ABTestModule = {
  namespaced: true,
  state: {
    tests: null,
  },
  getters: {
    tests(state) {
      return state.tests;
    },
    testGroups(state) {
      const { tests } = state;
      if (!tests) return '';
      const groups = tests.map(e => `${e.test_code}:${e.test_group_code}`);
      return groups.join(',');
    },
    testsObj(state) {
      const { tests } = state;
      if (!tests) return {};
      return tests.reduce((acc, v) => {
        acc[v.test_code] = v.test_group_code;
        return acc;
      }, {});
    },
    getGroupByCode: (state, getters) => code => {
      const tests = getters.testsObj;
      return tests[code];
    },
  },
  mutations: {
    SET_TESTS(state, payload) {
      state.tests = payload;
    },
    ADD_TEST(state, payload) {
      const tests = state.tests ? [...state.tests, payload] : [payload];
      state.tests = tests;
    },
    CHANGE_GROUP_BY_CODE(state, payload) {
      const tests = [...state.tests];
      const index = tests.findIndex(e => e.test_code === payload.code);
      if (index > -1) {
        tests[index] = {
          test_code: payload.code,
          test_group_code: payload.group,
        };
        state.tests = tests;
      }
    },
  },
};
