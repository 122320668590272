import { client } from '../../clients/client';
import { isAuthorizedUrl } from '../../utils/is-authorized-url';

export default function (coords, isAuthorized = false) {
  return client
    .get(
      `/lavka/v1${isAuthorizedUrl(isAuthorized)}/warehouses/get_by_coordinate`,
      {
        params: {
          lat: coords.lat,
          long: coords.long,
        },
      }
    )
    .then(res => res.data);
}
