export function getWorkingTimeFromWarehouse(warehouse) {
  let res = {
    openTime: '08:00',
    closeTime: '23:00',
  };

  if (!warehouse) return res;
  let spliceTime = time => time.split(':').slice(0, -1).join(':');

  res.openTime = spliceTime(warehouse.opens_at);
  res.closeTime = spliceTime(warehouse.closes_at);

  return res;
}
