import { ClientService } from '@shared/services/client';
import { Logger as LOG } from '@shared/services/logger';
import { AuthService } from '@shared/services/auth';
import { isMainPages } from '@services/main/lib/is-main-pages';

const logRoutes = (to, from) => {
  const routes = {
    from: { ...from },
    to: { ...to },
  };
  delete routes.from.params.route;
  delete routes.to.params.route;
  routes.from.matched = '';
  routes.to.matched = '';

  LOG.event(
    `${from.name} --> ${to.name} -- ${from.fullPath} --> ${to.fullPath}`,
    { routes },
    'Router'
  );
};

function isAppIniting(route) {
  return !route.name;
}
function activateLoader(store) {
  store.commit('SET_APP_LOADER', true);
}
function deactivateLoader(store) {
  store.commit('SET_APP_LOADER', false);
}

export function installAuthGuard(router, store, callbacks) {
  router.beforeEach((to, from, next) => {
    logRoutes(to, from);

    const { path, name } = to;
    const { code, track_id: trackId } = to.query;
    const { deviceId } = ClientService.getContext();
    const isAuthorized = store.state.isAuthorized;
    const needDeviceId = to.meta.deviceId && !deviceId;
    const needAuth = !!to.meta.protected;

    if (!isAuthorized && (needAuth || needDeviceId)) {
      if (isAppIniting(from)) {
        activateLoader(store);
      }
      LOG.event('Auth-guard authorize ', { path, code, trackId });
      return AuthService.authorize({ path, code, trackId })
        .then(() => {
          LOG.event('Auth-guard auth success');
          // ToDo: перенести в AuthService
          return store.dispatch('INIT_MAIN_FLOW', {
            forceLoadNearestAddress: isAppIniting(from),
            isMain: isMainPages(name),
          });
        })
        .then(() => {
          LOG.event('Auth-guard INIT_MAIN_FLOW success');
          return next();
        })
        .catch(err => {
          LOG.event('Auth-guard auth reject', err);

          if (callbacks && typeof callbacks.onReject === 'function' && err) {
            callbacks.onReject(err);
          }

          let nextRoute = false;
          if (from.name === null) {
            nextRoute = { name: 'main', query: to.query };
          }
          return next(nextRoute);
        })
        .finally(() => {
          deactivateLoader(store);
        });
    }
    return next();
  });
}
