<template>
  <div class="NoShoppingHistory pt-4">
    <img
      src="../shopping-history/assets/package.png"
      alt=""
      class="block m-auto"
      style="max-width: 343px; padding-bottom: 11px"
    />
    <p class="font-bold w-3/4 text-lg text-center" style="margin: auto">
      <span class="block m-auto text-2xl" v-html="$t('yourPurchases')" />
    </p>
    <UiButton
      size="M"
      class="mt-5 m-auto"
      style="width: 45%; display: block"
      @click="$router.push({ name: 'main' })"
      >{{ $t('backToMain') }}</UiButton
    >
  </div>
</template>

<script>
import UiButton from '@pure-ui/components/UiButton/UiButton.vue';

export default {
  name: 'NoShoppingHistory',
  components: { UiButton },
  i18n: {
    messages: {
      ru: {
        backToMain: 'На главную',
        yourPurchases: 'Здесь будут ваши покупки',
      },
      kk: {
        backToMain: 'Басты бетке',
        yourPurchases: 'Мұнда алған заттарыңыз тұрады',
      },
    },
  },
};
</script>

<style scoped></style>
