import { store } from '@/store';
import { $log } from '@/utils/plugins/logger';
import { Storage } from '@shared/services/storage';
import { USER_EVENTS, UserEmitter } from './emitter';
import { rFetchUser, rFetchChocoUser } from '@shared/services/api/apis';

function setUser(value) {
  store.commit('user/SET_USER', value);
}
function updateUser(value) {
  store.commit('user/UPDATE_USER', value);
}
function resetUser() {
  store.commit('user/SET_USER', null);
}
function setOrdersCount(value) {
  store.commit('user/SET_ORDERS_COUNT', value);
}
function getUser() {
  return store.getters['user/userProfile'];
}

/**
 * Сохраняет количество заказов в localStorage
 * @param {Number} count
 */
function setOrdersCountToLocal(count) {
  const storage = new Storage(window.localStorage);
  storage.set('user::ordersCount', count);
}

/**
 * Берет количество заказов с localStorage
 * @returns
 */
function getOrdersCountFromLocal() {
  const storage = new Storage(window.localStorage);
  const ordersCount = storage.get('user::ordersCount', 0);
  return +ordersCount;
}

/**
 * Сохраняет количество заказов в Store и LocalStorage
 * @param {Number?} value
 */
function saveOrdersCount(value) {
  setOrdersCount(value);
  setOrdersCountToLocal(value);
}

function loadOrdersCount() {
  const ordersCount = getOrdersCountFromLocal();
  saveOrdersCount(ordersCount);
}

/**
 * Если авторизация не сработала  то удает данные юзера со Store,
 * и берет количество заказов с localstorage (нужен для показа суржа, если юзер старый полльзователь и он просто вышел с аккаунта и зашел обратно)
 * @param {Error} err если есть ошибка выводит
 * @returns
 */
function loadUnauthorizedProfile(err) {
  $log('UserService loadUnauthorized', err);
  resetUser();
  loadOrdersCount();
  return Promise.resolve();
}

/**
 * Получение данных юзера Рядом
 * @returns {Promise}
 */
function loadRyadomProfile() {
  const warehouseId = store.getters['delivery/warehouseId'];
  return rFetchUser(warehouseId).then(({ data }) => {
    $log('UserService loadRyadomProfile', data, 'loadRyadomProfile');
    setUser(data);
    saveOrdersCount(data.orders_count);
  });
}

/**
 * Получение данных юзера Чоко
 * @returns {Promise}
 */
function loadChocoProfile() {
  return rFetchChocoUser().then(({ data }) => {
    updateUser(data.attributes);
  });
}

/**
 * Получаем и сохраняем данные юзера в Store
 * @returns {Promise}
 */
function loadUserProfile() {
  const { isAuthorized } = store.getters;
  $log('UserService loadUserProfile', { isAuthorized });
  if (!isAuthorized) return loadUnauthorizedProfile();

  return loadRyadomProfile()
    .then(() => {
      loadChocoProfile();
      UserEmitter.emit(USER_EVENTS.ON_USER_LOADED, getUser());

      //TODO: убрать отсюда и подписаться на емит
      window.dataLayer?.push({
        event: 'userLogin',
        userId: getUser().user_id,
      });
    })
    .catch(err => loadUnauthorizedProfile(err));
}

export const UserService = {
  loadUserProfile,
};
