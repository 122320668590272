import { store } from '@/store';
import { publicWs } from '@shared/services/ws';
import { PUBLIC_MESSAGE_TYPES } from '@shared/services/ws/config';
import { eventBus } from '@/utils/plugins/event-bus';
import { onUpdateClosureReason } from './lib/update-closure-reasons';

function onChangeWarehouse(data) {
  const { newVal, oldVal } = data;
  const oldId = oldVal?.id || undefined;
  const newId = newVal?.id || undefined;
  const hasDiff = newId !== oldId;
  if (hasDiff) {
    eventBus.emit('eb_on_warehouse_id_change', {
      newId,
      oldId,
    });
  }
}

function init() {
  eventBus.on('eb_on_warehouse_change', onChangeWarehouse);
  store.dispatch('delivery/FETCH_EXPECTED_DELIVERY_TIME');
  publicWs.on(PUBLIC_MESSAGE_TYPES.CLOSURE_REASON, onUpdateClosureReason);
}

export const DeliveryService = {
  init,
};
