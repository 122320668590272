<template>
  <MapView
    ref="MapViewRef"
    class="MapContainer"
    :polygons="polygons"
    :geo-btn="geoBtn"
    :zoom-btns="zoomBtns"
    :input-coords="inputCoords"
    :init-coords="initCoords"
    @onMapInit="onMapInit"
    @onMapActionEnd="$emit('onMapActionEnd', $event)"
    @onMapActionStart="$emit('onMapActionStart', $event)"
    @onMapCenterUpdated="onMapCenterUpdated"
    @onGeoLocationError="onGeoLocationError"
  />
</template>

<script>
import MapView from '@pure-ui/components/Map/MapView/MapView.vue';
import { CityService } from '@services/city';
import { fetchWarehouseZoneCoordinates } from '@services/map/utils/fetch-warehouse-zone-coordinates';

export default {
  components: {
    MapView,
  },
  inject: ['toast'],
  props: {
    inputCoords: {
      type: Array,
      default: () => [],
    },
    geoBtn: Boolean,
    zoomBtns: Boolean,
  },
  data() {
    return {
      initCoords: CityService.getCityData().defaultCoords,
      map: null,
      polygons: {
        regular: [],
        extended: [],
      },
    };
  },
  created() {
    this.fetchDeliveryZones();
  },
  methods: {
    fetchDeliveryZones() {
      fetchWarehouseZoneCoordinates().then(polygons => {
        this.polygons = {
          regular: polygons.regular,
          extended: polygons.extended,
        };
      });
    },
    // outside access
    defineDefaults() {
      return this.map.defineByCoords(CityService.getCityData().defaultCoords);
    },
    // outside access
    defineByCoords(coords) {
      if (!this.map) return Promise.reject('Map not inited');
      return this.map.defineByCoords(coords);
    },
    // outside access
    async defineGeolocation() {
      let coords = CityService.getCityData().defaultCoords;
      return this.map.findGeoLocation(3000, coords);
    },
    onGeoLocationError() {
      this.toast.show(this.$t('unableToGetGeoposition'), 'warning');
    },
    onMapCenterUpdated(data) {
      this.$emit('onMapCenterUpdated', data);
    },
    onMapInit(data) {
      this.map = data.map;
      this.$emit('onMapInit', data.options);
    },
  },
  i18n: {
    messages: {
      ru: {
        errorHappened: 'Произошла ошибка',
        unableToGetGeoposition:
          'Не удалось определить геопозицию, разрешите сбор геоданных в&nbsp;настройках браузера',
      },
      kk: {
        errorHappened: 'Қате орын алды',
        unableToGetGeoposition:
          'Тұрған жеріңіз табылмады, браузер параметріндегі геодеректерді жинауға рұхсат етіңіз',
      },
    },
  },
};
</script>

<style></style>
