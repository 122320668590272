<template>
  <ShoppingHistoryPage
    :is-desktop="$isDesktop"
    :is-web-view="$isWebView"
    :loading="loading"
    :products="products"
    :added-from="PRODUCT_ADDED.HISTORY"
    :filter-popup="filterPopup"
    :pagination="pagination"
    @onFilterPopupSetShow="filterPopup.show = $event"
    @onFilterPopupSortChange="filterPopup.sortValue = $event"
    @onFilterPopupSubmit="onFilterPopupSubmit"
    @onNextPage="onNextPage"
  />
</template>

<script>
import { PRODUCT_ADDED } from '@shared/config/product';
import ShoppingHistoryPage from './ShoppingHistoryPage.vue';
import { fetchFromApi, logMounted, logListView } from './utils';
import {getFilteredProducts} from "@/lib/coffee/get-filtered-products";

export default {
  name: 'ShoppingHistoryView',
  components: {
    ShoppingHistoryPage,
  },
  data: () => ({
    loading: false,
    products: [],
    PRODUCT_ADDED,
    filterPopup: {
      show: false,
      sortValue: 'last_shopped_at:desc',
    },
    pagination: {
      loading: false,
      currentPage: 0,
      totalPages: 1,
      fetchedPages: [],
    },
  }),
  mounted() {
    const from = this.$route.query.from || 'cart';
    logMounted(from);
    this.fetchHistoryInitial();
  },
  methods: {
    fetchHistoryInitial() {
      this.reset();
      this.loading = true;
      this.fetch().finally(() => {
        this.loading = false;
      });
    },
    fetch(page = 1) {
      if (this.alreadyLoaded(page)) {
        return Promise.resolve(null);
      }
      return fetchFromApi({
        limit: 100,
        sorting: this.filterPopup.sortValue,
        page,
      }).then(data => {
        const { items, pagination, logId } = data;
        this.products = getFilteredProducts(items);
        this.pagination.totalPages = pagination.total_pages;
        this.pagination.currentPage = pagination.current_page;
        logListView(logId, page);
      });
    },
    onFilterPopupSubmit() {
      this.filterPopup.show = false;
      this.fetchHistoryInitial();
    },
    onNextPage() {
      const { currentPage, totalPages } = this.pagination;
      if (currentPage + 1 > totalPages) {
        return;
      }
      this.pagination.loading = true;
      this.fetch(currentPage + 1).finally(() => {
        this.pagination.loading = false;
      });
    },
    reset() {
      this.products = [];
      this.pagination = {
        loading: false,
        currentPage: 0,
        totalPages: 1,
        fetchedPages: [],
      };
    },
    alreadyLoaded(page) {
      const { fetchedPages } = this.pagination;
      if (fetchedPages.includes(page)) {
        return true;
      }
      fetchedPages.push(page);
      return false;
    },
  },
  eventBusEvents: {
    eb_on_address_change() {
      this.fetchHistoryInitial();
    },
  },
};
</script>
