<template>
  <div
    class="see-more-wrapper text-center rounded-2xl bg-sirius-gray-200 flex flex-col justify-center items-center w-full h-full"
    @click="navigate"
  >
    <IconArrorSeeMore />
    <div class="text-sm md:text-lg md:px-6 font-bold mt-3">
      {{ $t('toSeeMore') }}
    </div>
  </div>
</template>

<script>
import IconArrorSeeMore from '@shared/components/icons/IconArrorSeeMore.vue';
import { Analitycs, EVENTS } from '@shared/services/analitycs';

export default {
  name: 'SeeMore',
  components: { IconArrorSeeMore },
  props: {
    navigation: {
      type: Object,
      default: () => ({ name: 'main' }),
    },
  },
  methods: {
    navigate() {
      if (!this.navigation) return;
      this.sendAnalytics();
      this.$router.push(this.navigation);
    },
    sendAnalytics() {
      if (!this.navigation || !this.navigation.params) return;

      if (this.navigation.params.id === '798') {
        Analitycs.logEvent(EVENTS.CATEGORY_SELECTED, {
          categoryId: 798,
          categoryName: 'Акции',
          from: 'main_view_all_button',
        });
      }
    },
  },
  i18n: {
    messages: {
      ru: {
        toSeeMore: 'Посмотреть ещё',
      },
      kk: {
        toSeeMore: 'Тағы қарау',
      },
    },
  },
};
</script>

<style scoped>
.see-more-wrapper {
  cursor: pointer;
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 10; /* Убедитесь, что z-index достаточно высокий, чтобы перекрыть другие элементы */
}
</style>
