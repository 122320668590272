import Story1kk from '@img/static-stories/lamber-1-kk.jpg';
import Story1ru from '@img/static-stories/lamber-1-ru.jpg';
import imageDesktopKk from '@img/category-banners/lamber-d-kk.jpg';
import imageMobileKk from '@img/category-banners/lamber-m-kk.jpg';
import imageDesktopRu from '@img/category-banners/lamber-d-ru.jpg';
import imageMobileRu from '@img/category-banners/lamber-m-ru.jpg';

export const categoryLamberBanner = {
  type: 'categoryBanner',
  code: 'category_lamber',
  imageDesktopKk,
  imageDesktopRu,
  imageMobileKk,
  imageMobileRu,
  videoMobileKk:
    'https://player.vimeo.com/progressive_redirect/playback/1007938757/rendition/360p/file.mp4?loc=external&signature=3cfab8682f55346d9ca9aef3ef0b1e503ee0b9868d1cfe6d2c070d9b04c25dbd',
  videoMobileRu:
    'https://player.vimeo.com/progressive_redirect/playback/1007938779/rendition/360p/file.mp4?loc=external&signature=d852c126114de173235187843fe775ed9feac9531973fe5126bc216bb8dae225',
  videoDesktopKk:
    'https://player.vimeo.com/progressive_redirect/playback/1008339540/rendition/540p/file.mp4?loc=external&signature=0c995337b4c0f17ea0a9447c3165212e2f92c1b20d284e8feec34888202d52af',
  videoDesktopRu:
    'https://player.vimeo.com/progressive_redirect/playback/1008339540/rendition/540p/file.mp4?loc=external&signature=0c995337b4c0f17ea0a9447c3165212e2f92c1b20d284e8feec34888202d52af',
  stories: [
    {
      imageKk: Story1kk,
      imageRu: Story1ru,
      videoKk:
        'https://player.vimeo.com/progressive_redirect/playback/1011216204/rendition/720p/file.mp4?loc=external&signature=4f21012a72c2be9cf7d5241e5a6cb3aa3cc0a9b8159a8ee928c55f021388a4c4',
      videoRu:
        'https://player.vimeo.com/progressive_redirect/playback/1011216241/rendition/720p/file.mp4?loc=external&signature=4824d54446e15cb7c098f1c836dc3a155e33bb110d2171fd51376bf261d8b462',
    },
  ],
};
