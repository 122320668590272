import { eventBus } from '@/utils/plugins/event-bus';
import { store } from '@/store';
import { RahmetApp } from '@shared/RahmetApp';

export const LoyaltyService = {
  init() {
    eventBus.on('eb_on_fetch_tests', () => {
      // console.log('LoyaltyService init', {
      //   webview: RahmetApp.isWebView(),
      //   ab: store.getters['abtest/testsObj']['loyalty'],
      //   testsObj: store.getters['abtest/testsObj'],
      // });
      if (!RahmetApp.isWebView()) return;
      if (!store.getters['isAuthorized']) return;
      if (store.getters['abtest/testsObj']['loyalty'] === 'test') {
        store.commit('loyalty/SET_AVAILABLE', true);
      }
    });
  },
};
