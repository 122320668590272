<template>
  <RenderlessAddressContext
    v-slot="{
      timeTitle,
      addressTitle,
      deliveryTime,
      showDistantIcon,
      onAddressClick,
    }"
  >
    <div class="MainAddressContext" @click="onAddressClick">
      <div class="main-address-context">
        <div class="main-address-context-line">
          <p class="line-clamp-1 break-all">{{ addressTitle }}</p>
          <IconArrow />
        </div>
        <div
          class="main-address-context-line main-address-context-time"
          :class="{ 'pr-3': !showDistantIcon }"
        >
          <p class="line-clamp-1 break-all">{{ timeTitle }}</p>

          <ExtendedDeliveryTooltip
            v-if="showDistantIcon"
            type="delivery-context-mob"
            class="main-address-context-icon"
            :time="deliveryTime"
            @click.native.stop
          />
        </div>
      </div>
    </div>
  </RenderlessAddressContext>
</template>

<script>
import RenderlessAddressContext from './RenderlessAddressContext';
import IconArrow from './components/IconArrow.vue';

export default {
  components: {
    RenderlessAddressContext,
    IconArrow,
    ExtendedDeliveryTooltip: () =>
      import('@components/extended-delivery/ExtendedDeliveryTooltip.vue'),
  },
};
</script>

<style scoped>
.main-address-context {
  @apply text-sm font-medium leading-4;
}
.main-address-context-line {
  @apply flex items-center justify-center;
}
.main-address-context-time {
  @apply text-sirius-gray-1000;
}

.main-address-context-tooltip {
  @apply p-2 text-xs font-medium leading-14 whitespace-nowrap;
}
.main-address-context-icon {
  @apply ml-1;
  width: 20px;
  max-width: 20px;
  max-height: 20px;
}
</style>
