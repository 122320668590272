import { DevConfigs } from '@/utils/dev-config';

const getCode = (prod, stage, dev) => {
  stage = stage || prod;
  dev = dev || stage || prod;
  if (DevConfigs.isProd) return prod;
  if (DevConfigs.isStage) return stage;
  return dev;
};

export const TESTS = {
  paidDelivery: {
    code: getCode(
      'paid_delivery_20230519',
      // 'paid_delivery_20230516',
      'paid_delivery'
    ),
    groups: ['test_1', 'test_2', 'test_3'],
  },
  cashbacks: {
    code: getCode('cashback_for_new_users', 'cashbacks'),
    groups: ['test', 'control'],
  },
  tabbarCart: {
    code: getCode('tabbar_without_cart', 'tabbar_without_cart_20240624'),
    groups: ['test', 'control'],
  },
  surgePlank: {
    code: getCode('remove_surge_plank', 'remove_surge_plank_20241207'),
    groups: ['test', 'control'],
  },
  newSpecCats: {
    code: getCode('ab_new_spec_cats', 'ab_new_spec_cats'),
    groups: ['test', 'control'],
  },
};
