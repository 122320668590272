import imageDesktop from '@img/static-stories/dos-1000-cover-desktop.jpg';
import imageMobile from '@img/static-stories/dos-1000-cover-mobile.jpg';
import imageKk from '@img/static-stories/dos-1000-kk.jpg';
import imageRu from '@img/static-stories/dos-1000-ru.jpg';

export const firstOrderDiscountBanner = {
  type: 'story',
  code: 'promo1000',
  fixedPosition: true,
  imageDesktop,
  imageMobile,
  textKk: `
    Алғашқы тапсырысқа -1000 теңге
  `,
  textRu: `
    -1000 тенге на первый заказ
  `,
  stories: [{ imageKk, imageRu }],
};
