<template>
  <MainLayout with-checkout-reminder>
    <template v-if="$isWebView" #header>
      <ToolBar :back="onBackClick" header-sliding>
        <SearchTrigger v-model="query" autofocus />
        <template v-if="false" #bottom-toolbar>
          <div class="px-4">
            <div
              class="w-full flex items-center justify-center bg-sirius-gray-200 text-medium text-sirius-black-100 rounded-2xl"
            >
              <div
                v-for="mode in SEARCH_MODES"
                :key="mode.type"
                class="w-full flex items-center justify-center text-center h-9 text-sm font-medium rounded-2xl transition-all duration-100"
                :class="{
                  'bg-sirius-orange-100 text-white': searchMode === mode.type,
                }"
                size="sm"
                @click="changeSearchMode(mode.type)"
              >
                {{ $t(mode.title) }}
              </div>
            </div>
          </div>
        </template>
      </ToolBar>
    </template>

    <div class="CategorySearchPage h-full mt-4">
      <transition name="fade" mode="out-in">
        <UiRippleLoader v-if="loading" class="mx-auto" />
        <ul
          v-else-if="!query && !products.length"
          class="overflow-y-scroll overscroll-contain hidden-scrollbar px-4"
        >
          <MobileCatalogItem
            v-for="(item, index) in categoriesByGroup"
            :key="index"
            :item="item"
            class="mt-5 first:mt-0"
            @onNavigate="goToCategory($event)"
          />
        </ul>
        <UiInfiniteScroll
          v-else-if="isSearched"
          :is-loading="isNextPageLoading"
          class="duration-100"
          @callback="fetchNextPage"
        >
          <div
            v-if="
              searchedCategories && !$isDesktop && searchMode === 'EVERYWHERE'
            "
            class="SearchedCategories px-4 pb-4"
          >
            <div
              v-for="(item, index) in searchedCategories"
              :key="index"
              class="mt-3 pb-3 border-b border-sirius-gray-400"
            >
              <CategoryBlock
                class="w-full"
                type="search"
                :category="item"
                @onNavigate="goToCategory(item)"
              />
            </div>
          </div>

          <ProductsSearchResult
            v-if="isEverywhereSearch"
            class="px-4"
            :added-from="PRODUCT_ADDED.SEARCH"
            :categories="categories"
            sticky-category-pos="top"
          />
          <ProductsSearchResultCategory
            v-if="isCategorySearch"
            class="px-4"
            :products="products"
          />

          <WishButton
            class="px-4 md:px-0 mt-10 md:mt-12"
            :show-button="hasWishButton"
          />
        </UiInfiniteScroll>
      </transition>
    </div>
  </MainLayout>
</template>

<script>
import { Analitycs, EVENTS, SEGMENT_EVENTS } from '@shared/services/analitycs';
import { mergeCategories } from '@shared/utils/product/merge-categories';
import { groupBySortWeight } from '@shared/utils/product/group-by';
import { PRODUCT_ADDED } from '@shared/config/product';
import { mapGetters, mapState } from 'vuex';
import { api } from '@shared/services/api';
import { SEARCH_MODES } from './config';

import ProductsSearchResultCategory from '@shared/components/product/ProductsSearchResultCategory.vue';
import ProductsSearchResult from '@shared/components/product/ProductsSearchResult.vue';
import SearchTrigger from '@pure-ui/components/SearchTrigger/SearchTrigger.vue';
import MobileCatalogItem from '@shared/components/catalog/MobileCatalogItem.vue';
import CategoryBlock from '@shared/components/catalog/CategoryBlock.vue';
import ToolBar from '@shared/components/layout/ToolBar.vue';
import UiInfiniteScroll from '@ui/UiInfiniteScroll.vue';
import UiRippleLoader from '@ui/UiRippleLoader.vue';
import MainLayout from '@layout/MainLayout.vue';
import debounce from 'debounce';

export default {
  name: 'CategorySearchView',
  components: {
    ProductsSearchResultCategory,
    ProductsSearchResult,
    MobileCatalogItem,
    UiInfiniteScroll,
    UiRippleLoader,
    CategoryBlock,
    SearchTrigger,
    MainLayout,
    ToolBar,
    WishButton: () => import('@shared/components/catalog/WishButton.vue'),
  },
  inject: ['toast'],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      searchMode: SEARCH_MODES[0].type,
      query: '',
      loading: false,
      isSearched: false,
      categories: {},
      products: [],
      page: 0,
      maxPage: undefined,
      isNextPageLoading: false,
      pageCache: {},
      PRODUCT_ADDED,
      searchedCategories: null,
    };
  },
  computed: {
    ...mapGetters('cart', ['cartSize']),
    ...mapGetters('abtest', ['testGroups']),
    ...mapGetters('delivery', ['warehouseId']),
    ...mapState('catalog', {
      categoriesByGroup: state => state.categoriesByGroup,
      storeCategories: state => state.categories,
    }),
    isCategorySearch() {
      return this.searchMode === 'BY_CATEGORY';
    },
    isEverywhereSearch() {
      return this.searchMode === 'EVERYWHERE';
    },
    nextPage() {
      return this.page + 1;
    },
    hasWishButton() {
      if (this.isNextPageLoading) return false;
      if (!this.maxPage) return true;
      return this.page === this.maxPage;
    },
  },
  watch: {
    query(v) {
      if (v.trim()) {
        this.onInput();
      } else {
        this.resetData();
      }
    },
  },
  created() {
    this.SEARCH_MODES = SEARCH_MODES;
    this.categoryId = this.id;

    if (this.$route.query.search) {
      this.configureDeeplink();
    }
  },
  methods: {
    configureDeeplink() {
      this.query = this.$route.query.search;
      this.onInput();
    },
    onInput: debounce(function onInput() {
      if (this.query.trim() === '') return;

      this.loading = true;
      this.resetData();

      this.searchedCategories = this.getCategoriesByQuery();

      this.$store
        .dispatch('delivery/FIND_WAREHOUSE_IF_NEEDED')
        .then(() => {
          return this.isCategorySearch ? this.searchIn() : this.searchAll();
        })
        .finally(() => {
          this.loading = false;
        });
    }, 1000),

    searchIn() {
      const payload = {
        warehouse_id: this.warehouseId,
        keyword: this.query,
        category_id: this.categoryId,
        page: this.nextPage,
        ab_groups: this.testGroups,
      };

      return api.lavka
        .searchInAllCategory(payload)
        .then(({ data }) => {
          this.isSearched = true;
          this.products = [...this.products, ...data.data.items];
          this.updatePagination(data.data.pagination);

          Analitycs.logSegmentEvent(SEGMENT_EVENTS.VIEW_PRODUCT_LIST, {
            screenName: 'Category Page',
            query: this.query,
            categoryId: this.categoryId,
            pageNum: this.nextPage - 1,
            logId: data.data.log_id,
            warehouseId: this.warehouseId,
          });
        })
        .catch(err => this.toast.show(err.message));
    },
    searchAll() {
      const payload = {
        warehouse_id: this.warehouseId,
        keyword: this.query,
        page: this.nextPage,
        ab_groups: this.testGroups,
      };
      return api.lavka
        .searchInAllCategory(payload)
        .then(({ data }) => {
          this.isSearched = true;

          const temp = groupBySortWeight(data.data.items);
          this.categories = mergeCategories(this.categories, temp);
          this.updatePagination(data.data.pagination);

          Analitycs.logSegmentEvent(SEGMENT_EVENTS.VIEW_PRODUCT_LIST, {
            screenName: 'Category Page',
            warehouseId: this.warehouseId,
            query: this.query,
            pageNum: this.nextPage - 1,
            logId: data.data.log_id,
          });
        })
        .catch(err => this.toast.show(err.message));
    },
    resetData() {
      this.loading = false;
      this.categories = {};
      this.products = [];
      this.page = 0;
      this.pageCache = {};
    },
    changeSearchMode(modeType) {
      this.searchMode = modeType;
      this.resetData();
      this.isSearched = false;
      this.onInput();
      Analitycs.logEvent(EVENTS.SEARCH_MODE_TAB, { tab: this.searchMode });
    },
    fetchNextPage() {
      const inCache = this.pageCache.hasOwnProperty(this.nextPage);
      if (this.page < this.maxPage && !inCache) {
        this.isNextPageLoading = true;
        this.pageCache[this.nextPage] = true;
        const promise = this.isCategorySearch
          ? this.searchIn()
          : this.searchAll();

        promise.finally(() => (this.isNextPageLoading = false));
      }
    },
    updatePagination(paging) {
      this.page = Number(paging.current_page);
      this.maxPage = paging.total_pages;
    },
    onBackClick() {
      Analitycs.logEvent(EVENTS.BACK_SEARCH);
      this.$router.go(-1);
    },

    getCategoriesByQuery() {
      let result = null;
      if (this.storeCategories && !this.$isDesktop) {
        result = this.storeCategories.filter(
          e => e.name.search(this.query) !== -1
        );
      }
      return result && result.length !== 0 ? result : null;
    },
    goToCategory(item) {
      if (!item) return;
      Analitycs.logEvent(EVENTS.CATEGORY_SELECTED, {
        categoryId: item.id,
        categoryName: item.name,
        from: 'search',
      });
      this.$router.push({
        name: 'category',
        params: {
          id: item.id.toString(),
        },
      });
    },
  },
  i18n: {
    messages: {
      kk: {
        inCategory: 'Осы бөлім',
        inAll: 'Барлық бөлім',
      },
      ru: {
        inCategory: 'Искать в категории',
        inAll: 'Искать везде',
      },
    },
  },
};
</script>
