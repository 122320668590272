<template>
  <div
    class="w-full flex items-center whitespace-nowrap overflow-auto md:overflow-visible hidden-scrollbar"
  >
    <template v-if="links && links.length">
      <router-link
        v-for="(item, index) in links"
        :key="index"
        :to="item.path"
        class="flex items-center font-medium text-sirius-black-100 hover:text-sirius-orange-100 active:text-sirius-orange-300"
      >
        <IconBreadArrow
          v-if="isSingle"
          class="mr-2 transform rotate-180 text-sirius-orange-100 active:text-sirius-orange-300"
        />
        <p class="" :class="{ 'text-sirius-gray-100': isLast(index) }">
          {{ $t(item.name) }}
        </p>
        <IconBreadArrow
          v-if="!isLast(index) && !isSingle"
          class="mx-2 text-sirius-orange-100 active:text-sirius-orange-300"
        />
      </router-link>
    </template>
    <template v-else>
      <router-link
        to="/"
        class="flex items-center font-medium text-sirius-black-100 hover:text-sirius-orange-100 active:text-sirius-orange-300"
      >
        <IconBreadArrow
          class="mr-2 transform rotate-180 text-sirius-orange-100 active:text-sirius-orange-300"
        />
        <p>
          {{ $t('returnToMain') }}
        </p>
      </router-link>
    </template>

    <UiInlineIcon
      v-if="hasSupport"
      name="support-headphones"
      class="support-btn"
      @click.native="clickSupport"
    />
  </div>
</template>

<script>
import UiInlineIcon from '@pure-ui/components/UiInlineIcon/UiInlineIcon.vue';
import IconBreadArrow from '../icons/IconBreadArrow.vue';

export default {
  name: 'UiBreadCrumbs',
  components: { IconBreadArrow, UiInlineIcon },
  inject: ['bottomSheetPopup'],
  props: {
    links: {
      type: Array,
      default: () => [],
    },
    hasSupport: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isSingle() {
      return this.links.length === 1;
    },
  },
  methods: {
    isLast(index) {
      return this.links.length - 1 === index && this.links.length !== 1;
    },
    clickSupport() {
      this.$eventBus.emit('eb_open_support_popup');
    },
  },
  i18n: {
    messages: {
      ru: {
        Главная: 'Главная',
        'Все заказы': 'Все заказы',
        returnToMain: 'Вернуться на главную',
      },
      kk: {
        Главная: 'Басты бет',
        'Все заказы': 'Тапсырыстар',
        returnToMain: 'Бастапқы мәзірге оралу',
      },
    },
  },
};
</script>

<style scoped>
.support-btn {
  @apply ml-auto cursor-pointer;
}
</style>
