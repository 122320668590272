import { client } from '../../clients/client';

export default function (ids, warehouseId) {
  if (!ids.length) return Promise.resolve([]);

  return client
    .get(`/lavka/v1/catalog/by_product_ids`, {
      params: {
        warehouse_id: warehouseId,
        product_ids: ids,
      },
    })
    .then(res => res.data);
}
