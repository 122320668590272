import { rFetchSaleProducts } from '@shared/services/api/requestsV2/sale-category';
import { store } from '@/store';
import { shuffleProducts } from './shuffle-products';
import { removeDublicates } from './remove-duplicates';
import { CityService } from '@services/city';
import { eventBus } from '@/utils/plugins/event-bus';

const getParentIdOfPayed = () => {
  return 981;
};
const getCategoryIdOfPayed = () => {
  return CityService.getCity() === 2 ? 1382 : 982;
};
const getParentId = () => {
  return 798;
};
// const getCategoryId = () => {
//   return CityService.getCity() === 2 ? 1373 : 204;
// };

const setSaleCategoryToStore = payload => {
  store.commit('catalog/SET_SALE_CATEGORY', payload);
};

const getTests = () => {
  return store.getters['abtest/testGroups'];
};
const getWarehouseId = () => {
  return store.getters['delivery/warehouseId'];
};

function fetchCatalog(DN, warehouseId, parentId, categoryId, limit) {
  warehouseId = warehouseId || getWarehouseId();
  return new Promise(resolve => {
    const payload = {
      DN,
      parent_id: parentId,
      is_active: 1,
      page: 1,
      limit,
      category_ids: categoryId && [categoryId],
      order: 'popular_asc',
      warehouse_id: warehouseId,
      ab_groups: getTests(),
    };

    rFetchSaleProducts(payload)
      .then(res => {
        if (res.data?.items?.length) return resolve(res.data.items);
        return resolve([]);
      })
      .catch(err => {
        console.error(err);
        return resolve([]);
      });
  });
}

/**
 * В эту категорию товары заводят вручную
 */
function fetchPayedSaleCatalog(warehouseId) {
  return fetchCatalog(
    'sale_hits_cat',
    warehouseId,
    getParentIdOfPayed(),
    getCategoryIdOfPayed(),
    10
  );
}

/**
 * В эту категорию автоматически попадают товары
 * когда в 1С заводят промоакцию
 */
function fetchSaleCatalog(warehouseId) {
  return fetchCatalog('sale_cat', warehouseId, getParentId(), null, 20);
}

/**
 * Вызываем метод во время загрузки приложения
 * сразу после получения склада
 *
 * Получаем список продуктов категорий Акции
 * и проплаченых акционных товаров
 *
 * Делаем шафл не проплаченых акционных товаров
 *
 * Сохранеяем их в Store catalog/saleCategory
 */
async function loadSliderCatalog(warehouseId) {
  let defaultSaleCategory = {
    id: getParentId(),
    // childId: getCategoryId(), // deprecated
    items: [],
  };
  setSaleCategoryToStore(defaultSaleCategory); // resetting

  const payedProducts = await fetchPayedSaleCatalog(warehouseId);
  const products = await fetchSaleCatalog(warehouseId);

  const shuffledProducts = shuffleProducts([...products]);
  let results = [...payedProducts, ...shuffledProducts];
  results = removeDublicates(results);
  results = results.splice(0, 10);

  let saleCategory = {
    id: getParentId(),
    // childId: getCategoryId(), // deprecated
    items: results,
  };

  setSaleCategoryToStore(saleCategory);
}

function loadSliderCatalogOnWarehouseChange(data) {
  let wId = data.newVal?.id || CityService.getCityData().defaultWarehouseId;
  loadSliderCatalog(wId);
}
// нужен если меняетс город но без склада
function loadSliderCatalogOnCityChange(city) {
  if (!store.getters['delivery/warehouse']) {
    loadSliderCatalog(city.defaultWarehouseId);
  }
}

function init() {
  return loadSliderCatalog().finally(() => {
    eventBus.on('eb_on_warehouse_change', loadSliderCatalogOnWarehouseChange);
    eventBus.on('eb_on_city_changed', loadSliderCatalogOnCityChange);
  });
}

export const SaleCategoryService = {
  fetchPayedSaleCatalog,
  getParentIdOfPayed,
  getParentId,
  init,
};
