<template>
  <div class="MarkerPin">
    <div class="MarkerPin__pin" :class="{ __pinup: pinup }">
      <div
        class="MarkerPin__circle"
        :class="`__${color} ${!pinup && !disabled && !loading ? '__shrink' : ''} `"
      >
        <p class="MarkerPin__text"><slot> </slot></p>
      </div>
      <div class="MarkerPin__line">
        <IconLine />
      </div>
      <div class="MarkerPin__shadow"><IconShadow /></div>
    </div>
  </div>
</template>

<script>
import IconShadow from './components/IconShadow.vue';
import IconLine from './components/IconLine.vue';

export default {
  components: {
    IconShadow,
    IconLine,
  },
  props: {
    disabled: Boolean,
    distant: Boolean,
    pinup: Boolean,
    loading: Boolean,
  },
  computed: {
    color() {
      if (this.disabled) return 'gray';
      if (this.distant) return 'blue';
      return 'orange';
    },
  },
};
</script>

<style scoped>
.MarkerPin__pin {
  position: relative;
  width: 32px;
  height: 58px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.MarkerPin__circle {
  min-width: 32px;
  min-height: 32px;
  width: 32px;

  z-index: 3;
  height: 32px;
  padding: 0 12px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 16px;
  border: 2.5px solid white;

  background-color: #ffa100;
  box-shadow: 0px 1px 8px 0px rgba(142, 108, 49, 0.45);

  transition:
    width 300ms ease,
    background-color 300ms ease,
    transform 300ms ease;

  overflow: hidden;
}
.__pinup .MarkerPin__circle {
  transform: translateY(-12px);
}
.__shrink.MarkerPin__circle {
  width: 95px;
}
.__orange.MarkerPin__circle {
  background-color: #ffa100;
}
.__blue.MarkerPin__circle {
  background-color: #7284e4;
}
.__gray.MarkerPin__circle {
  background-color: #acaaba;
}

.MarkerPin__text {
  @apply text-sm font-medium leading-4 text-white whitespace-nowrap;
  opacity: 0;
  transition: opacity 300ms ease;
}
.__shrink .MarkerPin__text {
  opacity: 1;
}

.MarkerPin__line {
  z-index: 2;
  position: absolute;
  top: 28px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: fit-content;
  box-shadow: 0px 1px 8px 0px rgba(62, 49, 142, 0.45);

  transition: transform 300ms ease;
}
.__pinup .MarkerPin__line {
  transform: translateY(-12px);
}

.MarkerPin__shadow {
  position: absolute;
  bottom: 0;

  width: fit-content;
  animation: none 2s infinite ease-in-out;
  animation-name: none;
}
.__pinup .MarkerPin__shadow {
  animation-name: scalePulse;
}

@keyframes scalePulse {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}
</style>
