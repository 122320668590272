import { store } from '@/store';
import { TESTS } from './tests';
import { $log } from '@/utils/plugins/logger';
import { DevConfigs } from '@/utils/dev-config';
import { SegmentService } from '@services/segment';
import { ClientService } from '@shared/services/client';
import { rFetchTests } from '@shared/services/api/requestsV2';
import { Analitycs, EVENTS, SEGMENT_EVENTS } from '@shared/services/analitycs';
import { eventBus } from '@/utils/plugins/event-bus';
import { RahmetApp } from '@shared/RahmetApp';

let lastSegmentId = -1;

const setTestsToStore = tests => {
  store.commit('abtest/SET_TESTS', tests);
};

const addTestToStore = test => {
  store.commit('abtest/ADD_TEST', test);
};

const addMockTest = (code, group) => {
  if (DevConfigs.isProd) return;
  addTestToStore({ test_code: code, test_group_code: group });
};

/**
 * Получаем значение абтеста по названию кода
 * @param {String} code код аб теста
 * @returns
 */
const getGroupByCode = code => {
  return store.getters['abtest/getGroupByCode'](code);
};

const logAnalytics = res => {
  if (!(res && res.length > 0)) return;

  let tests = 'undefined';
  tests = res.reduce((obj, el) => {
    obj[`${el.test_code}`] = el.test_group_code;
    return obj;
  }, {});

  Analitycs.logEvent(EVENTS.AB_TEST, { tests, segment_id: lastSegmentId });
  Analitycs.logSegmentEvent(SEGMENT_EVENTS.AB_TEST, {
    tests,
    segment_id: lastSegmentId,
  });
};

function getUniqId() {
  if (DevConfigs.isProd) return SegmentService.getId();
  const { deviceId } = ClientService.getContext();
  return Promise.resolve(deviceId);
}

const fetchTests = () => {
  // Временно выключили аб тесты на сайте, т. к. если адресов нет — нет возможности
  // определить город и все будут попадать в Алматы
  if (!RahmetApp.isWebView()) return Promise.resolve();
  const { isAuthorized } = store.getters;
  let tests = null;
  return getUniqId()
    .then(id => {
      $log('FetchTests id:', id);
      lastSegmentId = id;
      return rFetchTests(isAuthorized, id);
    })
    .then(res => {
      tests = [...res.data];
      setTestsToStore(tests);
      logAnalytics(tests);
    })
    .catch(() => {
      setTestsToStore(tests);
    })
    .finally(() => {
      addMockTest(TESTS.cashbacks.code, TESTS.cashbacks.groups[0]);
      // addMockTest(TESTS.tabbarCart.code, TESTS.tabbarCart.groups[0]);
      addMockTest('mock', 'test');
      addMockTest(TESTS.paidDelivery.code, TESTS.paidDelivery.groups[0]);
      addMockTest('catalog_section', 'test');
      addMockTest('popular_products', 'test');
      addMockTest('loyalty', 'test');
      eventBus.emit('eb_on_fetch_tests', tests);
    });
};

/**
 * Нужно для тестировщика, чтобы
 * быстро переключить группу теста из консоли
 * test -> control или control -> test
 */
function toggleTestGroup(testName) {
  const restTests = store.getters['abtest/tests'].filter(
    v => v.test_code !== testName
  );

  const current = store.getters['abtest/testsObj'][testName];
  const newVal = {
    test_code: testName,
    test_group_code: current === 'test' ? 'control' : 'test',
  };

  setTestsToStore([...restTests, newVal]);
}

const init = () => {
  return fetchTests();
};

export const ABTestService = {
  init,
  fetchTests,
  getGroupByCode,
  toggleTestGroup,
};
