import { client } from '../../clients/client';
/**
 * Выдача заказов юзера
 */

// eslint-disable-next-line camelcase
export default function ({ tab, page = 1, limit = 20, is_paid = 1 }) {
  const params = { tab, page, limit, is_paid };
  return client.get(`/lavka/v2/orders`, { params }).then(res => res.data);
}
