import { client } from '../../clients/client';
import { isAuthorizedUrl } from '../../utils/is-authorized-url';

export default function (payload, isAuthorized = false) {
  return client
    .put(`/lavka/v4${isAuthorizedUrl(isAuthorized)}/user_address`, payload, {
      useDeviceId: true,
    })
    .then(response => response.data);
}
