import { client } from '../../clients/client';

/**
 * Выдача продуктов категории Акции для мини списка на главной
 */
export default function (payload) {
  return client
    .get('/lavka/v1/catalog', {
      params: payload,
    })
    .then(response => response.data);
}
