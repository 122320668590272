<template>
  <MainLayout with-checkout-reminder>
    <template v-if="$isWebView" #header>
      <ToolBar bottom-toolbar>
        <span class="ml-2">
          {{ $t('favorites') }}
        </span>

        <template #right-side>
          <div @click="$emit('backToRahmetApp')">
            <IconMiniappClose />
          </div>
        </template>

        <template #bottom-toolbar>
          <div class="px-4 flex items-center">
            <SearchTrigger
              disabled
              @click.native="$router.push({ name: 'search' })"
            />
            <!--          Временно скрыли потому что нет фильтров на бэке. Задачу возьмем позже-->
            <div
              v-if="false"
              class="ml-2"
              @click="$emit('onFilterPopupSetShow', true)"
            >
              <IconFilterButton />
            </div>
          </div>
        </template>
      </ToolBar>
    </template>
    <template v-else-if="!$isDesktop" #header>
      <WebMobileHeaderCategory />
    </template>

    <div class="top-spacing" :class="topSpacing">
      <!-- Десктопный заголовок и хлебные крошки -->
      <div v-if="$isDesktop" class="container" style="max-width: 988px">
        <UiBreadCrumbs class="pt-4 pb-8" />
        <div class="mt-8 flex justify-between">
          <h1 class="text-32 font-bold leading-10 pb-2">
            {{ $t('favorites') }}
          </h1>
          <!--          Временно скрыли потому что нет фильтров на бэке. Задачу возьмем позже-->
          <DesktopFilterButton
            v-if="false"
            @click="$emit('onFilterPopupSetShow', true)"
          />
        </div>
      </div>
      <!-- / Десктопный заголовок и хлебные крошки -->

      <div>
        <div class="mobile-container px-3">
          <div class="container pt-2 md:pt-8" style="max-width: 988px">
            <template v-if="!$isWebView && !$isDesktop">
              <h1 class="text-2xl font-bold leading-10 pb-4">
                {{ $t('favorites') }}
              </h1>
            </template>
            <UiRippleLoader v-if="loading" />
            <template v-else>
              <FavoritesEmpty
                v-if="!products.length"
                @onGoToMain="$emit('onGoToMain')"
              />
              <UiInfiniteScroll
                v-else
                :is-loading="pagination.loading"
                class="duration-100"
                @callback="$emit('onNextPage')"
              >
                <div v-if="categoryNpsPlank" class="pb-8">
                  <CategoryNPSPlank
                    @click.native="$emit('onCategoryNPSPlank')"
                  />
                </div>
                <div
                  class="products grid gap-x-2 gap-y-2 grid-cols-3 md:grid-cols-5"
                >
                  <div v-for="product in products" :key="product.id">
                    <ProductCardWrapper
                      :product="
                        product.product_info ? product.product_info : product
                      "
                      :added-from="addedFrom"
                    />
                  </div>
                </div>
              </UiInfiniteScroll>
            </template>
          </div>
        </div>
      </div>
    </div>

    <ConfirmClose @confirm="$emit('onConfirmClose')" @close="popup.hide()" />

    <FilterSheetPopup
      v-bind="{
        ...filterPopup,
        sortOptions,
      }"
      @onClose="$emit('onFilterPopupSetShow', false)"
      @onSortChange="$emit('onFilterPopupSortChange', $event)"
      @onSubmit="$emit('onFilterPopupSubmit')"
    />
  </MainLayout>
</template>

<script>
import MainLayout from '@layout/MainLayout.vue';
import ToolBar from '@layout/ToolBar.vue';
import SearchTrigger from '@pure-ui/components/SearchTrigger/SearchTrigger.vue';
import ProductCardWrapper from '@shared/components/product/ProductCardWrapper.vue';
import UiBreadCrumbs from '@ui/UiBreadCrumbs.vue';
import UiRippleLoader from '@ui/UiRippleLoader.vue';
import UiInfiniteScroll from '@ui/UiInfiniteScroll.vue';
import IconMiniappClose from '@shared/components/icons/IconMiniappClose.vue';
import WebMobileHeaderCategory from '@components/header/WebMobileHeaderCategory.vue';

export default {
  name: 'FavoritesPage',
  components: {
    WebMobileHeaderCategory,
    ProductCardWrapper,
    UiInfiniteScroll,
    IconMiniappClose,
    UiRippleLoader,
    UiBreadCrumbs,
    SearchTrigger,
    MainLayout,
    ToolBar,
    CategoryNPSPlank: () =>
      import(
        '@pure-ui/components/CategoryNPS/CategoryNPSPlank/CategoryNPSPlank.vue'
      ),
    IconFilterButton: () =>
      import('@shared/components/icons/IconFilterButton.vue'),
    ConfirmClose: () =>
      import('@shared/components/confirm-close/ConfirmClose.vue'),
    FilterSheetPopup: () =>
      import('@/views/favorites/components/FilterSheetPopup.vue'),
    FavoritesEmpty: () => import('./components/FavoritesEmpty.vue'),
    DesktopFilterButton: () => import('./components/DesktopFilterButton.vue'),
  },
  inject: ['bottomSheet', 'popup'],
  props: {
    products: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    filterPopup: {
      type: Object,
      default: () => ({}),
    },
    pagination: {
      type: Object,
      default: () => ({
        loading: false,
      }),
    },
    addedFrom: {
      type: String,
      default: '',
    },
    categoryNpsPlank: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    isButtonsShown: false,
  }),
  computed: {
    /*
     Не очень удачное решение, нужно будет убрать
     */
    topSpacing() {
      if (this.$isDesktop) return 'mt-10';
      if (!this.$isWebView) return 'mt-16';
      return 'mt-16';
    },
    sortOptions() {
      return [
        { id: 'created_at:desc', title: this.$t('recentlyAdded') },
        { id: 'frequency:desc', title: this.$t('frequentlyBought') },
      ];
    },
  },
  i18n: {
    messages: {
      ru: {
        favorites: 'Избранное',
        recentlyAdded: 'недавно добавленные',
        frequentlyBought: 'часто покупаемые',
      },
      kk: {
        favorites: 'Таңдаулы',
        recentlyAdded: 'жақында қосылғандар',
        frequentlyBought: 'жиі сатып алынатындар',
      },
    },
  },
};
</script>
