<template>
  <RenderlessAddressContext
    v-slot="{ timeTitle, addressTitle, onAddressClick }"
    cart
  >
    <div
      class="CartAddressContext cart-address-context"
      @click="onAddressClick"
    >
      <div>
        <p class="cart-address-context-address">
          {{ addressTitle }}
        </p>
        <p class="cart-address-context-time">{{ timeTitle }}</p>
      </div>
      <div class="cart-address-context-arrow"><IconRightArrow /></div>
    </div>
  </RenderlessAddressContext>
</template>

<script>
import RenderlessAddressContext from './RenderlessAddressContext';
import IconRightArrow from './components/IconRightArrow.vue';
export default {
  components: { RenderlessAddressContext, IconRightArrow },
};
</script>

<style scoped>
.cart-address-context {
  @apply w-full flex items-center justify-between space-x-2;
}
.cart-address-context-address {
  @apply text-lg font-bold leading-6 line-clamp-1 break-all;
}
.cart-address-context-time {
  @apply mt-1 text-sirius-gray-1000 lowercase text-base font-medium leading-5;
}
.cart-address-context-arrow {
  @apply h-full;
}
</style>
