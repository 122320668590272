<template>
  <div
    v-scroll="{
      disabled: !scrollEnabled,
      onDirectionChanged: v => (searchShown = v === 'up'),
    }"
    class="WebMobileHeaderMain header"
  >
    <WebMobileHeaderBase>
      <template #left>
        <div class="header-left flex items-center">
          <CatalogIcon />
          <div class="header-search-icon" :class="{ '--show': !searchShown }">
            <IconSearchButton @click.native="goSearch" />
          </div>
        </div>
      </template>

      <div class="flex justify-center"><MainAddressContext /></div>

      <template #right><ProfileIcon /></template>
    </WebMobileHeaderBase>

    <ExpandableTransition>
      <div v-if="searchShown" class="header-search">
        <div class="pb-3 px-4 bg-white flex">
          <SearchTrigger
            style="height: 40px"
            disabled
            @click.native="onSearchFocus"
          />
          <FavoriteIcon class="ml-3" />
        </div>
      </div>
    </ExpandableTransition>
  </div>
</template>

<script>
import { Analitycs, EVENTS } from '@shared/services/analitycs';
import Scroll from '@/utils/directives/scroll';

import CatalogIcon from '@shared/components/header/CatalogIcon.vue';
import ProfileIcon from '@shared/components/header/ProfileIcon.vue';
import FavoriteIcon from '@/views/main/components/FavoriteIcon.vue';
import IconSearchButton from '@shared/components/icons/IconSearchButton.vue';
import ExpandableTransition from '@shared/components/ExpandableTransition.vue';
import SearchTrigger from '@pure-ui/components/SearchTrigger/SearchTrigger.vue';
import MainAddressContext from '@components/address-context/MainAddressContext.vue';
import WebMobileHeaderBase from '@components/header/web-mobile-header/WebMobileHeaderBase.vue';

export default {
  name: 'WebMobileHeaderMain',
  components: {
    CatalogIcon,
    ProfileIcon,
    FavoriteIcon,
    SearchTrigger,
    IconSearchButton,
    MainAddressContext,
    WebMobileHeaderBase,
    ExpandableTransition,
  },
  directives: {
    Scroll,
  },
  data: () => ({
    searchShown: true,
    scrollEnabled: false,
  }),
  mounted() {
    setTimeout(() => (this.scrollEnabled = true), 1000);
  },
  methods: {
    onSearchFocus() {
      Analitycs.logEvent(EVENTS.SEARCH_CLICKED, { page: 'main' });
      this.$router.push({ name: 'search' });
    },
    goSearch() {
      this.$router.push({ name: 'search' });
    },
  },
};
</script>

<style>
.header-left {
  @apply flex items-center;
}
.header-search-icon {
  @apply flex justify-end;
  transition: width 0.3s;
  width: 0;
  overflow: hidden;
}
.--show {
  width: 52px;
  overflow: visible;
}

.header-search {
  transition: height 0.3s;
  z-index: 71;
}
</style>
