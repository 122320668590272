import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { createStorage } from '@shared/services/storage';
import { eventBus, BUS_EVENTS } from '@shared/utils/event-bus';

Vue.use(VueI18n);

const storage = createStorage(window.localStorage);

export const i18n = new VueI18n({
  locale: 'ru', // ['kk', 'ru']
  fallbackLocale: 'ru',
  pluralizationRules: {
    /**
     * Похоже, пример из документации
     * https://kazupon.github.io/vue-i18n/guide/pluralization.html#custom-pluralization
     * работает некорректно.
     * При передаче '0 бонусов | {n} бонус | {n} бонуса | {n} бонусов' числа 114
     * возвращает '114 бонуса'
     *
     * Взял пример из https://gist.github.com/znechai/1b25d0ee9a92e5b879175ab4f040dbbc
     */
    ru: function ru(choice) {
      const cases = [2, 0, 1, 1, 1, 2];
      const count = choice;
      return (
        (count % 100 > 4 && count % 100 < 20
          ? 2
          : cases[Math.min(count % 10, 5)]) + 1
      );
    },
  },
});

const key = 'lang';
const defaultValue = { lang: 'ru' };

function setToStorage(newVal) {
  storage.stringify(key, newVal);
}
function getFromStorage() {
  return storage.parse(key, defaultValue);
}

function getLocale() {
  return getFromStorage().lang;
}
function setLocale(lang) {
  if (!lang) lang = getLocale();
  eventBus.$emit(BUS_EVENTS.LOCALE_CHANGE, lang);

  i18n.locale = lang;
  setToStorage({ lang });
}

export const LocaleService = {
  setLocale,
  getLocale,
};
