import { extractStories } from './extract-stories';

export function storyAdapter(v) {
  return {
    type: 'story',
    code: v.code,
    textKk: v.title_kk,
    textRu: v.title_ru,
    imageMobile: v.image_mobile,
    imageDesktop: v.image_desktop,
    videoMobile: v.video_mobile,
    videoDesktop: v.video_desktop,
    url: v.url,
    fixedPosition: v.is_fixed_position,
    stories: extractStories(v),
    buttonTextKk: v.buttonTextKk ? v.buttonTextKk : null,
    buttonTextRu: v.buttonTextRu ? v.buttonTextRu : null,
  };
}
