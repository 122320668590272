<template>
  <div class="HorizontalProductCard" @click="onClick('block')">
    <div @click.stop="onClick('photo')">
      <ProductPhoto
        :src="imgSrc"
        :blured="imgBlur"
        :disabled="!available"
        :mix-blend="imgMixBlend"
        img-size="s:60:60"
        class="HorizontalProductCard__photo"
      />
    </div>

    <div class="HorizontalProductCard__content ml-3">
      <p
        class="HorizontalProductCard__title"
        :class="{ __gray: !available }"
        @click.stop="onClick('name')"
      >
        <slot name="name"> {{ name }} </slot>
      </p>

      <template v-if="available">
        <p class="HorizontalProductCard__price">
          <span
            class="HorizontalProductCard__mainPrice"
            :class="{ __oldPrice: oldPrice }"
          >
            {{ formatPrice(price) }} ₸/шт
          </span>
          <span v-if="oldPrice" class="HorizontalProductCard__discountPrice">
            {{ formatPrice(oldPrice) }} ₸
          </span>
        </p>
        <p v-if="quantity > 0" class="HorizontalProductCard__totalPrice">
          {{ formatPrice(quantity * price) }} ₸
          <LoyaltyBadge v-if="loyaltyPercent" :percent="loyaltyPercent" />
        </p>
      </template>

      <p v-else class="HorizontalProductCard__outOfStock">
        {{ $t('outOfStock') }}
      </p>
    </div>

    <div class="HorizontalProductCard__left">
      <slot name="left"> </slot>
    </div>
  </div>
</template>

<script>
import ProductPhoto from '@pure-ui/components/Product/ProductPhoto/ProductPhoto.vue';
import { formatPrice } from '@shared/utils/formatters/price';

export default {
  name: 'HorizontalProductCard',
  components: {
    ProductPhoto,
    LoyaltyBadge: () => import('./components/LoyaltyBadge/LoyaltyBadge.vue'),
  },
  props: {
    name: {
      type: String,
      // default: 'Name of Product',
      required: true,
    },
    imgSrc: {
      type: String,
      default: '',
    },

    price: {
      type: Number,
      // default: 220,
      required: true,
    },
    oldPrice: {
      type: Number,
      default: 0,
    },
    quantity: {
      type: Number,
      default: 0,
    },
    available: {
      type: Boolean,
      default: true,
    },
    loyaltyPercent: {
      type: Number,
      default: 0,
    },

    imgMixBlend: Boolean,
    imgBlur: Boolean,
  },
  methods: {
    formatPrice,
    onClick(value) {
      this.$emit('onClick', value);
    },
  },
  i18n: {
    messages: {
      ru: {
        outOfStock: 'Нет в наличии',
      },
      kk: {
        outOfStock: 'Қазір жоқ',
      },
    },
  },
};
</script>

<style scoped>
.HorizontalProductCard {
  @apply w-full flex items-start cursor-pointer;
}
.HorizontalProductCard__photo {
  @apply bg-sirius-gray-200 rounded-xl overflow-hidden;
  width: 60px;
  height: 60px;
}

.HorizontalProductCard__title {
  @apply text-sm leading-4 cursor-pointer font-normal;
}
.HorizontalProductCard__title.__gray {
  @apply text-sirius-gray-100;
}

.HorizontalProductCard__outOfStock {
  @apply text-sirius-red-400 text-xs;
}

.HorizontalProductCard__price {
  @apply mt-1.5 leading-3 text-xs font-medium text-sirius-gray-100;
}

.HorizontalProductCard__mainPrice {
  @apply mr-1;
}
.HorizontalProductCard__mainPrice.__oldPrice {
  @apply text-sirius-red-400;
}

.HorizontalProductCard__discountPrice {
  @apply mr-1 line-through;
}

.HorizontalProductCard__totalPrice {
  @apply text-base font-medium leading-5 mt-1;
}

.HorizontalProductCard__left {
  @apply ml-auto pl-3;
}
</style>
