<template>
  <div class="DeliveryContextDesk w-full relative">
    <DeskAddressContext @onClick="onDeliveryClick" />

    <AddressListDesk v-if="showList" />

    <template v-if="!isAppLoading">
      <IsAddressCorrectContainer
        v-if="canShowIsCorrectCity()"
        @onFinished="isAddressCorrectTooltipShown = true"
      />
      <NoAddressReminderContainer v-else-if="addressCount === 0" />
      <AddressConfirmationPopoverWrapper v-else />
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { CityService } from '@services/city';
import { Analitycs, EVENTS } from '@shared/services/analitycs';

import DeskAddressContext from '@components/address-context/DeskAddressContext.vue';

export default {
  name: 'DeliveryContext',
  components: {
    DeskAddressContext,
    IsAddressCorrectContainer: () =>
      import('@components/address/IsAddressCorrectContainer.vue'),
    NoAddressReminderContainer: () =>
      import('@components/address/NoAddressReminderContainer.vue'),
    AddressListDesk: () =>
      import('@components/address/AddressListDesk/AddressListDesk.vue'),
    AddressConfirmationPopoverWrapper: () =>
      import('@/views/main/components/AddressConfirmationPopoverWrapper.vue'),
  },
  inject: ['popup'],
  data: () => ({
    isAddressCorrectTooltipShown: false,
    showList: false,
  }),
  computed: {
    ...mapGetters('user', ['addressCount', 'addresses']),
    ...mapGetters(['appLoader', 'appInitLoader']),
    isAppLoading() {
      return this.appInitLoader || this.appLoader;
    },
  },
  mounted() {
    this.$store.dispatch('delivery/FETCH_EXPECTED_DELIVERY_TIME');
    if (this.$route.params.changeAddress) {
      this.openAddressList();
    }
  },
  methods: {
    openMap(from) {
      this.$eventBus.emit('eb_open_address_create_container', { from });
    },
    onDeliveryClick() {
      if (this.addressCount !== 0) this.openAddressList();
      else this.openMap('desk_header');
    },
    openAddressList(from = 'desk_header') {
      this.popup.show('address-list-toolpopup');
      this.showList = true;
      Analitycs.logEvent(EVENTS.VIEWED_ADDRESS, {
        address_list: this.addresses,
        from,
      });
    },
    canShowIsCorrectCity() {
      if (this.isAddressCorrectTooltipShown) return false;
      return !CityService.isDefaultCitySelected();
    },
  },
  eventBusEvents: {
    eb_open_address_list(data) {
      if (this.$isDesktop) this.openAddressList(data.from);
    },
  },
};
</script>
