<template>
  <!-- prettier-ignore-attribute -->
  <button
    class="
      CategoryBannerCard
      focus:outline-none
      flex flex-col relative rounded-2xl md:rounded-3xl
      bg-sirius-gray-200 h-full cursor-pointer
    "
    :style="{
      height: $isDesktop ? '320px' : '216px',
      width: $isDesktop ? '188px' : '',
    }"
    @click="onClick"
  >
    <img
      v-if="imageUrl && !videoUrl"
      :src="imageUrl"
      class="CategoryBannerCard__img"
    />
    <video
      v-if="videoUrl"
      loop
      muted
      autoplay
      playsinline
      style="object-fit: cover"
      class="CategoryBannerCard__video"
      :poster="imageUrl"
      :src="videoUrl"
    />
  </button>
</template>

<script>
export default {
  name: 'CategoryBannerCard',
  props: {
    banner: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    imageUrl() {
      const type = this.$isDesktop ? 'Desktop' : 'Mobile';
      const lang = this.$i18n.locale === 'kk' ? 'Kk' : 'Ru';
      return this.banner[`image${type}${lang}`];
    },
    videoUrl() {
      const type = this.$isDesktop ? 'Desktop' : 'Mobile';
      const lang = this.$i18n.locale === 'kk' ? 'Kk' : 'Ru';
      return this.banner[`video${type}${lang}`];
    },
  },
  methods: {
    onClick() {
      this.$emit('click');
    },
  },
};
</script>

<style scoped>
.CategoryBannerCard {
  overflow: hidden;
}

.CategoryBannerCard__img {
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: calc(100% + 2px);
  object-fit: cover;
  object-position: top center;
}

.CategoryBannerCard__video {
  top: -1px;
  bottom: -1px;
  height: calc(100% + 2px);
  position: absolute;
  width: 100%;
  object-fit: cover;
  object-position: top center;
}

/*
  На старом ios у видео появляется иконка-треугольник
*/
.CategoryBannerCard__video::-webkit-media-controls-panel {
  display: none !important;
  -webkit-appearance: none;
}
.CategoryBannerCard__video::--webkit-media-controls-play-button {
  display: none !important;
  -webkit-appearance: none;
}
.CategoryBannerCard__video::-webkit-media-controls-start-playback-button {
  display: none !important;
  -webkit-appearance: none;
}
</style>
