import { rFetchWarehouses } from '@shared/services/api/apis';
import { getPolygonsFromResponse } from './polygons';

let warehouseZones = {
  regular: [],
  extended: [],
};

export const fetchWarehouseZoneCoordinates = () => {
  if (warehouseZones.regular.length || warehouseZones.extended.length)
    return Promise.resolve(warehouseZones);

  return rFetchWarehouses()
    .then(({ data }) => {
      // для каждого склада
      warehouseZones = getPolygonsFromResponse(data);
      return warehouseZones;
    })
    .catch(() => {
      return warehouseZones;
    });
};
