import { client } from '@shared/services/api/clients/client';

export function rGetPredictionTime(
  city_id,
  warehouse_id,
  lat,
  lon,
  surge_value
) {
  return client
    .get('ryadom-eta/v1/prediction-delivery-time', {
      params: {
        city_id,
        warehouse_id,
        client_latitude: lat,
        client_longitude: lon,
        surge_value,
      },
    })
    .then(response => response.data)
    .catch(error => {
      console.error('Error fetching prediction time:', error);
      throw error;
    });
}
