import { Analitycs, EVENTS } from '@shared/services/analitycs';
import InstaStories from '@pure-ui/components/InstaStories/InstaStories.vue';
import CategoryBannerCard from './CategoryBannerCard.vue';

function getMergedVNodes(target, items, position) {
  const copy = [...items];
  copy.splice(position, 0, target);
  return copy;
}

/**
 * Добавляет среди карточек товаров
 * карточку с баннером из админки
 * или с баннером о фреше
 */
export default {
  props: {
    banner: {
      type: Object,
      default: null,
    },
    containerWidth: {
      type: Number,
      default: 0,
    },
  },
  render(h) {
    let nodes = this.$scopedSlots.default();
    let banner;

    if (this.banner) {
      banner = h(CategoryBannerCard, {
        props: { banner: this.banner },
        on: { click: this.onCategoryBannerClick },
      });
    }

    if (banner) {
      nodes = getMergedVNodes(
        banner,
        this.$scopedSlots.default(),
        this.position
      );
      const stories = h(InstaStories, {
        props: { stories: [this.banner], buttonText: this.$t('btn') },
        ref: 'stories',
        key: 'category_stories',
      });
      nodes = [...nodes, stories];
    }

    return h(
      'div',
      {
        style: 'display: contents',
      },
      nodes
    );
  },
  computed: {
    position() {
      const cw = this.containerWidth;
      if (this.$isDesktop) {
        if (cw > 788) return 4;
        if (cw > 752) return 3;
        if (cw > 719) return 2;
        return 1;
      } else {
        if (cw > 735) return 1;
        if (cw > 327) return 2;
        return 1;
      }
    },
  },
  methods: {
    onFreshBannerClick() {
      Analitycs.logEvent(EVENTS.STORIES_FRESH_CARD_CLICK);
      this.$refs.stories.show(this.banner.code);
    },
    onCategoryBannerClick() {
      Analitycs.logEvent(EVENTS.BANNER_CLICK, {
        code: this.banner.code,
      });
      this.$refs.stories.show(this.banner.code);
    },
  },
  i18n: {
    messages: {
      kk: {
        btn: 'Түсінікті!',
      },
      ru: {
        btn: 'Всё понятно!',
      },
    },
  },
};
