import Vue from 'vue';
import Vuex from 'vuex';

import { DeliveryConfigService } from '@services/delivery-config';
import { DEFAULT_CITY } from '@services/city/constants';
import { AddressService } from '@services/address';
import { BannersService } from '@services/banners';
import { $log } from '@/utils/plugins/logger';
import { UserService } from '@services/user';
import { SaleCategoryService } from '@services/sale-category';
import { RahmetApp } from '@shared/RahmetApp';
import { ABTestService } from '@services/abtest';
import { PopularProductsService } from '@services/popular-products';
import { LoyaltyModule } from './modules/loyalty';
import { CategoryNpsService } from '@services/category-nps';

import { UserModule } from './modules/user';
import { CartModule } from './modules/cart';
import { ABTestModule } from './modules/abtest';
import { OrdersModule } from './modules/orders';
import { LoggerModule } from './modules/logger';
import { NyStatModule } from './modules/ny-stat';
import { AdultstModule } from './modules/adults';
import { BannersModule } from './modules/banners';
import { CatalogModule } from './modules/catalog';
import { DeliveryModule } from './modules/delivery';
import { eventBus } from '@/utils/plugins/event-bus';
import { ComponentModule } from './modules/component';
import { FavoritesModule } from './modules/favorites';
import { CashbacksModule } from './modules/cashbacks';
import { SamplingsModule } from './modules/samplings';
import { NyContestModule } from './modules/ny-contest';
import { ProductDBModule } from './modules/product-db';
import { PaidOrderModule } from './modules/paid-order';
import { PromocodesModule } from './modules/promocodes';
import { HeavyOrderModule } from './modules/heavy-order';
import { QuestionnaireModule } from './modules/questionnaire';
import { OrderProblemsModule } from './modules/order-problems';
import { ProductReviewModule } from './modules/product-review';
import { CategoryNpsModule } from '@/store/modules/category-nps';
import { ShoppingHistoryModule } from './modules/shopping-history';
import { PopularProductsModule } from './modules/popular-products';

Vue.use(Vuex);

function createStoreOptions() {
  return {
    strict: true,
    modules: {
      shoppingHistory: ShoppingHistoryModule,
      orderProblems: OrderProblemsModule,
      productReview: ProductReviewModule,
      questionnaire: QuestionnaireModule,
      categoryNps: CategoryNpsModule,
      productDB: ProductDBModule,
      heavyOrder: HeavyOrderModule,
      promocodes: PromocodesModule,
      samplings: SamplingsModule,
      component: ComponentModule,
      favorites: FavoritesModule,
      paidOrder: PaidOrderModule,
      cashbacks: CashbacksModule,
      nyContest: NyContestModule,
      delivery: DeliveryModule,
      banners: BannersModule,
      catalog: CatalogModule,
      adults: AdultstModule,
      logger: LoggerModule,
      abtest: ABTestModule,
      orders: OrdersModule,
      nyStat: NyStatModule,
      user: UserModule,
      cart: CartModule,
      popularProducts: PopularProductsModule,
      loyalty: LoyaltyModule,
    },
    state: {
      isAuthorized: false,
      isInitializing: false,
      initPromise: null,
      isDesktop: false,
      isWebView: false,
      /** нужен для запуска общего лоудера апки при инициализаций апки при переходе через диплинк в protected роут */
      appLoader: false,
      appInitLoader: true,

      currentCityId: DEFAULT_CITY.id,

      isMainCatalogInited: false,
    },
    getters: {
      isAuthorized(state) {
        return state.isAuthorized;
      },
      isWebView(state) {
        return state.isWebView;
      },
      isDesktop(state) {
        return state.isDesktop;
      },
      appLoader(state) {
        return state.appLoader;
      },
      appInitLoader(state) {
        return state.appInitLoader;
      },
      currentCityId(state) {
        return state.currentCityId;
      },
      showNewMainPageAndCatalog() {
        return RahmetApp.isWebView();
      },
    },
    mutations: {
      SET_CURRENT_CITY_ID(state, value) {
        state.currentCityId = value;
      },
      SET_IS_WEB_VIEW(state, value) {
        state.isWebView = value;
      },
      SET_AUTH_STATE(state, value) {
        state.isAuthorized = value;
      },
      SET_INITIALIZING_STATE(state, { isInitializing, initPromise }) {
        state.isInitializing = isInitializing;
        state.initPromise = initPromise;
      },
      SET_IS_DESKTOP(state, value) {
        state.isDesktop = value;
      },
      SET_APP_LOADER(state, value) {
        state.appLoader = value;
      },
      SET_APP_INIT_LOADER(state, value) {
        state.appInitLoader = value;
      },
      SET_MAIN_CATALOG_INITED(state) {
        state.isMainCatalogInited = true;
      },
    },
    actions: {
      INIT_AUTHORIZED_ASYNC_CONTENT(ctx) {
        if (ctx.state.isAuthorized) {
          ctx.dispatch('favorites/FETCH_FAVORITES_IDS');
          CategoryNpsService.loadSurveys();
        }
      },
      INIT_MAIN_CATALOG(ctx, isMain = false) {
        const { isMainCatalogInited } = ctx.state;
        $log('INIT_MAIN_CATALOG', { isMainCatalogInited });

        // при реконектах не будет срабатывать
        if (isMainCatalogInited) return Promise.resolve();

        const promises = [
          ctx.dispatch('catalog/FETCH_MAIN_CATEGORIES'),
          BannersService.init(),
          SaleCategoryService.init(),
          PopularProductsService.init(),
        ];

        const loadMainCatalog = (isAsync = false) => {
          $log('INIT_MAIN_CATALOG loadMainCatalog isAsync', { isAsync });
          return Promise.all(promises).then(() => {
            $log('INIT_MAIN_CATALOG loadMainCatalog ended');

            ctx.commit('SET_MAIN_CATALOG_INITED');

            ctx.dispatch('INIT_AUTHORIZED_ASYNC_CONTENT');
          });
        };

        // если это главная то будем дожидаться ответа запросов при глобальном лоудинге
        if (isMain) return loadMainCatalog();

        // если это не главная то будем асинхронно грузить
        return new Promise(resolve => {
          loadMainCatalog(true);
          return resolve();
        });
      },
      /**
       * @param {Store} ctx
       * @param {*} params
       *
       * forceLoadNearestAddress: Boolean,
       *    если true, не запускать процесс поиска ближайшего адреса,
       *    оставить текущий загруженный.
       *    Нужно, чтобы процесс поиска запускался только при первичной загрузке, вроде)
       *    Нужно вспомнить зачем нужно, вроде были проблемы на странице заказа
       *
       * isMain: Boolean // если загрузка идет на главной или в каталоге,
       *    делать запросы на INIT_MAIN_CATALOG синхронно,
       *    если нет то асинхронно
       *    смотреть .src/services/main/lib/is-main-pages.js
       * @returns {Promise}
       */
      INIT_MAIN_FLOW(ctx, params) {
        const forceLoadNearestAddress =
          params?.forceLoadNearestAddress || false;
        const isMain = params?.isMain || false;
        $log('INIT_MAIN_FLOW', { isMain, forceLoadNearestAddress });

        const initPromise = UserService.loadUserProfile()
          .then(() => AddressService.initAddresses(forceLoadNearestAddress))
          .then(() => ABTestService.init())
          .then(() => ctx.dispatch('delivery/FIND_WAREHOUSE'))
          .then(() => ctx.dispatch('cart/FETCH_CART'))
          .then(() => ctx.dispatch('INIT_MAIN_CATALOG', isMain))
          .then(() => $log('INIT_MAIN_FLOW ended'));

        ctx.commit('SET_INITIALIZING_STATE', {
          isInitializing: true,
          initPromise,
        });

        return initPromise.finally(() => {
          $log('INIT_MAIN_FLOW finally');
          ctx.commit('SET_INITIALIZING_STATE', {
            isInitializing: false,
            initPromise: null,
          });
        });
      },
      UPDATE_ORDER_CONTEXT(ctx) {
        return ctx
          .dispatch('delivery/FIND_WAREHOUSE')
          .catch(err => {
            ctx.commit('cart/RESET_CART');
            DeliveryConfigService.updateIsPaymentConfigShown();
            return Promise.reject(err);
          })
          .finally(() => {
            ctx.dispatch('cart/FETCH_CART').then(() => {
              eventBus.emit('eb_on_cart_fetched_after_address_changed');
            });
          });
      },
    },
  };
}

export const store = new Vuex.Store(createStoreOptions());
export { createStoreOptions };
