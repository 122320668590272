import { INITIAL_COORDS } from '../constants';

/**
 * Добавляет в DOM невидимую карту для yandex api
 */
function createHiddenMapElement() {
  if (document.getElementById('hiddenMap')) return;
  const map = document.createElement('div');
  map.className = 'hidden hidden w-full h-screen';
  map.id = 'hiddenMap';
  document.body.appendChild(map);
}

// function isInPolygons(coords) {}
function createMapPolygon(ymaps, map, polygonCoords) {
  let mapPolygons = polygonCoords.map(c => new ymaps.geometry.Polygon(c));
  mapPolygons.forEach(mp => {
    mp.options.setParent(map.options);
    mp.setMap(map);
  });
  return mapPolygons;
}

function hasCoordsInPolygon(Polygons, coords) {
  return Polygons.some(elem => elem.contains(coords));
}

function isCoordsInDeliveryZone(ymaps, coords, polygonCoords) {
  createHiddenMapElement();
  const hiddenMap = new ymaps.Map('hiddenMap', {
    center: INITIAL_COORDS,
    zoom: 7,
  });
  const polygons = createMapPolygon(ymaps, hiddenMap, polygonCoords);
  let result = hasCoordsInPolygon(polygons, coords);
  hiddenMap.destroy();
  return result;
}

/**
 *
 * @param {*} ymaps window.ymaps
 * @param {*} coords координаты которые нужно проверить входят ли они в зону или нет
 * @param {*} regularPolygons это полигоны обычной доставки {type: regular, coordinates: [[],...]}
 * @param {*} extendedPolygons это полигоны дальней доставки {type: regular, coordinates: [[],...]}
 * @returns
 */
export function getDeliveryZoneValidationsOfCoords(
  ymaps,
  coords,
  regularPolygons,
  extendedPolygons
) {
  let regularPolygonCoords = regularPolygons.map(p => p.coordinates) || [];
  let extendedPolygonCoords = extendedPolygons.map(p => p.coordinates) || [];

  let isInTerritory = isCoordsInDeliveryZone(
    ymaps,
    coords,
    regularPolygonCoords
  );
  let isInExtended = isCoordsInDeliveryZone(
    ymaps,
    coords,
    extendedPolygonCoords
  );

  return {
    isInTerritory,
    isInExtended,
  };
}
