<template>
  <RenderlessAddressContext
    v-slot="{ timeTitle, addressTitle, deliveryTime, showDistantIcon }"
  >
    <div class="DeskAddressContext" @click="$emit('onClick')">
      <IconLocation class="w-6 h-6 mt-1" />
      <div class="ml-2">
        <div class="DeskAddressContext__info">
          <p class="DeskAddressContext__time">
            {{ timeTitle }}
          </p>
          <ExtendedDeliveryTooltip
            v-if="showDistantIcon"
            class="DeskAddressContext__distant"
            :time="deliveryTime"
            @click.native.stop
          />
        </div>
        <div class="DeskAddressContext__address">
          {{ addressTitle }}
        </div>
      </div>
    </div>
  </RenderlessAddressContext>
</template>

<script>
import RenderlessAddressContext from './RenderlessAddressContext';
import IconLocation from '@shared/components/icons/IconLocation.vue';
export default {
  components: {
    RenderlessAddressContext,
    IconLocation,
    ExtendedDeliveryTooltip: () =>
      import('@components/extended-delivery/ExtendedDeliveryTooltip.vue'),
  },
};
</script>

<style scoped>
.DeskAddressContext {
  @apply w-full
    pt-1.5 pb-2 px-3
    border border-sirius-orange-100 rounded-2xl
    flex items-start cursor-pointer;

  min-width: 160px;
}
.DeskAddressContext__info {
  @apply flex items-center;
}
.DeskAddressContext__time {
  @apply whitespace-nowrap
  text-sirius-orange-100
  font-bold text-sm leading-4;
}
.DeskAddressContext__distant {
  @apply flex items-center justify-center h-5 w-7;
}
.DeskAddressContext__address {
  @apply mt-0.5 line-clamp-1 text-lg leading-5;
}
</style>
