import { store } from '@/store';

/**
 * Аналитики хотят собрать инфу о том, какое предположительное время
 * видел пользователь перед заказом и за сколько по факту привезли.
 * Для этого добавляем эту инфу в /lavka/v2/preorder
 *
 * @see https://swaggerui2.chocodev.kz/?url=https%3A%2F%2Fcourier.chocodev.kz&urls.primaryName=lavka#/
 * @return {{estimated_time_range_to: number, estimated_time_source_type: string, estimated_time_range_from: number}}
 */
export function getDeliveryTimePrediction() {
  const tmpArr = store.getters['delivery/expectedDeliveryTime']?.split(' - ');
  const from = parseInt(tmpArr[0]);
  const to = parseInt(tmpArr[1]) || null;
  return {
    estimated_time_range_from: from,
    estimated_time_range_to: to,
    estimated_time_source_type: getType(),
    surge_value: store.getters['delivery/originalMinAmount'] || 0,
  };
}

/**
 * Временный список типов: 'custom' | 'distant' | 'surge' | 'regular'
 * @returns {string} 'default' | 'default_almaty' | 'default_astana' | 'regular'
 */
function getType() {
  if (store.getters['delivery/sourceType']) {
    return store.getters['delivery/sourceType'];
  }
  if (store.getters['delivery/warehouse']?.expected_delivery_time !== '0') {
    return 'custom';
  }
  if (store.getters['paidOrder/isDistant']) {
    return 'distant';
  }
  if (store.getters['delivery/originalMinAmount']) {
    return 'surge';
  }
  return 'regular';
}
