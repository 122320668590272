<template>
  <div class="">
    <!-- TODO: minicart разделить  убрать лишнюю длогику для корзины в каталоге -->
    <div v-if="cartSize > 0">
      <div
        class="flex flex-col"
        :class="{ 'md:mt-8 md:mb-0 md:flex-row': !isMini }"
      >
        <div class="" :class="isMini ? '' : 'md:w-1/2'">
          <div v-if="$isDesktop" class="px-4 flex items-center justify-between">
            <p v-if="!isMini" class="font-bold text-32 leading-10">
              {{ $t('cart') }}
            </p>
            <div
              class="ml-auto flex items-center text-sm font-medium text-sirius-gray-700 cursor-pointer"
              @click="clearCart"
            >
              {{ $t('clearCart') }}
              <IconTrashbin class="text-sirius-gray-700 ml-2" />
            </div>
          </div>

          <div v-show="isPaidOrder && !isMini" class="px-4 mt-5">
            <PaidDeliveryWrapper ref="RefPaidDelivery" view="cart" />
          </div>

          <LostGoodsReminder
            class="mx-4 mt-3"
            :class="`${isMini ? 'md:mx-0' : ''}`"
          />

          <section class="bg-white mt-4">
            <div v-for="(item, index) in cartProducts" :key="uniqueKey(item)">
              <HorizontalProductCardWrapper
                :class="`${isMini ? 'mb-3' : ''}`"
                :product="item"
                cart
              />

              <div
                v-if="!$isDesktop && cartProducts.length !== index + 1"
                class="px-4 w-full rounded-full border-1 border-sirius-gray-400"
              />
            </div>

            <div
              v-for="(item, index) in sampleProducts"
              :key="`sample_${item.id}_${index}`"
              :class="`${!isMini ? 'px-4' : ''}`"
            >
              <SamplingProductWrapper class="mt-3" :product="item" />
            </div>
          </section>
          <LostGoods class="mt-3" />
        </div>

        <div :class="`${isMini ? '' : 'md:w-1/2'}`">
          <AppliancesFeat
            v-if="showAppliancesFeat"
            class="px-4 mt-6"
            @isToggled="setAppliancesToggleState"
          />
          <AdditionalFeatures
            v-if="!isMini"
            class="px-4"
            :class="`${!showAppliancesFeat ? 'mt-6' : ''}`"
          />
          <UiTextarea
            v-if="!isMini"
            v-model="comment"
            :label="$t('commentToOrder')"
            class="mt-7 mx-4"
            :row="3"
            no-resize
            @onInput="onCommentUpdated"
          />
          <ShoppingHistory v-if="!$isDesktop && isAuthorized" />

          <section class="mt-5">
            <CheckoutInfoList
              v-if="!isMini"
              class="mt-auto p-4 bg-white"
              :service-fee="serviceFee"
              :disabled="isDisabled"
              :disable-time="disableDeliveryTime"
              :discount="discountInfo"
              :delivery="deliveryInfo"
              :delivery-time="deliveryTimeInfo"
              :is-delivery-distant="isDistant"
              :products-price="productsPriceInfo"
              @onConditionsClick="onConditionsClick"
            />

            <div class="mt-6" :class="!isMini ? 'px-4' : ''">
              <!-- Перейти к оплате -->
              <UiButton
                size="lg"
                :disabled="isDisabled"
                class="w-full font-bold text-lg md:px-5 relative"
                @click="validatePayment"
              >
                <template v-if="isMini">
                  <span class="mr-auto">
                    {{ $t('goToCart') }}
                  </span>
                  <span class="ml-auto">
                    {{ formatPrice(totalPriceForCheckout) }} ₸
                  </span>
                </template>
                <template v-else-if="!minAmountReminder">
                  <span class="mr-auto">
                    {{ $t('goToPayment') }}
                  </span>
                  <span class="ml-auto">
                    {{ formatPrice(totalPriceForCheckout) }} ₸
                  </span>
                </template>
                <template v-else>
                  {{
                    $t('notEnoughTillMin', {
                      amount: formatPrice(minAmountReminder),
                    })
                  }}
                </template>
                <LoyaltyBadge
                  v-if="$store.getters['loyalty/available']"
                  :count="loyaltyCartCount"
                  class="loyalty-badge"
                />
              </UiButton>

              <CashbacksReminderWrapper
                v-if="hasCashbacksProgram"
                class="mt-5"
              />
              <MinAmountReminder
                v-if="isMini && minAmountReminder"
                class="mt-3"
              />
            </div>
          </section>
        </div>
      </div>

      <ShoppingHistory v-if="$isDesktop && !isMini && isAuthorized" />
    </div>

    <UiFullScreenLoader v-if="isProcessing" />

    <SuccessPaymentModal
      v-model="showSuccessPaymentModal"
      :time="expectedDeliveryTime"
      @onClose="onSuccessPaymentClose"
    />
    <NotificationReminderModal
      ref="NotificationReminderModal"
      @onClose="onSuccessPaymentClose"
    />
    <AddressConfirmPopup
      @change="onAddressConfirmChange"
      @confirm="onAddressConfirmDone"
    />
    <PromocodeAppliedModal name="promocode-applied-modal" />
    <ExtendedTimeModal
      :show="extendedTimeModal"
      @change="extendedTimeModal = $event"
    />
    <ServiceFeeModal />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import {
  EVENTS,
  Analitycs,
  SEGMENT_EVENTS,
  APPSFLYER_EVENTS,
} from '@shared/services/analitycs';
import {
  SERVICE_FEE_EVENTS,
  serviceFeeEmitter,
} from '@services/service-fee/emitter';
import { getDeliveryTimePrediction } from '@/lib/get-delivery-time-prediction';
import { wordDeclination } from '@shared/utils/delivery/word-declination';
import { orderEmitter, ORDER_EVENTS } from '@services/orders/events';
import { CartEmitter, CART_EVENTS } from '@services/cart/events';
import { eventBus, BUS_EVENTS } from '@shared/utils/event-bus';
import { NotificationService } from '@services/notifications';
import { formatPrice } from '@shared/utils/formatters/price';
import { isApplianceCategory } from '@services/appliances';
import { ServiceFeeService } from '@services/service-fee';
import { ERR_NO_DELIVERY } from '@shared/config/delivery';
import { createStorage } from '@shared/services/storage';
import { ClientService } from '@shared/services/client';
import { Logger as LOG } from '@shared/services/logger';
import { AuthService } from '@shared/services/auth';
import { PaymentService } from '@services/payment';
import { AdultsService } from '@services/adults';
import { RahmetApp } from '@shared/RahmetApp';
import { UserService } from '@services/user';
import { api } from '@shared/services/api';
import { v4 as uuidv4 } from 'uuid';

import UiFullScreenLoader from '@shared/components/ui/UiFullScreenLoader.vue';
import IconTrashbin from '@shared/components/icons/IconTrashbin.vue';
import UiTextarea from '@pure-ui/components/UiTextArea/UiTextArea.vue';
import UiButton from '@ui/UiButtonNew.vue';

import ExtendedTimeModal from '@components/extended-delivery/ExtendedTimeModal.vue';
import MinAmountReminder from '@shared/components/checkout/MinAmountReminder.vue';
import NotificationReminderModal from '@components/notification-reminder/NotificationReminderModal.vue';
import ShoppingHistory from '@shared/components/shopping-history/ShoppingHistory.vue';
import CashbacksReminderWrapper from '@components/cashbacks/CashbacksReminderWrapper.vue';
import SamplingProductWrapper from '@components/samplings/SamplingProductWrapper.vue';

import PromocodeAppliedModal from './PromocodeAppliedModal.vue';
import AddressConfirmPopup from './AddressConfirmPopup.vue';
import AdditionalFeatures from './AdditionalFeatures.vue';
import LostGoodsReminder from './LostGoodsReminder.vue';
import CheckoutInfoList from './checkout/CheckoutInfoList.vue';
import AppliancesFeat from './AppliancesFeat.vue';
import LostGoods from './LostGoods.vue';
import LoyaltyBadge from './LoyaltyBadge.vue';
import debounce from 'debounce';
import HorizontalProductCardWrapper from '@shared/components/product/HorizontalProductCardWrapper.vue';

export default {
  name: 'MiniCart',
  components: {
    ServiceFeeModal: () =>
      import('@components/service-fee/ServiceFeeModal.vue'),
    PaidDeliveryWrapper: () =>
      import('@components/paid-delivery/PaidDeliveryWrapper.vue'),
    SuccessPaymentModal: () =>
      import('@components/orders/success-payment/SuccessPaymentModal.vue'),
    NotificationReminderModal,
    CashbacksReminderWrapper,
    SamplingProductWrapper,
    PromocodeAppliedModal,
    AddressConfirmPopup,
    AdditionalFeatures,
    UiFullScreenLoader,
    LostGoodsReminder,
    ExtendedTimeModal,
    MinAmountReminder,
    CheckoutInfoList,
    ShoppingHistory,
    AppliancesFeat,
    IconTrashbin,
    UiTextarea,
    LostGoods,
    UiButton,
    HorizontalProductCardWrapper,
    LoyaltyBadge,
  },
  inject: ['popup', 'toast', 'bottomSheetPopup'],
  beforeRouteEnter(to, from, next) {
    Analitycs.logSegmentEvent(SEGMENT_EVENTS.CLICK_CART, {
      screenName: from.name ?? '',
    });
    next();
  },
  props: {
    isMini: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      order: undefined,
      preorderId: 0,
      comment: '',
      isAppliancesToggled: false,
      isProcessing: false,

      isAddressConfirmed: false,

      showSuccessPaymentModal: false,
      extendedTimeModal: false,
    };
  },
  computed: {
    ...mapState('user', ['deliveryAddress']),
    ...mapState('cart', ['cart']),
    ...mapState('delivery', ['warehouse']),
    ...mapState(['isInitializing']),

    ...mapGetters('cashbacks', ['hasCashbacksProgram']),
    ...mapGetters('heavyOrder', ['maxWeight']),
    ...mapGetters('user', ['userOrdersCount']),
    ...mapGetters('abtest', ['testGroups']),
    ...mapGetters(['isAuthorized']),
    ...mapGetters('paidOrder', [
      'activePaymentConfig',
      'serviceFeePrice',
      'paymentConfigs',
      'isHighPrice',
      'isDistant',
    ]),
    ...mapGetters('samplings', ['sampleProducts', 'samplingTotalPrice']),
    ...mapGetters('delivery', [
      'expectedDeliveryTime',
      'isWarehouseOpen',
      'warehouseId',
      'warehouse',
      'minCheck',
    ]),
    ...mapGetters('cart', [
      'minAmountReminder',
      'cartNoItemsSize',
      'cartWeight',
      'cartSize',
      'items',
    ]),
    isPaidOrder() {
      return this.paymentConfigs || this.minCheck;
    },
    paymentConfig() {
      if (!this.paymentConfigs) return null;
      return this.activePaymentConfig;
    },
    deliveryTimeInfo() {
      const minutes = wordDeclination(this.expectedDeliveryTime.split('-')[1], [
        'минуту',
        'минуты',
        'минут',
      ]);
      return `${this.expectedDeliveryTime} ${minutes}`;
    },
    discountInfo() {
      return this.cart.discount;
    },
    productsPriceInfo() {
      return `${formatPrice(this.cart.totalPrice + this.samplingTotalPrice)} ₸`;
    },
    deliveryInfo() {
      const maxPrice = this.paymentConfigs
        ? this.paymentConfigs.reduce(
            (price, el) =>
              el.delivery_price >= price ? el.delivery_price : price,
            0
          )
        : 0;
      let price = 0;
      let from = 0;
      let to = 0;

      const config = this.paymentConfig;
      if (config) {
        price = config.delivery_price;
        from = maxPrice;
        to = 0;
      }

      return {
        price,
        from,
        to,
      };
    },
    canProcessOrder() {
      return !this.minAmountReminder;
    },
    totalPriceForCheckout() {
      const deliveryPrice = this.$store.getters[
        'cart/isFreeDeliveryPromocodeApplied'
      ]
        ? 0
        : (this.paymentConfig && this.paymentConfig.delivery_price) || 0;
      const serviceFee = this.serviceFeePrice ?? 0;

      return (
        this.cart.totalPriceWithDiscount +
        deliveryPrice +
        serviceFee +
        this.samplingTotalPrice
      );
    },
    showAppliancesFeat() {
      const appliancesFrom =
        typeof localStorage !== 'undefined'
          ? localStorage.getItem('appliancesFrom') || 'appliancesFeat'
          : 'appliancesFeat';

      let hasAppliances = false;
      let hasMeal = false;

      this.items.forEach(item => {
        if (
          isApplianceCategory(item.analytical_category_id) ||
          isApplianceCategory(item.parent_id)
        ) {
          hasMeal = true;
        }
        if (item.product_id === 11700) {
          hasAppliances = true;
        }
        return item;
      });

      if (hasAppliances && appliancesFrom !== 'appliancesFeat') {
        return false;
      }
      if ((hasAppliances && appliancesFrom === 'appliancesFeat') || hasMeal) {
        return true;
      }

      return false;
    },
    isQuestionnaire() {
      /* eslint-disable camelcase */
      // const isShown = window.localStorage.getItem('questionnaire-shown');
      // const id = this.userProfile?.user_id;
      // return id && questionnaireUsers.includes(id) && !isShown && this.$isWebView;
      return false;
      /* eslint-disable camelcase */
    },
    isDisabled() {
      return this.isMini ? false : !this.canProcessOrder;
    },
    serviceFee() {
      if (this.serviceFeePrice === null) return null;

      // ServiceFeeService.onUserShowed();

      return {
        price: this.serviceFeePrice,
        // isFirstTime: !ServiceFeeService.isShownBefore(),
      };
    },
    cartProducts() {
      return this.items.filter(
        p => !(this.showAppliancesFeat && p.product_id === 11700)
      );
    },
    loyaltyCartCount() {
      return Math.round(this.totalPriceForCheckout / 100);
    },
    disableDeliveryTime() {
      return this.warehouse && !this.isWarehouseOpen;
    },
  },
  mounted() {
    this.$store.dispatch('delivery/FETCH_EXPECTED_DELIVERY_TIME');
    this.comment = this.$store.getters['cart/comment'];
    window.localStorage.removeItem('analytics-promocode');
    if (!this.isMini) Analitycs.logEvent(EVENTS.VIEWED_CART);
    if (!this.isInitializing && !this.isMini) {
      this.fetchCart().catch(() => {});
    }
    eventBus.$on(BUS_EVENTS.LOCALE_CHANGE, this.onLocaleChange);
  },
  beforeDestroy() {
    eventBus.$off(BUS_EVENTS.LOCALE_CHANGE);
  },
  methods: {
    formatPrice,
    ...mapActions('cart', {
      fetchCart: 'FETCH_CART',
      validateState: 'VALIDATE_STATE',
      clearLocalCart: 'CLEAR_LOCAL_CART',
      removeUnavailable: 'REMOVE_UNAVAILABLE',
    }),
    openServiceFeeModal() {
      ServiceFeeService.openModal();
    },
    openDeliveryModal() {
      this.$eventBus.emit('eb_open_paid_delivery_conditions_modal', {
        clickedFrom: 'basket-lower-block',
        clickedOn: 'conditions',
        blockType: 'conditions',
      });
    },
    openTimeModal() {
      this.extendedTimeModal = true;
      Analitycs.logEvent(EVENTS.DISTANT_CLICK_CONDITIONS_TIME);
    },
    onConditionsClick(value) {
      if (value === 'time') this.openTimeModal();
      else if (value === 'delivery') this.openDeliveryModal();
      else if (value === 'serviceFee') this.openServiceFeeModal();
      else if (value === 'nyContest') {
        this.bottomSheetPopup.show('ny-conditions-modal');
        Analitycs.logEvent(EVENTS.NY_CONTEST_2023_MODAL_OPENED, {
          from: 'cart',
        });
      }
    },
    setAppliancesToggleState(value) {
      this.isAppliancesToggled = value;
    },
    goToMain() {
      this.$router.push({ name: 'main' });
    },
    validatePayment(payload) {
      LOG.event('validatePayment');
      if (this.isMini) {
        this.$router.push({ name: 'cart' });
        this.isProcessing = false;
        return;
      }

      if (!this.canProcessOrder) {
        this.toast.show(
          this.$t('minCheckError', {
            price: this.minCheck,
          })
        );
        this.isProcessing = false;
        return;
      }
      this.validateState()
        .then(() => {
          this.onPay(payload);
        })
        .catch(err => {
          console.error(err);
          this.isProcessing = false;
          if (err.message === 'NO_ADDRESS') {
            this.$eventBus.emit('eb_open_create_address_reminder', {
              from: 'on_payment',
            });
          } else if (err.message === ERR_NO_DELIVERY) {
            this.$eventBus.emit('eb_open_no_delivery_modal');
          } else if (err.message.startsWith('Invalid uuid')) {
            this.toast.show(err.message, 'error');
            throw err;
          } else {
            this.toast.show(err.message, 'error');
          }
        });
    },
    reAuthorize() {
      LOG.event('MiniCart reAuthorize');
      return AuthService.authorize()
        .then(() => {
          LOG.event('MiniCart AuthService.authorize()');
          return this.$store.dispatch('INIT_MAIN_FLOW');
        })
        .then(() => {
          LOG.event('MiniCart INIT_MAIN_FLOW');
          return this.validatePayment();
        })
        .catch(err => {
          if (err.message === 'DefaultReject') {
            this.isProcessing = false;
            return;
          }
          LOG.event('MiniCart auth error', err);
          const message = this.$t('canNotAuthorize');
          if (err.message !== 'NO_DELIVERY') this.toast.show(message, 'error');
        });
    },
    onPay() {
      LOG.event('onPay', { cartNoItemsSize: this.cartNoItemsSize });

      if (this.cartNoItemsSize !== 0) {
        this.openLostGoodsModal();
        this.isProcessing = false;
        return;
      }

      // Heavy-Order
      if (this.cartWeight > this.maxWeight) {
        this.$eventBus.emit('eb_open_full_cart_modal', 'cart');
        this.isProcessing = false;
        return;
      }
      // Heavy-Order

      // warehouse open or no
      if (this.warehouse && !this.isWarehouseOpen) {
        this.$eventBus.emit('eb_open_time_modal', { from: 'pay' });
        this.isProcessing = false;
        return;
      }
      // warehouse open or no

      if (!this.isAddressConfirmed && !this.$isDesktop) {
        this.popup.show('address-confirm-popup');
        this.isProcessing = false;
        return;
      }

      const storage = createStorage(window.localStorage);
      const lessPackages = storage.parse('additionalFeat::lessPackages');
      const contactlessDelivery = storage.parse(
        'additionalFeat::contactlessDelivery'
      );

      Analitycs.logEvent(EVENTS.PAYMENT_BASKET, {
        lessPackages,
        contactlessDelivery,
        isAppliances: this.isAppliancesToggled && this.showAppliancesFeat,
      });
      Analitycs.logSegmentEvent(SEGMENT_EVENTS.CLICK_CHECKOUT);
      this.$gtm.trackEvent({
        event: 'initiateCheckout',
        cartTotal: this.totalPriceForCheckout,
      });
      this.isProcessing = true;
      if (this.isAuthorized) {
        LOG.event('onAuthorized');
        this.processOrder();
        return;
      }

      this.reAuthorize();
    },
    clearCart() {
      Analitycs.logEvent(EVENTS.FLUSHED_CART);
      this.$store.dispatch('cart/CLEAR_CART').catch(err => {
        this.toast.show(err.message);
      });
    },
    uniqueKey(product) {
      return `${product.id}_${product.category_id || product.feature_id}`;
    },
    openNotificationModal() {
      if (this.$refs.NotificationReminderModal) {
        this.$refs.NotificationReminderModal.openModal();
      }
    },
    openSuccessModal() {
      this.showSuccessPaymentModal = true;
    },
    closeSuccessModal() {
      this.showSuccessPaymentModal = false;
    },
    onSuccessPaymentClose(name) {
      if (this.isQuestionnaire) {
        window.localStorage.setItem('questionnaire-shown', true);
      }

      this.closeSuccessModal();

      this.$store.dispatch('cart/SET_UNPAID_ORDER', false);
      this.clearLocalCart();
      UserService.loadUserProfile();
      if (!name) name = 'main';
      this.$router.push({ name });

      orderEmitter.emit(ORDER_EVENTS.OPEN_LAST_ADDED_ACTIVE_ORDER);
    },
    async onSuccessPayment() {
      const canShowNots = await NotificationService.canShowModal();
      if (canShowNots) {
        this.openNotificationModal();
      } else {
        this.openSuccessModal();
      }

      Analitycs.logEvent(EVENTS.DONE_PAYMENT, {
        preorderId: this.preorderId,
        cartWeight: this.cartWeight,
        promocode: this.$store.getters['cart/promocode'],
        samples: this.$store.getters['samplings/samples'],
        samplingTotalPrice: this.$store.getters['samplings/samplingTotalPrice'],
        estimated_delivery_time: getDeliveryTimePrediction(),
      });
      Analitycs.logAppsflyerEvent(APPSFLYER_EVENTS.DOM_DONE_PAYMENT, {
        dom_preorder_id: this.preorderId,
        content_ids: this.items.map(p => `${p.id}`),
        value: this.totalPriceForCheckout,
        currency: 'KZT',
        content_type: 'product',
      });
      if (this.userOrdersCount === 0) {
        Analitycs.logAppsflyerEvent(APPSFLYER_EVENTS.DOM_FIRST_DONE_PAYMENT, {
          dom_preorder_id: this.preorderId,

          content_ids: this.items.map(p => `${p.id}`),
          value: this.totalPriceForCheckout,
          currency: 'KZT',
          content_type: 'product',
        });
      }

      CartEmitter.emit(CART_EVENTS.ON_PAYMENT_DONE, {
        preorderId: this.preorderId,
        cartWeight: this.cartWeight,
        promocode: this.$store.getters['cart/promocode'],
      });

      serviceFeeEmitter.emit(SERVICE_FEE_EVENTS.ON_CURRENT_ORDER_SESSION_ENDED);

      this.$store.dispatch('cart/SET_UNPAID_ORDER', false);
    },
    validate() {
      if (this.deliveryAddress === null) {
        return {
          error: 'Выберите адрес доставки',
        };
      }
      if (this.warehouse === null) {
        return {
          error: 'Выбранный адрес не входит в зону доставки. Измените адрес',
        };
      }
      if (this.items.filter(e => e.analytical_category_id === 159).length > 1) {
        return {
          error:
            'На товары с большими бутылками воды действует ограничение по весу, можно добавить только 1 товар в заказ',
        };
      }
      return { error: '' };
    },
    buildPayload() {
      const { deviceId } = ClientService.getContext();
      const storage = createStorage(window.localStorage);
      const lessPackages = storage.parse('additionalFeat::lessPackages');
      const contactlessDelivery = storage.parse(
        'additionalFeat::contactlessDelivery',
        false
      );
      const hasAdultProduct = [...this.items].some(p =>
        AdultsService.checkIsAdultProduct(p)
      );
      const iin = storage.get('additionalFeat::iin');
      const iinToggle = storage.parse('additionalFeat::iin-toggle');
      let userIIN = null;
      if (iinToggle && iin) {
        userIIN = iin.replace(/ /g, '').replace(/•/g, '');
      }

      const promocode = this.$store.getters['cart/promocode'];

      const payload = {
        idempotency_key: uuidv4(),
        payment_method_id: 1,
        warehouse_id: this.warehouseId,
        device_id: deviceId,
        user_iin: userIIN,
        comment: this.comment,
        ab_groups: this.testGroups,
        high_delivery_cost: this.isHighPrice ? 1 : 0,

        leave_at_the_door: contactlessDelivery && !hasAdultProduct ? 1 : 0,
        less_packages: lessPackages ? 1 : 0,
        ...(promocode && { promocode }),

        street_name: this.deliveryAddress.street_name,
        address_id: this.deliveryAddress.id,
        building: this.deliveryAddress.building,
        domofon_code: this.deliveryAddress.domofon_code,
        address_comment: this.deliveryAddress.comment,
        flat_number: this.deliveryAddress.flat_number,
        entrance: this.deliveryAddress.entrance,
        street: this.deliveryAddress.street,
        floor: this.deliveryAddress.floor,
        long: this.deliveryAddress.long,
        lat: this.deliveryAddress.lat,
        ...getDeliveryTimePrediction(),
      };

      if (this.isPaidOrder) {
        payload.delivery_price = 0;
        if (this.paymentConfig && this.paymentConfig.delivery_price > 0) {
          payload.delivery_price = this.paymentConfig.delivery_price;
        }
      }
      return payload;
    },
    createWebPayload() {
      const successUrl = this.$router.resolve({
        name: 'preorder',
        params: { id: this.preorderId },
        query: {
          successMessage: true,
          afterPayment: true,
          firstPayment: this.userOrdersCount === 0,
        },
      }).href;
      const failureUrl = this.$router.resolve({
        name: 'cart',
        params: {},
        query: { afterPayment: true },
      }).href;

      return {
        backlink: this.order.payment_deeplink,
        successUrl,
        failureUrl,
      };
    },
    onSuccessOrder() {
      this.$store.dispatch('cart/SET_UNPAID_ORDER', true);
      this.$eventBus.emit('eb_on_create_preorder');
      let productIds = this.items.map(p => `${p.id}`);
      Analitycs.logEvent(EVENTS.STARTED_PAYMENT, {
        preorderId: this.preorderId,
        paymentDeeplink: this.order.payment_deeplink,
      });
      Analitycs.logAppsflyerEvent(APPSFLYER_EVENTS.DOM_STARTED_PAYMENT, {
        content_ids: productIds,
        value: this.totalPriceForCheckout,
        currency: 'KZT',
        content_type: 'product',
      });
      this.$gtm.trackEvent({
        event: 'purchase',
        cartTotal: this.totalPriceForCheckout,
        transactionId: this.preorderId,
        items: productIds,
      });
      const payload = {};
      if (this.$isWebView) {
        Object.assign(payload, { deeplink: this.order.payment_deeplink });
      } else {
        Object.assign(payload, this.createWebPayload());
        window.localStorage.setItem(
          'analytics-promocode',
          this.$store.getters['cart/promocode']
        );
      }

      LOG.event('PaymentService', payload);
      return PaymentService.pay(payload)
        .then(status => {
          if (status === 'success' && this.$isWebView) {
            LOG.event('PaymentService success', payload);
            this.onSuccessPayment();
          }
          if (status === 'closed') {
            LOG.event('PaymentService payment window closed', payload);
          }
        })
        .catch(err => {
          LOG.event('PaymentService cancel', err);
          this.toast.show(err.message);
        })
        .finally((this.isProcessing = false));
    },
    createPreorder() {
      this.isProcessing = true;
      api.lavka
        .createPreorder(this.buildPayload())
        .then(({ data }) => {
          LOG.event('Preorder hash, preorderId', {
            hash: data.hash,
            preorderId: data.id,
          });

          this.order = data;
          this.preorderId = data.id;
          Analitycs.logSegmentEvent(SEGMENT_EVENTS.CLICK_PAYOUT, {
            preorderId: this.preorderId,
          });

          this.onSuccessOrder(this.preorderId);
        })
        .catch(err => {
          console.error(err);
          this.toast.show(err.message);
          this.isProcessing = false;
          RahmetApp.hapticNotification('error');
        });
    },
    processOrder() {
      LOG.event('processOrder');
      const validation = this.validate();

      if (validation.error) {
        this.toast.show(validation.error);
        this.isProcessing = false;
        return;
      }

      this.createPreorder();
    },
    onLocaleChange() {
      this.fetchCart().catch(() => {});
    },
    onAddressConfirmChange() {
      this.popup.hide('address-confirm-popup');
      this.$eventBus.emit('eb_open_address_list', {
        from: 'on_payment_address_change_confirmation',
      });
    },
    onAddressConfirmDone() {
      this.isAddressConfirmed = true;
      this.popup.hide('address-confirm-popup');
      this.validatePayment();
    },
    onCommentUpdated: debounce(function onInput(value) {
      console.log('value', value);
      this.$store.commit('cart/SET_COMMENT', value);
    }, 300),

    onPayingWithLostGoods() {
      this.removeUnavailable().then(() => this.validatePayment());
    },
    openLostGoodsModal() {
      this.$eventBus.emit('eb_open_lost_goods_modal', {
        cb: this.onPayingWithLostGoods,
      });
    },
  },
  i18n: {
    messages: {
      ru: {
        goToCart: 'К оформлению',
        cart: 'Корзина',
        notEnoughTillMin: 'До минимальной суммы {amount} ₸',
        clearCart: 'Очистить корзину',
        oneOrderMaxWeight: 'Максимальный вес одного заказа: {weight} кг',
        commentToOrder: 'Комментарий к заказу',
        productsTotal: 'Товары на сумму',
        discount: 'Скидка',
        delivery: 'Доставка',
        goToPayment: 'Перейти к оплате',
        minCheckError: 'Минимальный чек от {price} ₸',
        canNotAuthorize: 'Не получилось авторизоваться',
        promocode: 'Промокод',
      },
      kk: {
        goToCart: 'Көшу',
        cart: 'Себет',
        notEnoughTillMin: 'Минималды сомаға дейін {amount} ₸',
        clearCart: 'Себетті тазалау',
        oneOrderMaxWeight: 'Тапсырыс үшін максималды салмақ: {weight} кг',
        commentToOrder: 'Тапсырысқа комментарий',
        productsTotal: 'Сомаға өнімдер',
        discount: 'Жеңілдік',
        delivery: 'Жеткізу',
        goToPayment: 'Төлемге өту',
        minCheckError: 'Минималды сомаға дейін {price} ₸-ден',
        canNotAuthorize: 'Авторизация жасау мүмкін болмады',
        promocode: 'Промокод',
      },
    },
  },
};
</script>
