import { store } from '@/store';
import {
  rRemoveAddress,
  rFetchAddresses,
  rCreateAddresses,
  rUpdateAddresses,
  rSynchronizeAddress,
} from '@shared/services/api/apis';
import { ClientService } from '@shared/services/client';
import { createStorage } from '@shared/services/storage';
import { ADDRESS_EVENTS, AddressEmitter } from './emitter';
import { hasMatchingAddress } from './utils';
import { CityService } from '@services/city';
import { eventBus } from '@/utils/plugins/event-bus';
import { ERR_NO_DELIVERY } from '@shared/config/delivery';
import { Analitycs, EVENTS } from '@shared/services/analitycs';
import { decideToShowAddressConfirmation } from './lib/decide-to-show-address-confirmation/decide-to-show-address-confirmation';
import { getNearestAddress } from './lib/get-nearest-address';

const storage = createStorage(window.localStorage);

/**
 * @typedef Address
 * @prop {number} id
 * @prop {string} street
 * @prop {string} comment
 * @prop {string} name
 * @prop {number} lat
 * @prop {number} long
 * @prop {string} street_name
 * @prop {string} building
 * @prop {boolean} is_vip
 * @prop {number} floor
 * @prop {string} entrance
 * @prop {string} flat_number
 * @prop {string} domofon_code
 * @prop {boolean} is_private_home
 * @prop {string?} device_id
 * @prop {string?} user_id
 * @prop {number?} city_id
 * @prop {string?} created_at
 * @prop {string?} updated_at
 * @prop {number?} created_by
 * @prop {number?} updated_by
 */

/**
 * Включает лоудер при манипуляций с адресами
 */
function loadingOn() {
  store.commit('user/SET_ADDRESS_LOADING_STATE', true);
}

/**
 * Выключает лоудер при манипуляций с адресами
 */
function loadingOff() {
  store.commit('user/SET_ADDRESS_LOADING_STATE', false);
}

/**
 * Сохраняем список адресов в Store
 * @param {Address[]} addresses
 */
function setAddressesToStore(addresses) {
  if (!addresses || !Array.isArray(addresses)) addresses = [];
  store.commit('user/SET_ADDRESSES', addresses);
}

/**
 * Сохраняем адрес доставки юзера в Store
 * @param {Address} address
 * @param {string} from
 * @param {number} timeToFetchGeo время, за которое браузер получил гео локацию
 */
function setDeliveryAddressToStore(address, from, timeToFetchGeo) {
  if (address && address.city_id) {
    CityService.setCity(address.city_id);
  }
  Analitycs.logEvent(EVENTS.DELIVERY_ADDRESS_CHANGED, {
    addressId: address?.id || 'no_address',
    from,
    address: address || 'no_address',
    timeToFetchGeo,
  });
  store.commit('user/SET_DELIVERY_ADDRESS', address);
}

/**
 * Добовляем новый созданный адрес в Store
 * @param {Address} address
 */
function addAddressToStore(address) {
  store.commit('user/ADD_ADDRESS', address);
}

/**
 * Находим адрес по айди и обновляем этот адрес в списке Store
 * @param {Address} address
 */
function updateAddressInStore(address) {
  store.commit('user/UPDATE_ADDRESS', address);
}

/**
 * Находит адрес в списке Store и удаляет от-туда
 * @param {Number} addressId айди адреса
 */
function removeAddressFromStore(addressId) {
  store.commit('user/REMOVE_ADDRESS', addressId);
}

/**
 * Проверяет авторизован юзер в приложении или же нет
 * @returns {Boolean}
 */
function isAuthorized() {
  return store.getters.isAuthorized;
}

/**
 * Сохраняем адрес пользователя в локал сторадж
 * если юзер добавил ее когда был не авторизован.
 * Это для того что бы когда он авторизуется мы синхронизировали ее
 * @param {Address} address объект адреса
 */
function saveLastAddress(address) {
  if (!isAuthorized()) storage.stringify('address::lastSaved', address);
}

/**
 * Это случай, когда до авторизации пользователь
 * сохранил адрес, мы кнему подвязываем теперь юзер айди
 * @param {Address} address
 * @returns
 */
function synchronizeAddress(address) {
  loadingOn();
  const { deviceId } = ClientService.getContext();
  const body = {
    ...address,
    device_id: deviceId,
  };
  return rSynchronizeAddress(body)
    .then(res => {
      saveLastAddress(res.data);
      addAddressToStore(res.data);
    })
    .catch(() => {
      removeAddressFromStore(address.id);
      setDeliveryAddressToStore(null, 'sync_address_catch');
    })
    .finally(() => loadingOff());
}

/**
 * Получаем список всех адресов юзера
 * и сохранем в Store
 * @returns {Promise<Address[]>}
 */
function loadAddresses() {
  return rFetchAddresses(isAuthorized()).then(response => {
    setAddressesToStore(response.data);
  });
}

/**
 * Меняет основной адрес в базе и в Store
 *
 * Прогоняет по складам, Получает нужный склад
 *
 * И проверяет на то что адрес далеко или близко к юзеру
 * @param {Address} address объект адреса
 * @param {Boolean} canOffLoader нужен когда мы запускаем этот метод сразу после создание адреса или при удалений адреса, нужен для того что б не отключался лоудер
 * @returns {Promise}
 */
function changeAddress(address, canOffLoader = true, from = 'user_selection') {
  loadingOn();

  setDeliveryAddressToStore(address, from);
  AddressEmitter.emit(ADDRESS_EVENTS.ON_ADDRESS_CHANGE, address);

  return store
    .dispatch('UPDATE_ORDER_CONTEXT')
    .catch(err => {
      if (err.message === ERR_NO_DELIVERY) {
        eventBus.emit('eb_open_no_delivery_modal');
        return;
      }
      return Promise.reject(err);
    })
    .finally(() => {
      eventBus.emit('eb_on_address_change', { ...address, from });
      if (canOffLoader) loadingOff();
    });
}

/**
 * Создаем адрес юзера в базе
 *
 * Делаем ее основным
 *
 * Проверяем его склад
 * @param {Address} payload объект адреса
 * @returns {Promise}
 */
function createAddress(payload, from) {
  payload.device_id = ClientService.getContext().geviceId;

  loadingOn();
  return rCreateAddresses(payload, isAuthorized())
    .then(res => {
      const address = res.data;
      saveLastAddress(address);
      addAddressToStore(address);
      Analitycs.logEvent(EVENTS.ADDED_ADDRESS, {
        addressId: address.id,
        from,
        address,
      });
      return changeAddress(address, false, 'created_address');
    })
    .finally(() => loadingOff());
}

/**
 * Если юзер поменял активный адрес
 * запускаем перерасчет склада, потому что юзер может
 * в корне поменять адрес доставки на дургой склад
 *
 * Учли нет то просто ресолвим промис
 * @param {Address} address объект адреса
 * @returns {Promise}
 */
function updateDeliveryAddress(address) {
  const deliveryAddressId = store.getters['user/deliveryAddressId'];
  if (deliveryAddressId === Number(address.id)) {
    setDeliveryAddressToStore(address, 'edited_address');
    return store.dispatch('UPDATE_ORDER_CONTEXT').catch(err => {
      if (err.message === ERR_NO_DELIVERY) {
        eventBus.emit('eb_open_no_delivery_modal');
        return;
      }
      return Promise.reject(err);
    });
  }
  return Promise.resolve();
}

/**
 * Изменяем информацию по адресу
 *
 * Обновляем базу и Store
 *
 * Если нужно делаем перерасчет склада
 * @param {Address} payload объект адреса
 * @returns
 */
function updateAddress(payload, from) {
  loadingOn();
  return rUpdateAddresses(payload, isAuthorized())
    .then(res => {
      const address = res.data;
      Analitycs.logEvent(EVENTS.EDITED_ADDRESS, {
        addressId: address.id,
        from,
        address,
      });
      updateAddressInStore(address);
      return updateDeliveryAddress(address).then(() => {
        eventBus.emit('eb_on_address_update', address);
      });
    })
    .finally(() => loadingOff());
}

/**
 * Удаляет адрес юзера в базе и в Store
 *
 * Если это последний адрес то ставит дефолтные занчения доставке
 *
 * Если активный адрес удалился то ставит первый в списке адрес как основную
 * @param {Number} addressId айди адреса
 * @returns {Promise}
 */
function removeAddress(addressId, from) {
  loadingOn();
  return rRemoveAddress(addressId, isAuthorized())
    .then(() => {
      Analitycs.logEvent(EVENTS.DELETED_ADDRESS, {
        addressId,
        from,
      });
      removeAddressFromStore(addressId);
      const addresses = store.getters['user/addresses'];
      const noAddresses = addresses.length === 0;
      const isActiveAddressRemoving =
        store.getters['user/deliveryAddressId'] === addressId;

      if (noAddresses) {
        setDeliveryAddressToStore(null, 'no_addresses');
        store.commit('delivery/SET_WAREHOUSE', null);
        store.dispatch('catalog/FETCH_MAIN_CATEGORIES');
        return Promise.resolve();
      }

      if (isActiveAddressRemoving) {
        const address = addresses[0];
        return changeAddress(address, false, 'removed_address');
      }

      return Promise.resolve();
    })
    .finally(() => loadingOff());
}

/**
 * Синхронизация адресов
 * @returns {Promise}
 */
function syncAddresses() {
  /**
   * В базе есть адреса двух видов:
   * сохранённые с device_id и с user_id.
   * Первые сохраняются когда пользователь не авторизован,
   * вторые после авторизации
   * Этот метод запускается только после авторизации,
   * после получения user_id адресов в loadAddresses(),
   * после определения текущего
   * ближайшего адреса в
   */
  const addresses = store.getters['user/addresses'];
  let deliveryAddress = store.getters['user/deliveryAddress']
    ? { ...store.getters['user/deliveryAddress'] }
    : null;

  /**
   * Нужно только для веб версии — чтобы отправить в базу
   * сохранённый по device id адрес перед замещением
   * на user id адрес
   */
  const localySavedAddress = storage.parse('address::lastSaved', null);
  if (localySavedAddress) {
    deliveryAddress = { ...localySavedAddress };
    storage.remove('address::lastSaved');
  }

  /**
   * Абсолютно новый пользователь без сохранённых адресов
   */
  if (!addresses && !deliveryAddress) return Promise.resolve();

  /**
   * Переходим сюда если не сработал loadDeliveryAddress,
   * например не дал доступ к геолокации,
   * или уже есть выбранный адрес в сторе
   */

  /** если нету выбранного адреса то ставим самый первый */
  if (!deliveryAddress) {
    const mainAddress = addresses[0];
    setAddressesToStore(addresses);
    setDeliveryAddressToStore(mainAddress, 'first_address_in_sync');
    return Promise.resolve();
  }

  /**
   * Когда пз не авторизован и добавил адрес, он сохранился в БД
   * и в данный момент лежит в сторе в user/deliveryAddress
   * по device_id.
   * Если среди пришедших по user_id адресов есть такой же,
   * нужно найти его и заменить на него.
   * Зачем это нужно не до конца понятно
   */
  const hasSameAddress = hasMatchingAddress(addresses, deliveryAddress);
  if (hasSameAddress) {
    deliveryAddress = hasSameAddress;
    setAddressesToStore(addresses);
    setDeliveryAddressToStore(deliveryAddress, 'matched_address');
    return Promise.resolve();
  }

  /**
   * Если выбранный адрес — user_id-адрес, то просто устанавливаем
   */
  const updatedAddress =
    addresses ?? addresses.length > 0
      ? addresses.find(x => x.id === deliveryAddress.id)
      : undefined;

  if (updatedAddress !== undefined) {
    setAddressesToStore(addresses);
    setDeliveryAddressToStore(updatedAddress, 'user_id_added_address');
    return Promise.resolve();
  }

  return synchronizeAddress(deliveryAddress);
}

/**
 * Находит самый ближайший адрес к юзеру и делает его основным
 * Delivery addresss
 * GEO+ -> NearestAddress(200m) -> LastOrderAddress -> LastAddedAddress
 * GEO- -> LastOrderAddress -> LastAddedAddress
 * @returns {Promise}
 */
async function loadDeliveryAddress(isForce = false) {
  const addresses = store.getters['user/addresses'];
  let result = {
    lastAddedAddress: undefined,
    lastOrderAddress: undefined,
    nearestAddress: undefined,
    isNear: false,
    geo: false,
  };
  let deliveryAddress = store.getters['user/deliveryAddress'];

  if (deliveryAddress && !isForce) return Promise.resolve(result);
  if (!addresses.length) return Promise.resolve(result);

  const lastAddedAddress = addresses[0];
  result.lastAddedAddress = addresses[0];

  if (addresses.length === 1) {
    setDeliveryAddressToStore(lastAddedAddress, 'single_address');
    return Promise.resolve(result);
  }

  const lastOrderAddress = addresses.find(a => a.is_main);

  const startTime = Date.now();
  const { isNear, nearestAddress, geo } = await getNearestAddress(addresses);
  const endTime = Date.now() - startTime;

  let reason = '';
  if (isNear && nearestAddress) {
    deliveryAddress = nearestAddress;
    reason = 'nearest_address';
  } else if (lastOrderAddress) {
    deliveryAddress = lastOrderAddress;
    reason = 'last_order_address';
  } else {
    deliveryAddress = lastAddedAddress;
    reason = 'last_added_address';
  }

  setDeliveryAddressToStore(deliveryAddress, reason, endTime);

  result = {
    lastAddedAddress: lastAddedAddress,
    lastOrderAddress: lastOrderAddress,
    nearestAddress: nearestAddress,
    isNear: isNear,
    geo: geo,
  };

  // TODO: clear logs

  return Promise.resolve(result);
}

function initAddresses(forceLoadNearestAddress = false) {
  let loadDeliveryAddressRes = {
    lastAddedAddress: undefined,
    lastOrderAddress: undefined,
    nearestAddress: undefined,
    isNear: false,
    geo: false,
  };
  return loadAddresses()
    .then(() => loadDeliveryAddress(forceLoadNearestAddress))
    .then(res => {
      loadDeliveryAddressRes = res;
      return syncAddresses(); // TODO maybe need to be done firstly
    })
    .then(() => {
      // needs to be done after sync if sync is not first, if we will refactor sync this methods can be called agter laodDeliveryAddress
      decideToShowAddressConfirmation(loadDeliveryAddressRes);
    });
}

export const AddressService = {
  initAddresses,
  createAddress,
  changeAddress,
  updateAddress,
  removeAddress,
};
