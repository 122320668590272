import { store } from '@/store';
import { DevConfigs } from '@/utils/dev-config';
import { eventBus } from '@/utils/plugins/event-bus';
import { $log } from '@/utils/plugins/logger';
import { createStorage } from '@shared/services/storage';

let once = false;
let decided = false;
let storage = createStorage(window.localStorage);

export function getIsDesktopReminderShown() {
  return storage.getItem('address-presumptive::desktop-shown', false);
}

export function setIsDesktopReminderShown() {
  let threeHours = DevConfigs.isProd ? 3 * 60 * 60 * 1000 : 10 * 60 * 1000;
  storage.setItem('address-presumptive::desktop-shown', true, threeHours);
}

function resetStates() {
  decided = true;
  store.commit('user/SET_PRESUMPTIVE_ADDRESS', null);
  setIsDesktopReminderShown();
}

function onAddressChange(address) {
  if (
    ['user_selection', 'created_address', 'updated_address'].includes(
      address.from
    )
  ) {
    resetStates();
  }
}
function onCreatePreorder() {
  resetStates();
}

/**
 * lastAddedAddress: undefined,
 * lastOrderAddress: undefined,
 * nearestAddress: undefined,
 * isNear: false,
 * geo: false
 *
 * Reminder
 * GEO+ && isNear !== LastOrderAddress
 * GEO+ && !isNear
 * GEO- ->
 */
export function decideToShowAddressConfirmation(data) {
  if (!once) {
    eventBus.on('eb_on_address_change', onAddressChange);
    eventBus.on('eb_on_create_preorder', onCreatePreorder);
    once = true;
  }
  const addresses = store.getters['user/addresses'];
  const deliveryAddress = store.getters['user/deliveryAddress'];
  const { lastOrderAddress, isNear, geo } = data;
  $log('decideToShowAddressConfirmation', {
    decided,
    addresses,
    deliveryAddress,
    lastOrderAddress,
    isNear,
    geo,
  });

  if (!addresses.length || !deliveryAddress || decided) {
    resetStates();
    return;
  }

  let reason = '';
  // если у пользователя текущий адрес А и если он близко к нему
  // и если адрес последнего заказа не текущий адрес
  if (
    geo &&
    isNear &&
    lastOrderAddress &&
    deliveryAddress.id !== lastOrderAddress.id
  ) {
    reason = 'geo + isNear + deliveryAddress !== lastOrderAddress';
  }
  // если у пользователя текущий адрес А и если он далеко от него
  else if (geo && !isNear) {
    reason = 'geo + !isNear';
  }
  // если у пользователя не разрешен гео
  else if (!geo) {
    reason = '!geo';
  }

  $log('decideToShowAddressConfirmation', { reason, decided });
  if (reason) store.commit('user/SET_PRESUMPTIVE_ADDRESS', deliveryAddress);
  else resetStates();

  decided = true;
}
