<template>
  <transition :name="transition" @after-enter="onOpen" @after-leave="onClose">
    <div
      v-if="show"
      class="UiPopup popup-backdrop"
      @click.stop
      @click.self="closeModal"
    >
      <div
        role="dialog"
        class="popup-dialog"
        :class="`--${radius} ${fullPage ? '--full-page' : ''} ${withoutPaddings ? '--without-padding' : ''}`"
      >
        <UiInlineIcon
          v-if="isBackButton"
          name="back-arrow"
          @click.native="closeModal"
        />
        <UiInlineIcon
          v-else-if="closable"
          :name="$isDesktop ? 'popup-desk-close' : 'popup-mob-close'"
          class="absolute z-10 cursor-pointer"
          :class="
            $isDesktop ? 'w-10 h-10 top-6 right-6' : 'w-6 h-6 top-3 right-3 '
          "
          @click.native="closeModal"
        />

        <div class="text-base md:text-2xl font-bold">
          <slot name="title"> </slot>
        </div>
        <div class="popup-dialog-content desk-scrollbar">
          <slot></slot>
          <slot name="buttons"></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import {
  whenNavigationToBack,
  cancelHandlingToBack,
  handleNavigationToBack,
} from 'choco-app';
import { isElement } from '@/utils/functions/is-node';
import UiInlineIcon from '@pure-ui/components/UiInlineIcon/UiInlineIcon.vue';

export default {
  name: 'UiPopup',
  components: { UiInlineIcon },
  model: {
    prop: 'show',
    event: 'change',
  },
  props: {
    show: Boolean,
    closable: {
      type: Boolean,
      default: false,
    },
    radius: {
      type: String,
      default: 'old',
    },
    fullPage: {
      type: Boolean,
      default: false,
    },
    isBackButton: {
      type: Boolean,
      default: false,
    },
    transition: {
      type: String,
      default: 'popup-fade',
    },
    withoutPaddings: Boolean,
  },
  data() {
    return {};
  },
  beforeDestroy() {
    if (this.$el && isElement(this.$el)) document.body.removeChild(this.$el);
  },
  mounted() {
    document.body.appendChild(this.$el);
  },
  methods: {
    closeModal() {
      this.$emit('change', false);
      cancelHandlingToBack();
      this.onTriggeredModalClose();
    },
    onTriggeredModalClose() {
      this.$emit('onTriggeredModalClose', false);
    },
    onClose() {
      cancelHandlingToBack();
      this.$emit('onClose');
      document.body.style.overflow = 'auto';
    },
    onOpen() {
      whenNavigationToBack(this.closeModal);
      handleNavigationToBack();
      this.$emit('onOpen');
      document.body.style.overflow = 'hidden';
    },
  },
};
</script>

<style scoped>
.popup-backdrop {
  @apply fixed inset-0 flex items-center justify-center bg-overlay z-modal;
}
.popup-dialog {
  @apply mx-4 px-4 pt-4 pb-6
    md:px-8 md:pt-7 md:pb-10
    relative z-modal bg-white;
  max-width: 100%;
  width: var(--popup-width, 100%);
  margin-bottom: var(--popup-offset);

  box-shadow:
    0px 92px 92px rgba(0, 0, 0, 0.05),
    0px 3px 38.4354px rgba(0, 0, 0, 0.0334858),
    0px -3px 20.5494px rgba(0, 0, 0, 0.06),
    0px 5.79369px 11.5198px rgba(0, 0, 0, 0.0189792),
    0px 2.40368px 6.11809px rgba(0, 0, 0, 0.0132742),
    0px 0.705169px 2.54588px rgba(0, 0, 0, 0.00743532);

  @media (min-width: 768px) {
    width: var(--popup-width-d, 100%);
  }
}
.popup-dialog.--without-padding {
  @apply p-0;
}

.--old {
  @apply rounded-2xl md:rounded-4xl;
}
.--2xl {
  @apply rounded-2xl;
}
.--3xl {
  @apply rounded-3xl;
}
.--4xl {
  @apply rounded-4xl;
}

.--full-page {
  @apply mx-0 rounded-none w-full h-full overflow-y-auto;
}
.--full-page .popup-dialog-content {
  @apply h-full;
}

.popup-fade-enter,
.popup-fade-leave-to {
  @apply opacity-0;
}

.popup-fade-enter-active {
  @apply ease-in duration-200;
}

.popup-fade-leave-active {
  @apply ease-in duration-200 delay-75;
}

.popup-fade-enter-active > .popup-dialog {
  transition:
    opacity ease-in 75ms 100ms,
    transform ease-in-out 75ms 100ms;
}

.popup-fade-leave-active > .popup-dialog {
  transition:
    opacity ease-in 75ms 50ms,
    transform ease-in-out 100ms;
}

.popup-fade-enter > .popup-dialog,
.popup-fade-leave-to > .popup-dialog {
  @apply opacity-0 transform scale-75;
}

.popup-move-in-enter,
.popup-move-in-leave-to {
  transform: translateX(0px);
}

.popup-slide-enter-active,
.popup-slide-leave-active {
  transition: transform 0.3s ease;
}
.popup-slide-enter,
.popup-slide-leave-to {
  transform: translateX(100%);
}
.popup-slide-enter-to,
.popup-slide-leave-from {
  transform: translateX(0%);
}
</style>
