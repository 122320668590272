import { eventBus } from '@/utils/plugins/event-bus';
import { $log } from '@/utils/plugins/logger';

function openWarehouseMigrateModal() {

  // переделать на is_active из роута get_by_coordinates

  // const state = store.getters['delivery/warehouseId'] === 6;
  // if (state) {
  //   eventBus.emit('eb_open_migrate_warehouse_modal');
  // }
  return false;
}

function init() {
  $log('WarehouseInactiveService', null, 'Init');
  eventBus.on('eb_on_address_change', openWarehouseMigrateModal);
  eventBus.on('eb_on_address_update', openWarehouseMigrateModal);
  eventBus.on('eb_init_page_load_done', openWarehouseMigrateModal);
  openWarehouseMigrateModal();
}

export const WarehouseInactiveService = {
  init,
  openWarehouseMigrateModal,
};
