import { CITIES } from '../constants';

function filterCities(text) {
  Object.values(CITIES).forEach(city => {
    if (text.includes(city.name)) text = text.replace(city.name, '');
  });
  return text;
}
const filterP = text => (text.includes('!') ? text.replace('!', '') : text);

const FILTERS = {
  cities: filterCities,
  '!': filterP,
};
export function filterCategoryName(text, filters = ['cities', '!']) {
  return filters.reduce((acc, f) => FILTERS[f](acc), text);
}
