import { mapGetters } from 'vuex';
import { AdultsService } from '@services/adults';
import { Logger } from '@shared/services/logger';
import { PRODUCT_ADDED } from '@shared/config/product';
import { Analitycs, EVENTS } from '@shared/services/analitycs';
import { CART_EVENTS, CartEmitter } from '@services/cart/events';
import { extractWeight } from '@services/product/utils/extract-weight';
import { getCoffeeUnitsInCart } from '@/lib/coffee/get-coffee-units-count-in-cart';

import {
  ERR_NO_ADDRESS,
  ERR_NO_DELIVERY,
  ERR_INCORRECT_ADDRESS,
} from '../../config/delivery';

const BRAND_NAME_EXCEPTION_ANALYTICAL_CATEGORIES = [
  1, 5, 6, 2, 73, 10, 74, 4, 3,
];
export const RenderlessProduct = {
  name: 'RenderlessProduct',
  inject: ['toast', 'popup', 'bottomSheetPopup', 'bottomSheet'],
  props: {
    product: {
      type: Object,
      required: true,
    },
    idKey: {
      type: String,
      default: 'id',
    },
    feature: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isPending: false,
      featureIdKey: 'feature_id',
    };
  },
  computed: {
    ...mapGetters('delivery', ['isWarehouseOpen', 'warehouse']),
    ...mapGetters('cart', [
      'isInCart',
      'getFromCart',
      'isInCartWithFeature',
      'cartWeight',
      'cart',
    ]),
    ...mapGetters('heavyOrder', ['maxWeight']),
    ...mapGetters('favorites', { storeIsFavorite: 'isFavorite' }),
    /**
     * Добавлен ли товар в корзину
     * @returns {*}
     */
    isSelected() {
      let result = false;
      if (this.product && this.product.feature_id) {
        result = this.isInCartWithFeature(
          this.product[this.idKey],
          this.product[this.featureIdKey]
        );
      } else if (this.feature && this.feature.id) {
        result = this.isInCartWithFeature(
          this.product[this.idKey],
          this.feature[this.idKey]
        );
      } else {
        result = this.product ? this.isInCart(this.product[this.idKey]) : false;
      }
      return result;
    },
    isFavorite() {
      return this.storeIsFavorite(this.product.id);
    },
    /**
     * Количество единиц товара, добавленных в корзину
     * @returns {*}
     */
    quantity() {
      let productId = this.product[this.idKey];
      let featureId = 0;

      if (this.product && this.product.feature_id) {
        featureId = this.product[this.featureIdKey];
      } else if (this.feature && this.feature.id) {
        featureId = this.feature[this.idKey];
      }

      let product = this.getFromCart(`${productId}_${featureId}`);
      let quantity = product?.quantity || 0;
      return quantity;
    },
    /**
     * Количество единиц товара, доступных к покупке
     * @returns {string|null}
     */
    lastItems() {
      let diff = 0;

      // if (
      //   this.product &&
      //   this.product.feature_id &&
      //   this.product.features_quantity &&
      //   this.product.features_quantity !== 1
      // ) {
      //   diff = this.product.features_quantity - this.quantity;
      // } else
      if (this.feature && this.feature.max_quantity) {
        diff = this.feature.max_quantity - this.quantity;
      } else {
        diff =
          (this.product && this.product.max_quantity
            ? this.product.max_quantity
            : 0) - this.quantity;
      }

      return diff >= 0 && diff <= 3 ? `${diff}` : null;
    },
    showBrandName() {
      return !BRAND_NAME_EXCEPTION_ANALYTICAL_CATEGORIES.includes(
        this.product.analytical_category_id
      );
    },
  },
  methods: {
    onLoading() {
      this.isPending = true;
    },
    offLoading() {
      this.isPending = false;
    },
    /**
     * Проверяем продукт если тобачка или алкоголь то открываем модалку
     * И перекидываем колбек который должен сработать после подтверждения
     * @param {*} addToCart
     */
    checkIsAgreedAdultProduct(from, id) {
      if (
        AdultsService.checkIsAdultProduct(this.product) &&
        !(
          AdultsService.checkIsAgreed() ||
          this.$store.getters['adults/isAgreed']
        )
      ) {
        const vm = this;
        const cb = () => vm.addToCart(from, id);
        AdultsService.openModal(cb);
        return true;
      }
      return false;
    },
    removeProductFromCart() {
      this.$store
        .dispatch('cart/REMOVE_PRODUCT_FROM_CART', {
          productId: this.product[this.idKey],
          featureID:
            this.feature && this.feature[this.idKey]
              ? this.feature[this.idKey]
              : this.product[this.featureIdKey],
        })
        .then(v => {
          CartEmitter.emit(CART_EVENTS.ON_CART_CHANGED);
          return Promise.resolve(v);
        })
        .catch(this.onError);
    },
    removeFromCart(quantity = 1) {
      this.onLoading();
      const payload = {
        productId: this.product[this.idKey],
        featureID:
          this.feature && this.feature[this.idKey]
            ? this.feature[this.idKey]
            : this.product[this.featureIdKey],
        quantity,
      };
      this.$store
        .dispatch('cart/REMOVE_FROM_CART', payload)
        .then(() => {
          CartEmitter.emit(CART_EVENTS.ON_CART_CHANGED);
        })
        .catch(this.onError)
        .finally(() => {
          this.offLoading();
        });
    },
    addToCart(from = null, id = null) {
      this.checkIsWarehouseOpen();
      Logger.event('addToCart', { from, id });
      if (this.checkIsAgreedAdultProduct(from, id)) {
        return;
      }

      if (this.product.is_sample) {
        this.toast.show('На данный товар действует ограничение', 'info');
        return;
      }

      if (this.product[this.idKey] === 11700) {
        localStorage.setItem('appliancesFrom', from ?? 'appliancesFeat');
      }
      if (
        this.product.analytical_category_id === 159 &&
        this.cart.items.filter(e => e.analytical_category_id === 159).length > 0
      ) {
        Logger.event('heavy');

        this.toast.show(
          'На данный товар действует ограничение по весу, можно добавить только 1 товар в заказ',
          'info'
        );
        return;
      }

      if (this.product.is_coffee && getCoffeeUnitsInCart() >= 2) {
        this.toast.show(
          'Этот товар можно добавить не более 2 раз за заказ',
          'info'
        );
        return;
      }

      let payload = {
        ...this.product,
        productId: this.product[this.idKey],
        featureID:
          this.feature && this.feature[this.idKey]
            ? this.feature[this.idKey]
            : this.product[this.featureIdKey],
        price: this.product.price ? this.product.price : this.feature.price,
      };
      Logger.event('payload', payload);

      if (this.isPending) {
        Logger.event('isPending', this.isPending);
        return;
      }

      if (from) {
        const key = id ? `${from}_${id}` : `${from}`;
        payload = Object.assign(payload, {
          addedFrom: key,
        });
      }

      // Heavy-Order
      let weight = extractWeight(this.product, this.feature);
      weight += this.cartWeight;

      Logger.event('weights', {
        product_weight: this.product.weight,
        feature_weight:
          this.feature && this.feature.weight
            ? this.feature.weight
            : 'undefined',
        feature_name:
          this.feature && this.feature.name ? this.feature.name : 'undefined',
        cart_weight: this.cartWeight,
        calculated_weight: weight,
      });

      if (weight > this.maxWeight) {
        this.$eventBus.emit('eb_open_full_cart_modal', from);
        return;
      }
      // Heavy-Order

      Logger.event('isPending', this.isPending);
      this.onLoading();
      this.$store
        .dispatch('cart/ADD_TO_CART', payload)
        .then(res => {
          Logger.event('then', res);
          CartEmitter.emit(CART_EVENTS.ON_CART_CHANGED);

          if (
            AdultsService.checkIsAdultProduct(this.product) &&
            from === PRODUCT_ADDED.HISTORY_SLIDER_CART_PAGE
          ) {
            this.$eventBus.emit('eb_on_add_adult_product_from_history');
          }
        })
        .catch(err => {
          Logger.event('catch', err);

          this.onError(err);
        })
        .finally(() => {
          this.offLoading();
        });
    },
    addToFavorites(id) {
      return this.$store
        .dispatch('favorites/ADD_TO_FAVORITES', {
          id,
        })
        .then(() => {
          Analitycs.logEvent(EVENTS.FAVORITES_ADDED, {
            product_id: id,
          });
        })
        .catch(() => {
          this.toast.show(this.$t('wasNotPossible'));
        });
    },
    removeFromFavorites(id) {
      return this.$store
        .dispatch('favorites/REMOVE_FROM_FAVORITES', {
          id,
        })
        .catch(() => {
          this.toast.show('wasNotPossible');
        });
    },
    openNoCorrectAddress() {
      this.$eventBus.emit('eb_open_no_correct_address_modal');
    },
    openNoDelivery() {
      this.$eventBus.emit('eb_open_no_delivery_modal');
    },
    openAddress() {
      if (this.$isDesktop) {
        this.$eventBus.emit('eb_open_address_create_container', {
          from: 'on_adding_product_to_cart',
        });
      } else
        this.$eventBus.emit('eb_open_create_address_reminder', {
          from: 'on_adding_product_to_cart',
        });
    },
    checkIsWarehouseOpen() {
      if (this.warehouse && !this.isWarehouseOpen) {
        this.$eventBus.emit('eb_open_time_modal', { from: 'product' });
      }
    },
    onError(err) {
      Logger.event('onError', err);
      if (!err || !err.message) return;

      switch (err.message) {
        case ERR_NO_ADDRESS:
          this.openAddress();
          break;
        case ERR_NO_DELIVERY:
          this.openNoDelivery();
          break;
        case ERR_INCORRECT_ADDRESS:
          this.openNoCorrectAddress();
          break;
        default:
          this.toast.show(err.message);
          break;
      }
    },
    replace() {
      const payload = {
        recipe_id: this.product.replacement.recipe_id,
        ...(this.product.replacement.feature_id && {
          feature_id: this.product.replacement.feature_id,
        }),
        product_id: this.product.replacement.product_id,
        replace_product_id: this.product.id,
        ...(this.product.feature_id && {
          replace_feature_id: this.product.feature_id,
        }),
      };
      return this.$store
        .dispatch('cart/REPLACE_INGREDIENT', payload)
        .catch(this.onError);
    },
    replaceFromFull() {
      const payload = {
        recipe_id: this.product.replacement.recipe_id,
        ...(this.product.replacement.feature_id && {
          feature_id: this.product.replacement.feature_id,
        }),
        product_id: this.product.replacement.product_id,
        replace_product_id: this.product.id,
        ...(this.feature.id && { replace_feature_id: this.feature.id }),
      };
      return this.$store
        .dispatch('cart/REPLACE_INGREDIENT', payload)
        .catch(this.onError);
    },
  },
  i18n: {
    messages: {
      kk: {
        wasNotPossible: 'Қате',
      },
      ru: {
        wasNotPossible: 'Не удалось',
      },
    },
  },
  render() {
    return this.$scopedSlots.default({
      isPending: this.isPending,
      isSelected: this.isSelected,
      isFavorite: this.isFavorite,
      quantity: this.quantity,
      addToCart: this.addToCart,
      addToFavorites: this.addToFavorites,
      removeFromCart: this.removeFromCart,
      removeProductFromCart: this.removeProductFromCart,
      removeFromFavorites: this.removeFromFavorites,
      replace: this.replace,
      replaceFromFull: this.replaceFromFull,
      lastItems: this.lastItems,
      showBrandName: this.showBrandName,
    });
  },
};
