<template>
  <div
    class="BaseCustomerAddress address"
    :class="`${active ? '--active' : ''}`"
  >
    <div class="address__icon">
      <slot name="left"></slot>
    </div>
    <div class="address__text">
      <p class="address__text__name">{{ title || $t('address') }}</p>
      <p class="address__text__address">
        {{ description || $t('selectAddress') }}
      </p>
    </div>
    <div class="address__controls" @click.stop>
      <slot name="right"> </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseCustomerAddress',
  components: {},
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  computed: {},
  i18n: {
    messages: {
      kk: {
        selectAddress: 'Мекенжай таңдаңыз',
        address: 'Мекенжай',
      },
      ru: {
        selectAddress: 'Укажите адрес',
        address: 'Адрес',
      },
    },
  },
};
</script>

<style scoped>
.address {
  @apply pt-1 flex cursor-pointer;
}
.address__icon {
  @apply mr-2;
}
.address__text {
  @apply w-full leading-tight;
}
.address__text__name {
  @apply text-base font-bold leading-5 line-clamp-1;
}
.--active .address__text__name {
  @apply text-sirius-orange-100;
}
.address__text__address {
  @apply mt-1 
    text-sm font-medium leading-4 text-sirius-gray-1000 
    line-clamp-1;
}
.address__controls {
  @apply ml-auto flex items-center;
}
.address__control {
  @apply w-6 h-6 flex items-center justify-center;
}
</style>
