import { isStreet } from './is-street';
import { isBuilding } from './is-building';
import { fetchWarehouseZoneCoordinates } from './fetch-warehouse-zone-coordinates';
import { getDeliveryZoneValidationsOfCoords } from './map-polygon';
import { CityService } from '@services/city';
import { validateGeoObject } from './validate-geoobject';

const insertValidationsToGeoObject = geoObjects => {
  return new Promise(resolve => {
    fetchWarehouseZoneCoordinates().then(polygons => {
      console.log('fetchWarehouseZoneCoordinates', polygons);

      // Мы тут в объекте поле редактируем, осторожно)
      const res = geoObjects.map(value => {
        const [lng, lat] = value.GeoObject.Point.pos.split(' ');

        let { isInTerritory, isInExtended } =
          getDeliveryZoneValidationsOfCoords(
            window.ymaps,
            [lat, lng],
            polygons.regular,
            polygons.extended
          );
        value.isInTerritory = isInTerritory;
        value.isInExtended = isInExtended;

        value = validateGeoObject(value);

        return value;
      });

      resolve(res);
    });
  });
};

export const validateGeoObjectsCollection = (
  GeoObjectCollection,
  initialCoords
) => {
  const { featureMember } = GeoObjectCollection;

  let hasStreet = false;
  let hasBuilding = false;

  featureMember.forEach(member => {
    const { kind } = member.GeoObject.metaDataProperty.GeocoderMetaData;
    if (!hasStreet) hasStreet = isStreet(kind);
    if (!hasBuilding) hasBuilding = isBuilding(kind);
  });

  return insertValidationsToGeoObject(featureMember, initialCoords).then(
    res => {
      let addresses = res || [];
      let address = res[0] || null;

      return {
        addresses,
        address,
        hasStreet,
        hasBuilding,
      };
    }
  );
};

export const filterGeoObjectsByDelivery = GeoObjects => {
  return GeoObjects.filter(
    value => value.isInCountry && value.isInCity && value.hasBuilding
  );
};

export const sortGeoObjectsByDelivery = GeoObjects => {
  let currentCity = CityService.getCityData().name;
  return GeoObjects.sort(a => (a.cityName === currentCity ? -1 : 1));
};
