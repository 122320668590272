<template>
  <div class="Banner" v-on="$listeners">
    <img
      v-img-proxy="{
        url: $isDesktop ? imageDesktop : imageMobile,
        size: $isDesktop ? 's:388:172' : 's:226:132',
        as: 'webp',
      }"
      alt="banner-img"
      class="Banner__img"
    />
    <div class="Banner__text">
      <span v-html="text" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Banner',
  props: {
    textRu: {
      type: String,
      default: '',
    },
    textKk: {
      type: String,
      default: '',
    },
    imageMobile: {
      type: String,
      default: '',
    },
    imageDesktop: {
      type: String,
      default: '',
    },
  },
  computed: {
    text() {
      const prop = this.$i18n.locale === 'ru' ? 'textRu' : 'textKk';
      return this[prop];
    },
  },
};
</script>

<style>
.Banner {
  --width: 226px;
  --desktop-width: 388px;
  width: var(--width);
  height: 100%;
  position: relative;
  padding: 10px;
  box-sizing: border-box;
  cursor: pointer;
}

.Banner__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 16px;
  background: #d4d3df;
}

.Banner__text {
  position: relative;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.07px;
}

@media (min-width: 768px) {
  .Banner {
    --width: var(--desktop-width);
    border-radius: 24px;
    padding: 20px;
  }

  .Banner__text {
    font-size: 20px;
    line-height: 24px;
  }
}
</style>
