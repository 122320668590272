import { client } from '../../clients/client';

/**
 * Выдача заказа по аиди
 */
export default function (id) {
  return client
    .post(`/lavka/v1/invoice/generate_hash`, { order_id: id })
    .then(res => res.data.data.hash);
}
