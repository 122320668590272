export const PopularProductsModule = {
  namespaced: true,
  state: {
    products: [],
  },
  getters: {
    products(state) {
      return state.products;
    },
  },
  mutations: {
    SET_PRODUCTS(state, value) {
      state.products = value;
    },
  },
};
