<template>
  <div
    class="p-4 pt-3 border-sirius-gray-400 last:border-none leading-tight cursor-pointer"
    :class="{ 'border-b': !isLast }"
  >
    <div v-if="product.status" class="mb-2 flex justify-start">
      <div
        class="px-2 py-1 rounded-lg font-medium text-xs text-sirius-red-200 bg-sirius-red-200 bg-opacity-10"
      >
        {{ product.status }}
      </div>
    </div>
    <div
      class="flex md:flex-row-reverse items-start justify-between md:justify-end"
      @click="changeCheckbox"
    >
      <div class="flex">
        <div class="shrink-0">
          <ProductPhoto
            :src="product.path"
            class="bg-sirius-gray-200 rounded-xl shrink-0 overflow-hidden"
            :disabled="!canReturn"
            img-size="s:60:60"
            style="width: 60px; height: 60px"
          />
        </div>
        <div class="ml-2">
          <p
            class="font-medium text-sm"
            :class="{ 'text-sirius-gray-700': !canReturn }"
            style="letter-spacing: -0.025em"
          >
            {{ product.name }} {{ product.feature_name }}
          </p>
          <div class="mt-2 flex items-center justify-between">
            <div class="flex items-center">
              <p class="bg-sirius-gray-200 px-2 py-1 rounded-lg">
                <span v-if="isDiscount" class="line-through mr-0.5">
                  {{ formatPrice(product.original_price) }} ₸
                </span>
                <span class="" :class="{ 'text-sirius-red-200': isDiscount }">
                  {{ formatPrice(product.price) }} ₸
                </span>
              </p>
              <span class="ml-2 text-sirius-gray-700">
                x {{ product.quantity }}
              </span>
              <span class="ml-2 font-medium text-sirius-gray-700">
                {{ formatPrice(product.quantity * product.price) }} ₸
              </span>
            </div>
          </div>

          <div
            v-if="checked && product.available_quantity > 1"
            class="mt-4"
            @click.stop
          >
            <p class="text-sm text-sirius-gray-700">Укажите количество</p>
            <div class="flex items-center mt-2">
              <ProductCounter
                style="width: 100px; max-width: 100px"
                :model-value="counter"
                :max="product.available_quantity || 0"
                :min="1"
                variant="secondary"
                size="sm"
                @onIncrement="onCounterIncrement"
                @onDecrement="onCounterDecrement"
                @onMaxLimit="onMaxLimit"
              />
              <p class="font-medium text-black-new-500 dark:text-white ml-3">
                {{ formatPrice(product.price * counter) }} ₸
              </p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="canReturn" class="flex ml-3 md:ml-0 md:mr-3">
        <label class="checkbox" @click.stop>
          <input
            v-model="checked"
            class="checkbox__input"
            type="checkbox"
            @change="onCheckboxChanged()"
          />
          <!-- <svg class="checkbox__check" width="20" height="20">
          <polyline points="18 5 9 16 4 11" />
        </svg> -->
          <div class="checkbox-box" :class="{ 'checkbox-box-active': checked }">
            <svg
              width="16"
              height="12"
              viewBox="0 0 20 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.927 1.927L7.18401 13.669L1.16101 7.646"
                stroke="white"
                stroke-width="3"
                stroke-miterlimit="10"
              />
            </svg>
          </div>
        </label>
      </div>
      <div v-else class="flex ml-3 md:ml-0 md:mr-3">
        <div class="checkbox">
          <svg
            class="checkbox-box checkbox-box-disabled"
            width="16"
            height="12"
            viewBox="0 0 20 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.927 1.927L7.18401 13.669L1.16101 7.646"
              stroke="white"
              stroke-width="3"
              stroke-miterlimit="10"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { RahmetApp } from '@shared/RahmetApp';
import { formatPrice } from '@shared/utils/formatters/price';
import ProductPhoto from '@pure-ui/components/Product/ProductPhoto/ProductPhoto.vue';

export default {
  name: 'ProductItem',
  components: {
    ProductCounter: () =>
      import('@pure-ui/components/Product/ProductCounter/ProductCounter.vue'),
    ProductPhoto,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    isLast: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      counter: 0,
      checked: false,
    };
  },
  computed: {
    withoutStatus() {
      return (
        this.product.status && !String(this.product.status).includes('Возврат')
      );
    },
    minQuantity() {
      return this.product.quantity - this.product.available_quantity;
    },
    canReturn() {
      return !(
        this.product.available_quantity === 0 ||
        String(this.product.status).includes('Возврат')
      );
    },
    isDiscount() {
      return this.product.original_price !== this.product.price;
    },
  },
  methods: {
    formatPrice,
    changeCheckbox() {
      if (!this.canReturn) return;
      this.checked = !this.checked;
      this.onCheckboxChanged();
    },
    onCounterIncrement() {
      this.counter += 1;
      this.$emit('onProductIncrement', this.product, this.counter);
    },
    onCounterDecrement() {
      this.counter -= 1;
      if (this.counter === 0) this.checked = false;
      this.$emit('onProductDecrement', this.product, this.counter);
    },
    onCheckboxChanged() {
      RahmetApp.hapticSelection();
      this.counter = this.checked === true ? 1 : 0;
      this.$emit('onProductDecrement', this.product, this.counter);
    },
    onMaxLimit() {
      RahmetApp.hapticNotification('error');
    },
  },
};
</script>

<style lang="postcss">
.checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 24px;
  height: 24px;
}
.checkbox__input {
  width: 0;
  height: 0;
  opacity: 0;
}
.checkbox-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border: 2px solid #a4a2b7;
  box-sizing: border-box;
  border-radius: 8px;
  transition: 0.3s;
  &-active {
    border-color: #ffa100;
    background: #ffa100;
  }
  &-disabled {
    border-color: #a4a2b7;
    background: #a4a2b7;
  }
}
</style>
