<template>
  <button
    class="ui-button cursor-pointer"
    :class="`
      ${sizes[size]}
      ${variants[variant]}
      ${size}
      ${useDefaultPadding ? sizes_padding[size] : ''}
    `"
    v-on="$listeners"
  >
    <slot>
      {{ label }}
    </slot>
  </button>
</template>

<script>
const SIZES = {
  xs: '--xs',
  sm: '--sm',
  md: '--md',
  lg: '--lg',
  xl: '--xl',
  SM: '--SM',
  M: '--M',
  LG: '--LG',
  XL: '--XL',
};

const SIZES_PADDING = {
  xs: '',
  sm: 'px-0 py-1',
  md: 'px-4 py-2',
  lg: 'px-4 py-4',
  xl: 'px-6 py-4',
};

const VARIANTS = {
  primary: '--primary',
  gray: '--gray',
  white: '--white',
  darkGray: '--dark-gray',
  'light-gray': '--light-gray',
  secondary: '--secondary',
  'gray-no-hover': '--gray-no-hover',
};

export default {
  name: 'UiButton',
  props: {
    size: {
      type: String,
      default: 'md',
      validator: v => SIZES.hasOwnProperty(v),
    },
    variant: {
      type: String,
      default: 'primary',
      validator: v => VARIANTS.hasOwnProperty(v),
    },
    useDefaultPadding: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      sizes: SIZES,
      variants: VARIANTS,
      sizes_padding: SIZES_PADDING,
    };
  },
};
</script>

<style scoped>
/*
С lang="postcss" не работает
https://github.com/nuxt/nuxt/issues/3231#issuecomment-381873034
 */
.ui-button {
  @apply inline-flex
  items-center
  justify-center
  transition ease-in duration-75;

  &.--xs {
    @apply rounded-2xl;
  }

  &.--sm {
    height: 36px;
    @apply rounded-xl;
  }

  &.--md {
    height: 44px;
    @apply rounded-xl;
  }

  &.--SM {
    height: 36px;
    @apply px-3 w-full rounded-xl text-sm font-bold leading-4;
  }

  &.--M {
    min-height: 48px;
    @apply px-3 w-full rounded-2xl text-base font-bold leading-5;
  }
  &.--LG {
    min-height: 52px;
    @apply px-3 w-full rounded-2xl text-base font-bold leading-5;
  }

  &.--XL {
    min-height: 64px;
    @apply px-3 w-full rounded-2xl text-lg font-bold leading-6;
  }

  &.--lg {
    @apply rounded-xl h-13;
  }

  &.--xl {
    @apply h-16 rounded-2xl;
  }

  &.--full {
    @apply rounded-full;
  }

  &:focus {
    @apply outline-none;
  }

  &.--primary {
    @apply bg-sirius-orange-100 md:hover:bg-sirius-orange-400 text-white;

    &:active {
      @apply bg-sirius-orange-300;
    }

    &:disabled {
      @apply opacity-50;
    }
  }

  &.--gray {
    @apply bg-sirius-gray-200 md:hover:bg-sirius-gray-300 text-sirius-black-100;

    &:active {
      @apply bg-sirius-gray-100;
    }

    &:disabled {
      @apply opacity-50;
    }
  }

  &.--secondary {
    @apply bg-sirius-gray-200 md:hover:bg-sirius-gray-300 text-sirius-black-100;

    &:active {
      @apply bg-sirius-gray-100;
    }

    &:disabled {
      @apply opacity-50;
    }
  }

  &.--dark-gray {
    @apply bg-sirius-gray-300 text-sirius-black-100;

    &:active {
      @apply bg-sirius-gray-200;
    }

    &:disabled {
      @apply opacity-50;
    }
  }

  &.--white {
    @apply bg-white text-sirius-black-100;

    &:active {
      @apply bg-white;
    }

    &:disabled {
      @apply opacity-50;
    }
  }

  &.--light-gray {
    @apply text-sirius-black-100;
    background: rgba(212, 211, 223, 0.15);

    &:active {
      @apply bg-white;
    }

    &:disabled {
      @apply opacity-50;
    }
  }

  &.--gray-no-hover {
    @apply bg-sirius-gray-200  text-sirius-black-100;
  }

  &.--transparent {
    @apply bg-transparent;
  }
}
</style>
