<template>
  <MainLayout with-bottom-toolbar>
    <template v-if="$isWebView" #header>
      <CartHeader
        :cart-btn="!isEmptyCart"
        @onClearCart="clearCart"
        @onBack="goBack"
      />
    </template>

    <div class="cart-content pb-3">
      <template v-if="!$isWebView">
        <UiBreadCrumbs class="px-4 md:px-0 mt-5 md:mt-16" />
        <div v-if="!$isDesktop" class="mt-5 px-4">
          <CartAddressContext />
        </div>
      </template>

      <EmptyCart
        v-if="cartSize === 0"
        class="mt-8 md:mt-18 mx-auto pb-28 px-4"
        @onPickProducts="goToMain"
      />

      <MiniCart ref="miniCart" />

      <NoAddressReminderContainer v-if="!$isDesktop" />
    </div>
  </MainLayout>
</template>

<script>
import { Analitycs, EVENTS, SEGMENT_EVENTS } from '@shared/services/analitycs';
import { RahmetApp } from '@shared/RahmetApp';
import { mapState, mapGetters } from 'vuex';

import CartAddressContext from '@components/address-context/CartAddressContext.vue';
import CartHeader from '@components/header/CartHeader.vue';
import UiBreadCrumbs from '@ui/UiBreadCrumbs.vue';
import MainLayout from '@layout/MainLayout.vue';
import MiniCart from './components/miniCart.vue';

export default {
  name: 'CartView',
  components: {
    CartAddressContext,
    UiBreadCrumbs,
    CartHeader,
    MainLayout,
    MiniCart,
    EmptyCart: () =>
      import('@pure-ui/components/Cart/components/EmptyCart.vue'),
    NoAddressReminderContainer: () =>
      import('@components/address/NoAddressReminderContainer.vue'),
  },
  beforeRouteEnter(to, from, next) {
    Analitycs.logSegmentEvent(SEGMENT_EVENTS.CLICK_CART, {
      screenName: from.name ?? '',
    });
    next();
  },
  data() {
    return {
      hasReminder: false,
    };
  },
  computed: {
    ...mapState('cart', ['cart']),
    ...mapGetters('cart', ['cartSize']),
    isEmptyCart() {
      return this.cartSize === 0;
    },
  },
  methods: {
    goToMain() {
      this.$router.push({ name: 'main' });
    },
    clearCart() {
      RahmetApp.hapticSelection();
      this.$refs.miniCart.clearCart();
    },
    goBack() {
      RahmetApp.hapticSelection();
      Analitycs.logEvent(EVENTS.BACK_BASKET);
      const emptyHistory = window.history.length < 3;
      if (this.$route.params.beforeRoute === 'checkout' || emptyHistory) {
        this.$router.push({ name: 'main' });
      } else this.$router.go(-1);
    },
    checkHasAReminder() {
      setTimeout(() => {
        this.hasReminder = !!document.getElementById('UiBlackReminder');
      }, 1000);
    },
  },
  eventBusEvents: {
    eb_on_address_change() {
      this.checkHasAReminder();
    },
  },
  i18n: {
    messages: {
      ru: {
        cart: 'Корзина',
        notEnoughTillMin: 'До минимальной суммы {amount} ₸',
        returnToMain: 'Вернуться на главную',
        cartIsEmptyYet: 'Корзина пока пуста',
        timeToFixIt: 'Самое время это исправить! ✨',
        pickProducts: 'Выбрать товары',
      },
      kk: {
        cart: 'Себет',
        notEnoughTillMin: 'Минималды сомаға {amount} ₸',
        returnToMain: 'Басты бетке',
        cartIsEmptyYet: 'Себет әлі бос',
        timeToFixIt: 'Оны тап қазір толтырыңыз! ✨',
        pickProducts: 'Тауарларды таңдау',
      },
    },
  },
};
</script>

<style scoped>
.cart-content {
  @apply md:mx-auto h-full flex flex-col
    bg-white dark:bg-night md:container xl:max-w-screen-xl;
}
.cart-content.__reminder {
  padding-bottom: 52px;
}
</style>
