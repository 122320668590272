<template>
  <div class="w-full h-13 relative">
    <div
      class="py-2 px-3 flex items-center rounded-2xl cursor-pointer border shadow-search-shadow"
      :class="[
        focused
          ? 'bg-sirius-gray-200 border-sirius-orange-100'
          : 'bg-white border-transparent',
      ]"
      tabindex="0"
      @click="focusIt"
    >
      <IconLoupe width="36" height="36" />
      <SearchInput
        ref="searchInput"
        v-model="searchQuery"
        class="font-medium text-sirius-black-100"
        :placeholder="$t('findGoods')"
        @focus="focused = true"
        @blur="focused = false"
        @input="onSearchInput"
        @keyup.enter="onSearchInput"
      />
    </div>
    <transition name="fade">
      <UiToolPopup
        name="search-trigger-toolpopup"
        style="--modal-width: 688px"
        size="lg"
      >
        <div class="pt-6 pl-8 pr-4 pb-9 w-full">
          <template v-if="false && $route.name === 'category'">
            <div
              class="max-w-max flex items-center justify-center bg-sirius-gray-200 text-sirius-black-100 rounded-xl"
            >
              <div
                v-for="mode in SEARCH_MODES"
                :key="mode.type"
                class="flex items-center justify-center text-center h-8 text-sm font-medium rounded-xl transition-all duration-100 cursor-pointer"
                :class="{
                  'bg-sirius-orange-100 text-white': searchMode === mode.type,
                }"
                style="width: 164px"
                @click="changeSearchMode(mode.type)"
              >
                {{ mode.title }}
              </div>
            </div>
          </template>
          <UiRippleLoader
            v-if="isSearching"
            class="flex justify-center mx-auto mt-6"
          />
          <div
            v-else
            class="overflow-y-scroll overflow-x-hidden overscroll-contain hidden-scrollbar pr-3"
            style="max-height: 684px"
          >
            <template v-if="searchedCategories">
              <div
                v-for="(item, index) in searchedCategories"
                :key="index"
                class="mt-3 pb-3 border-b border-sirius-gray-400"
              >
                <CategoryBlock
                  class="w-full"
                  type="search"
                  :category="item"
                  @onNavigate="goToCategory(item)"
                />
              </div>
            </template>
            <template v-if="searchedProducts">
              <div
                v-for="(item, index) in searchedProducts"
                :key="`product-${index}`"
                class="mt-6"
              >
                <HorizontalProductCardWrapper
                  :product="item"
                  :id-key="'id'"
                  :added-from="PRODUCT_ADDED.SEARCH"
                  @onNavigate="hideResults"
                />
              </div>
            </template>
            <UiButton
              v-if="searchedProducts || searchedCategories"
              class="mt-8 w-full text-lg font-bold"
              variant="gray"
              size="lg"
              @click="showAllProducts"
            >
              Показать все товары
            </UiButton>
            <div v-else class="mt-5 flex">
              <div class="">
                <img
                  src="@img/search/nothing-found.png"
                  width="36px"
                  height="36px"
                  alt="smile"
                />
              </div>
              <div class="ml-5">
                <p class="font-bold text-2xl">Ничего не нашлось</p>
                <p class="mt-4">
                  Возможно, вы неверно ввели запрос, или у нас такого <br />
                  товара пока нет
                </p>
                <div class="mt-7 flex space-x-4">
                  <UiButton
                    class="px-8 font-bold text-lg"
                    size="lg"
                    @click="hideResults"
                  >
                    Готово
                  </UiButton>
                  <UiButton
                    class="px-8 font-bold text-lg"
                    size="lg"
                    variant="gray"
                    @click="openWishButton"
                  >
                    Нет того, что ищу
                  </UiButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </UiToolPopup>
    </transition>
  </div>
</template>

<script>
import debounce from 'debounce';
import { mapGetters, mapState } from 'vuex';

import { Analitycs, EVENTS, SEGMENT_EVENTS } from '@shared/services/analitycs';
import { SEARCH_MODES } from '@/views/category-search/config';
import { PRODUCT_ADDED } from '@shared/config/product';
import { api } from '@shared/services/api';
import { getFilteredProducts } from '@/lib/coffee/get-filtered-products';

import CategoryBlock from '@shared/components/catalog/CategoryBlock.vue';
import IconLoupe from '@shared/components/icons/IconLoupe.vue';
import SearchInput from '@shared/components/SearchInput.vue';
import UiRippleLoader from '@ui/UiRippleLoader.vue';
import UiToolPopup from '@ui/UiToolPopup.vue';
import UiButton from './ui/UiButtonNew.vue';
import HorizontalProductCardWrapper from './product/HorizontalProductCardWrapper.vue';

export default {
  name: 'SearchTrigger',
  components: {
    IconLoupe,
    SearchInput,
    UiToolPopup,
    CategoryBlock,
    UiRippleLoader,
    UiButton,
    HorizontalProductCardWrapper,
  },
  inject: ['popup', 'bottomSheetPopup'],
  model: {
    prop: 'value',
    event: 'onInput',
  },
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      searchMode: SEARCH_MODES[0].type,
      query: '',
      focused: false,
      searchQuery: '',
      searchedCategories: null,
      searchedProducts: null,
      isSearching: false,
      PRODUCT_ADDED,
      SEARCH_MODES,
    };
  },
  computed: {
    ...mapGetters('delivery', ['warehouseId']),
    ...mapState('catalog', ['categories']),
    ...mapGetters('abtest', ['testGroups']),
    categoryId() {
      return this.$route.params.id;
    },
    isCategorySearch() {
      return (
        this.searchMode === 'BY_CATEGORY' &&
        this.$route.name === 'category' &&
        this.categoryId
      );
    },
  },
  methods: {
    resetSearchedData() {
      this.searchedCategories = null;
      this.searchedProducts = null;
    },
    searchIn() {
      const payload = {
        warehouse_id: this.warehouseId,
        keyword: this.searchQuery,
        category_id: this.categoryId,
        page: 1,
        limit: 5,
        ab_groups: this.testGroups,
      };

      return api.lavka.searchInAllCategory(payload).then(({ data }) => {
        this.searchedProducts = getFilteredProducts(data.data.items);
        if (this.searchedProducts.length === 0) this.searchedProducts = null;

        Analitycs.logSegmentEvent(SEGMENT_EVENTS.VIEW_PRODUCT_LIST, {
          screenName: 'Category Page',
          query: this.searchQuery,
          categoryId: this.categoryId,
          logId: data.data.log_id,
          warehouseId: this.warehouseId,
        });
      });
    },
    searchAll() {
      const payload = {
        warehouse_id: this.warehouseId,
        keyword: this.searchQuery,
        page: 1,
        limit: 5,
        ab_groups: this.testGroups,
      };
      return api.lavka.searchInAllCategory(payload).then(({ data }) => {
        this.searchedProducts = getFilteredProducts(data.data.items);
        if (this.searchedProducts.length === 0) this.searchedProducts = null;
        Analitycs.logEvent(EVENTS.VIEWED_SEARCH_RESULTS, {
          search_results: data.data,
          search_query: this.searchQuery,
        });
        Analitycs.logSegmentEvent(SEGMENT_EVENTS.VIEW_PRODUCT_LIST, {
          screenName: 'Search Page',
          warehouseId: this.warehouseId,
          query: this.searchQuery,
          logId: data.data.log_id,
        });
      });
    },
    getProductsByQuery() {
      return this.$store
        .dispatch('delivery/FIND_WAREHOUSE_IF_NEEDED')
        .then(() =>
          this.isCategorySearch ? this.searchIn() : this.searchAll()
        )
        .catch(err => this.toast.show(err.message));
    },
    getCategoriesByQuery() {
      let result = null;
      if (this.categories) {
        try {
          result = this.categories.filter(
            e => e.name.search(this.searchQuery) !== -1
          );
        } catch (e) {
          console.log(e);
        }
      }
      return result && result.length !== 0 ? result : null;
    },
    onSearchInput: debounce(function onInput() {
      if (this.$route.name === 'search' && this.searchQuery.trim().length > 0) {
        this.goToSearch();
        return;
      }
      this.isSearching = true;
      this.showResults();
      if (this.searchQuery.trim() === '') {
        this.isSearching = false;
        return;
      }
      if (this.searchQuery.length > 3) {
        Analitycs.logEvent(EVENTS.ENTERED_SEARCH, {
          search_query: this.searchQuery,
        });
      }
      this.resetSearchedData();
      this.searchedCategories = this.getCategoriesByQuery();
      this.getProductsByQuery().finally(() => {
        this.isSearching = false;
      });
    }, 1000),
    hideResults() {
      this.popup.hide('search-trigger-toolpopup');
    },
    showResults() {
      this.popup.show('search-trigger-toolpopup');
    },
    focusIt() {
      this.focused = true;
      this.$refs.searchInput.focusIt();
    },
    showAllProducts() {
      if (this.isCategorySearch) return this.goToCategorySearch();
      return this.goToSearch();
    },
    goToSearch() {
      this.$router.push({
        name: 'search',
        query: {
          search: this.searchQuery,
        },
      });
      this.hideResults();
    },
    goToCategorySearch() {
      this.$router.push({
        name: 'category',
        params: {
          id: this.categoryId,
        },
        query: {
          search: this.searchQuery,
        },
      });
      this.hideResults();
    },
    changeSearchMode(modeType) {
      this.isSearching = true;
      this.searchMode = modeType;
      this.resetSearchedData();
      this.onSearchInput();
    },
    goToCategory(item) {
      if (!item) return;
      this.$router.push({
        name: 'category',
        params: {
          id: item.id.toString(),
        },
      });
      this.hideResults();
    },
    openWishButton() {
      this.$eventBus.emit('eb_open_wish_product_popup');
    },
  },
  i18n: {
    messages: {
      ru: {
        findGoods: 'Найти товары и продукты',
      },
      kk: {
        findGoods: 'Өнімдер мен тауарларды іздеу',
      },
    },
  },
};
</script>
