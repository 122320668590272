import { Analitycs, EVENTS } from '@shared/services/analitycs';
import { RahmetApp } from '@shared/RahmetApp';
import dayjs from 'dayjs';
import { eventBus } from '@/utils/plugins/event-bus';
import { $log } from '@/utils/plugins/logger';

/**
 * Считаем, что билд устарел, если прошло
 * больше 2 недель
 */
function isOutdated() {
  const now = dayjs();
  const releaseDate = import.meta.env.VITE_APP_RELEASE_TIME;
  const diff = now.diff(releaseDate, 'week', true);
  return diff >= 2;
}

function updateWebview() {
  Analitycs.logEvent(EVENTS.OUTDATED_BUILD);
  if (typeof window.RahmetApp?.reloadPage === 'function') {
    RahmetApp.reloadPage();
    return;
  }
  Analitycs.logEvent(EVENTS.OUTDATED_BUILD_NO_RELOAD);
}

function updateSite() {
  $log('refresh site');
  Analitycs.logEvent(EVENTS.OUTDATED_BUILD);
  document.location.reload();
}

function refresh() {
  if (RahmetApp.isWebView()) {
    updateWebview();
  } else {
    updateSite();
  }
}

function refreshIfOutdated() {
  if (isOutdated()) refresh();
}

function init() {
  $log('init OutdatedBuildWatcherService', {
    'import.meta.env.VITE_APP_RELEASE_TIME': import.meta.env
      .VITE_APP_RELEASE_TIME,
  });

  window.addEventListener('vite:preloadError', event => {
    throw new Error('vite:preloadError', { event });
  });

  refreshIfOutdated();
  // на сайте слушаем возврат на вкладку
  if (!RahmetApp.isWebView()) {
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        refreshIfOutdated();
      }
    });
  }

  eventBus.on('eb_on_reconnect_to_app', () => {
    $log('refreshIfOutdated', null, 'EventBus');
    refreshIfOutdated();
  });
}

export const OutdatedBuildWatcherService = {
  init,
};
