<template>
  <section class="MmMap">
    <MmSubtitle class="MmMap__title"> {{ $t('title') }} </MmSubtitle>
    <div id="micromarket-map" class="MmMap__mapWrapper"></div>
  </section>
</template>

<script>
// import { loadYmap } from 'vue-yandex-maps';
import MmSubtitle from '@/views/micromarket/components/MmSubtitle/MmSubtitle.vue';
import {
  // YMAPS_SETTINGS,
  INITIAL_COORDS,
  MARKS,
} from './constants';
import YandexMap from '@pure-ui/components/Map/MapView/map/yandex-map';

export default {
  name: 'MmMap',
  components: { MmSubtitle },
  inject: [],
  props: {},
  data: () => ({}),

  mounted() {
    this.mapInstance = new YandexMap(this.onMapInit);
    this.mapInstance.initMap(
      'micromarket-map',
      INITIAL_COORDS.reverse(),
      13,
      false
    );
  },
  methods: {
    setMicromarkets() {
      MARKS.forEach(markData => {
        this.mapInstance.map.geoObjects.add(
          new window.ymaps.Placemark(
            markData.coords.reverse(),
            {
              hintContent: markData.name,
            },
            {
              iconLayout: 'default#image',
              // Своё изображение иконки метки.
              iconImageHref: markData.icon,
              // Размеры метки.
              iconImageSize: [120, 120],
              // Смещение левого верхнего угла иконки относительно
              // её "ножки" (точки привязки).
              iconImageOffset: [-60, -120],
            }
          )
        );
      });
    },
    onMapInit() {
      this.setMicromarkets();
    },
  },

  i18n: {
    messages: {
      kk: { title: 'Микромаркетті бұрын орнатқандар' },
      ru: { title: 'Микромаркеты уже установили' },
    },
  },
};
</script>

<style scoped>
.MmMap {
  padding-top: 96px;
  width: calc(100% - 32px);
  max-width: 535px;
  margin: auto;
}

.MmMap__mapWrapper {
  margin-top: 24px;
  overflow: hidden;
  display: block;
  border-radius: 36px;
  border: 0.5px solid var(--main-grey, #d4d3df);
  background: rgba(212, 211, 223, 0.2);
  min-height: 424px;
}

@media (min-width: 768px) {
  .MmMap {
    max-width: 988px;
    padding-top: 170px;
  }

  .MmMap__mapWrapper {
    margin-top: 48px;
  }
}
</style>
