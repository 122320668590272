import { client } from '../../clients/client';

export function rFetchPopularProducts(warehouseId = 1, limit = 100) {
  return client
    .get(`/lavka-favorites/v1/bestsellers`, {
      params: {
        warehouse_id: warehouseId,
        limit,
      },
    })
    .then(response => response.data);
}
