<template>
  <div class="ProductsSwiper products-swiper">
    <UiSwiper
      :slides="slides"
      :settings="settings"
      navigation
      @onSlideChange="onSlideChange"
    >
      <template #slide="slide">
        <div class="products-swiper-slide">
          <SeeMore
            v-if="slide.type === 'see-more'"
            class="see-more"
            :navigation="navigation"
          />
          <ProductCardWrapper
            v-else-if="slide.product_info"
            :product="slide.product_info"
            :added-from="addedFrom"
          />
          <ProductCardWrapper v-else :product="slide" :added-from="addedFrom" />
        </div>
      </template>
    </UiSwiper>
  </div>
</template>

<script>
import ProductCardWrapper from '@shared/components/product/ProductCardWrapper.vue';
import SeeMore from './SeeMore.vue';

export default {
  name: 'ProductsSwiper',
  components: {
    ProductCardWrapper,
    UiSwiper: () => import('@pure-ui/components/UiSwiper/UiSwiper.vue'),
    SeeMore,
  },
  props: {
    products: {
      type: Array,
      default: () => [],
    },
    addedFrom: {
      type: String,
      required: true,
    },
    navigation: {
      type: Object,
      default: () => {
        return { name: 'main' };
      },
    },
  },
  data: () => ({
    currentSlide: 0,
  }),
  computed: {
    slides() {
      return [...this.products, { type: 'see-more' }];
    },
    settings() {
      return {
        slidesPerView: 5,
        slidesPerGroup: 5,
        speed: 500,
      };
    },
  },
  mounted() {
    this.emitViewed();
  },
  methods: {
    onSlideChange(index) {
      this.currentSlide = index;
      this.emitViewed();
    },
    emitViewed() {
      const slidesCount = this.currentSlide + this.settings.slidesPerView;
      const productsCount = this.products.length;
      this.$emit('onCardsViewed', Math.min(slidesCount, productsCount));
    },
  },
};
</script>

<style scoped>
.products-swiper-slide {
  @apply flex items-center justify-center;
}
.see-more {
  width: 100%;
  max-width: 188px;
  height: 320px;
}
</style>
